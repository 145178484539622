import client from "./client";

export default {
    async getInspections(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/inspections', filters)));
    },

    async createInspections(data) {
        return client.parseResponse(await client.post('/inspections', data))
    },

    async getInspection(id) {
        return client.parseResponse(await client.get('/inspections/' + id));
    },

    async editInspection(data) {
        return client.parseResponse(await client.put('/inspections/' + data.id, data))
    },

    async deleteInspection(payload) {
        return client.parseResponse(await client.delete('/inspections/' + payload.id));
    },
    async getAllInspectionQuestions(data) {
        return client.parseResponse(await client.get('/get-inspection-questions/' + data.id, data));
    },
    async getFinalInspections(data) {
        return client.parseResponse(await client.get('/get-inspections/', data));
    },
    async getInspectionVehicles(data) {
        return client.parseResponse(await client.get('/inspection-vehicles/', data));
    },
    async getUnassignedVehicles(data) {
        return client.parseResponse(await client.get('/unassigned-vehicles/', data));
    },
}
