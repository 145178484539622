<template>
    <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded border bg-white">

        <div class="rounded-t mb-0 px-4 py-6">
            <div class="flex flex-wrap items-center">
                <div class="relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-lg">
                        Tyre Settings
                    </h3>
                </div>
            </div>
        </div>

        <div class="block w-full overflow-x-auto">
            <el-tabs type="border-card">

                <el-tab-pane>
                    <template #label>
                        <div class="flex flex-row justify-center items-center">
                            <el-icon class="mr-2">
                                <Shop/>
                            </el-icon>
                            Tyre Stores
                        </div>
                    </template>
                    <tyre-stores/>
                </el-tab-pane>

                <el-tab-pane>
                    <template #label>
                        <div class="flex flex-row justify-center items-center">
                            <el-icon class="mr-2">
                                <Rank/>
                            </el-icon>
                            Tyre Sizes
                        </div>
                    </template>
                    <tyre-sizes/>
                </el-tab-pane>

                <el-tab-pane>
                    <template #label>
                        <div class="flex flex-row justify-center items-center">
                            <el-icon class="mr-2">
                                <Box/>
                            </el-icon>
                            Tyre Models
                        </div>
                    </template>
                    <tyre-models/>
                </el-tab-pane>

                <el-tab-pane>
                    <template #label>
                        <div class="flex flex-row justify-center items-center">
                            <el-icon class="mr-2">
                                <CollectionTag/>
                            </el-icon>
                            Tyre Brands
                        </div>
                    </template>
                    <tyre-brands/>
                </el-tab-pane>

                <el-tab-pane>
                    <template #label>
                        <div class="flex flex-row justify-center items-center">
                            <el-icon class="mr-2">
                                <DCaret/>
                            </el-icon>
                            Tyre Tread Patterns
                        </div>
                    </template>
                    <tyre-tread-patterns/>
                </el-tab-pane>

                <el-tab-pane>
                    <template #label>
                        <div class="flex flex-row justify-center items-center">
                            <el-icon class="mr-2">
                                <Collection/>
                            </el-icon>
                            Tyre Specifications
                        </div>
                    </template>
                    <tyre-specifications/>
                </el-tab-pane>

                <el-tab-pane>
                    <template #label>
                        <div class="flex flex-row justify-center items-center">
                            <el-icon class="mr-2">
                                <Shop/>
                            </el-icon>
                            Tyre Vendors
                        </div>
                    </template>
                    <tyre-vendors/>
                </el-tab-pane>

                <el-tab-pane>
                    <template #label>
                        <div class="flex flex-row justify-center items-center">
                            <el-icon class="mr-2">
                                <WarnTriangleFilled/>
                            </el-icon>
                            Tyre Defects
                        </div>
                    </template>
                    <tyre-defects/>
                </el-tab-pane>

                <el-tab-pane>
                    <template #label>
                        <div class="flex flex-row justify-center items-center">
                            <el-icon class="mr-2">
                                <Scissor/>
                            </el-icon>
                            Tyre Puncture Repairs
                        </div>
                    </template>
                    <tyre-puncture-repairs/>
                </el-tab-pane>

                <el-tab-pane>
                    <template #label>
                        <div class="flex flex-row justify-center items-center">
                            <el-icon class="mr-2">
                                <Tools/>
                            </el-icon>
                            Vehicle Types
                        </div>
                    </template>
                    <vehicle-types/>
                </el-tab-pane>

                <el-tab-pane>
                    <template #label>
                        <div class="flex flex-row justify-center items-center">
                            <el-icon class="mr-2">
                                <Tools/>
                            </el-icon>
                            Axle Structures
                        </div>
                    </template>
                    <axle-structures/>
                </el-tab-pane>
                <el-tab-pane>
                    <template #label>
                        <div class="flex flex-row justify-center items-center">
                            <el-icon class="mr-2">
                                <Tools/>
                            </el-icon>
                            Dismount Reason
                        </div>
                    </template>
                    <dismount-reason/>
                </el-tab-pane>

            </el-tabs>
        </div>

    </div>
</template>
<script>
import {
    Shop,
    Rank,
    Box,
    Tools,
    CollectionTag,
    Collection,
    DCaret,
    WarnTriangleFilled,
    Scissor,
} from '@element-plus/icons-vue'
import TyreStores from "@/components/TyreSettings/TyreStores.vue";
import TyreSizes from "@/components/TyreSettings/TyreSizes.vue";
import TyreModels from "@/components/TyreSettings/TyreModels.vue";
import TyreBrands from "@/components/TyreSettings/TyreBrands.vue";
import TyreTreadPatterns from "@/components/TyreSettings/TyreTreadPatterns.vue";
import TyreSpecifications from "@/components/TyreSettings/TyreSpecifications.vue";
import TyreVendors from "@/components/TyreSettings/TyreVendors.vue";
// import TyreAxels from "@/components/TyreSettings/TyreAxels.vue";
import TyreDefects from "@/components/TyreSettings/TyreDefects.vue";
import TyrePunctureRepairs from "@/components/TyreSettings/TyrePunctureRepairs.vue";
import AxleStructures from "@/components/TyreSettings/AxleStructures.vue";
import VehicleTypes from "@/components/TyreSettings/VehicleTypes.vue";
import DismountReason from "@/components/TyreSettings/DismountReason.vue";

export default {
    components: {
        Shop,
        Rank,
        Box,
        Tools,
        CollectionTag,
        Collection,
        DCaret,
        WarnTriangleFilled,
        Scissor,
        TyreStores,
        TyreSizes,
        TyreModels,
        TyreBrands,
        TyreSpecifications,
        TyreTreadPatterns,
        TyreVendors,
        // TyreAxels,
        TyreDefects,
        TyrePunctureRepairs,
        AxleStructures,
        VehicleTypes,
        DismountReason
    }
}
</script>
