<template>
    <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded border bg-white">

        <div class="rounded-t mb-0 px-4 py-5 border-b">
            <div class="flex flex-wrap items-center">
                <div class="md:flex-row flex-col relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-lg">
                        Permissions
                    </h3>
                    <div class="flex flex-row justify-end">
                        <button class="bg-secondary text-white px-10 py-2 font-semibold rounded-md"
                                @click="addPermissionDialogVisible = true">Add Permission
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!--    Filters and Search-->
        <div class="flex flex-row py-5 justify-between text-gray-100 border-b px-4 items-end">
            <div class="flex flex-col text-gray-100 px-4">
                <div class="flex flex-row justify-start items-end space-x-4">
                    <div class="flex flex-col">
                        <p class="text-md font-bold text-sm lg:text-md mb-3 text-black">Module</p>
                        <div class="flex flex-row items-start justify-start w-full">
                            <div class="flex flex-row rounded-md w-full">
                                <el-select
                                    style="width: 100%" multiple v-model="filters.module_id"
                                    placeholder="Filter by Module"
                                    size="default">
                                    <el-option
                                        v-for="item in modules.data"
                                        :key="item.id"
                                        :label="item.module"
                                        :value="item.id"
                                    />
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col text-black">
                        <p class="text-md font-bold text-sm lg:text-md mb-3 text-black">Table</p>
                        <div class="flex flex-row items-start justify-start w-full">
                            <div class="flex flex-row rounded-md w-full">
                                <el-select
                                    style="width: 100%" multiple v-model="filters.table_name"
                                    placeholder="Filter by table"
                                    size="default">
                                    <el-option
                                        v-for="(table, index) in tables"
                                        :value="table"
                                        :key="index"
                                        :label="table"
                                    />
                                </el-select>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!--      search-->
            <form>
                <div class="relative text-black">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" class="w-5 h-5 mr-3">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                        </svg>
                    </div>
                    <input v-model="search"
                           class="block outline-none w-full px-12 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-secondary focus:border-secondary"
                           placeholder="Search Permissions ...">
                </div>
            </form>
        </div>

        <div class="block w-full overflow-x-auto">

            <!-- Add/Edit dialog-->
            <el-dialog
                v-model="addPermissionDialogVisible"
                title="Add/Edit Permissions"
                :width="windowWidth > 768 ? '30%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <form class="px-4">
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Name<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.name" type="text"
                                        name="form.name" required/>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Description<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.description" type="text"
                                        name="form.name"/>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Table Name<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input placeholder=""
                                           class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                           v-model="form.table_name" type="text"
                                           name="form.name"/>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Module<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.module_id" placeholder="Select module"
                                        size="default">
                                        <el-option
                                            v-for="item in modules.data"
                                            :key="item.id"
                                            :label="item.module"
                                            :value="item.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="addPermission">
                            Save
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger" @click="addPermissionDialogVisible = false">
                            Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>


            <!--Table-->
            <div class="py-8 px-4">
                <el-table class="" :data="permissions.data" stripe size="large" style="width: 100%">
                    <el-table-column prop="name" label="NAME"/>
                    <el-table-column prop="description" label="DESCRIPTION"/>
                    <el-table-column prop="table_name" label="TABLE"/>
                    <el-table-column prop="module.module" label="MODULE"/>
                    <el-table-column prop="created_at" label="CREATED AT"/>
                    <el-table-column label="ACTIONS">
                        <template #default="scope">
                            <el-dropdown size="large">
                                <el-button>
                                    Actions
                                    <el-icon class="el-icon--right">
                                        <arrow-down/>
                                    </el-icon>
                                </el-button>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item @click.native="updatePermission(scope.row)">Edit Permission
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="deletePermission(scope.row)">Delete Permission
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <!--Pagination-->
            <div class="flex flex-row p-5 w-full justify-end">
                <el-pagination
                    :current-page="permissions.current_page"
                    :page-size="permissions.per_page"
                    :page-sizes="[5,10,20,50]"
                    :small=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="permissions.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>

            <!--Modules dialog-->
            <el-dialog
                v-model="assignModulesDialogVisible"
                title="Modules"
                :width="windowWidth > 768 ? '40%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <div class="flex flex-col">
                        <div class="modal-body">
                            <div v-for="(module, index) in modules_p" :key="index">
                                <div class="flex px-2 py-2 my-2 items-center">
                                    <el-checkbox
                                        :id="'check'+index"
                                        v-model="module.status">
                                    </el-checkbox>
                                    <label class="font-medium px-6 text-lg" :for="'check'+index">
                                        {{ module.module }}
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="addModule">
                            Save
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="assignModulesDialogVisible = false">Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>
        </div>

    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {ArrowDown} from '@element-plus/icons-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
    data() {
        return {
            search: '',
            uploadDialogVisible: false,
            addPermissionDialogVisible: false,
            windowWidth: window.innerWidth,
            assignModulesDialogVisible: false,
            form: {},
            company: {},
            filters: {
                paginate: true,
                per_page: 10
            },
        };
    },
    components: {
        ArrowDown

    },
    mounted() {
        this.getPermissionsList(this.filters)
        this.getModules(this.filters)
    },
    computed: {
        modules_p() {
            return this.modules.data.map(function (item) {
                item.status = item.status == 1 ? true : false;
                return item;
            });
        },
        ...mapGetters({
            permissions: 'permissions/permissions',
            modules: 'modules/modules'
        }),
        tables() {
            let data =  this.permissions.data.map(item => {
                return item.table_name
            })
            return new Set(data)
        },
    },
    watch: {
        search: function (val) {
            this.getPermissionsList({
                ...this.filters,
                search: val
            });
        },
        filters: {
            deep: true,
            handler: function () {
                this.getPermissionsList({...this.filters})
            }
        },
    },
    methods: {
        ...mapActions({
            getPermissionsList: 'permissions/getPermissions',
            createPermissions: 'permissions/createPermission',
            editPermission: 'permissions/editPermission',
            removePermission: 'permissions/deletePermission',
            getModules: 'modules/getModules',
        }),
        handleSizeChange(size) {
            this.filters.per_page = size;
            this.getPermissionsList({paginate: true, ...this.filters});
        },
        handleCurrentChange(page) {
            this.filters.page = page;
            this.getPermissionsList({paginate: true, ...this.filters});
        },
        addPermission() {
            if (this.form.id) {
                this.editPermission(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addPermissionDialogVisible = false
                    }
                })
            } else {
                this.createPermissions(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addPermissionDialogVisible = false
                    }
                });
            }
        },
        updatePermission(item) {
            this.addPermissionDialogVisible = true
            this.form = Object.assign({}, this.form, item);
        },
        deletePermission(item) {
            let conf = confirm(`Are yous sure you want to remove this permission?`)
            if (conf) {
                this.removePermission({
                    id: item.id
                })
            }
        },

    }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: transparent;
    display: flex;
    align-items: center;
}
</style>
