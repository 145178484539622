import client from "./client";

export default {
    async getRoles(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/roles', filters)));
    },
    async createRole(data) {
        return client.parseResponse(await client.post('/roles', data))
    },
    async getRole(id) {
        return client.parseResponse(await client.get('/roles/' + id));
    },
    async editRole(data) {
        return client.parseResponse(await client.put('/roles/' + data.id, data))
    },
    async getRolePermissions(data) {
        return client.parseResponse(await client.get('/permissions/' + data.id, data));
    },
    async saveRolePermissions(data) {
        return client.parseResponse(await client.post('/role-permissions', data));
    },
    async deleteRole({id}) {
        return client.parseResponse(await client.delete('/roles/' + id));
    },

}
