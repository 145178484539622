<template>
    <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded border bg-white">

        <div class="rounded-t mb-0 px-4 py-5 border-b">
            <div class="flex flex-wrap items-center">
                <div class="md:flex-row flex-col relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-lg">
                        Customers
                    </h3>
                    <div class="flex flex-row justify-end">
                        <button class="bg-secondary text-white px-10 py-2 font-semibold rounded-md"
                                @click="addCustomerDialogVisible = true">Add Customer
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!--    Filters and Search-->
        <div
            class="flex lg:flex-row flex-col justify-between md:items-start md:space-y-0 space-y-2 md:space-x-4 space-x-0 py-8 px-6 border-b">
                        <div class="flex flex-row justify-start items-end mb-3 space-x-4">
                            <div class="flex flex-row">
                                <el-button-group>
                                    <el-button @click="exportCustomers" type="success" plain>
                                        <span class="mr-3">Export</span>
                                        <el-icon>
                                            <Download/>
                                        </el-icon>
                                    </el-button>
                                    <el-button @click="uploadCustomerDialogVisible=!uploadCustomerDialogVisible"
                                               type="warning"
                                               size="default"
                                               plain>
                                        <span class="mr-3">Import</span>
                                        <el-icon>
                                            <Upload/>
                                        </el-icon>
                                    </el-button>
                                </el-button-group>
                            </div>
                        </div>

            <form>
                <div class="relative">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" class="w-5 h-5 mr-3">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                        </svg>
                    </div>
                    <input v-model="search"
                           class="block outline-none w-full px-12 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-secondary focus:border-secondary"
                           placeholder="Search Customers ...">
                </div>
            </form>
        </div>

        <div class="block w-full overflow-x-auto">

            <!--Import dialog-->
            <el-dialog
                v-model="uploadCustomerDialogVisible"
                title="Upload Customers"
                :width="windowWidth > 768 ? '40%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <div class="flex flex-row items-center w-full rounded mb-4">
                        <a class="py-2 px-6 text-sky-600"
                           href="/templates/vehicles_template.csv" download="">
                            <font-awesome-icon class="mr-2 text-sm"
                                               :icon="['fas', 'download']"/>
                            Please click here to download the customers template.
                        </a>
                    </div>
                    <form class="px-4">
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">File (.xls or .xlsx or .csv)<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row border p-2 rounded-md w-full">
                                    <input
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        class="outline-none w-full" @change="companyFile" type="file" required/>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="upload">
                            Upload
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="uploadCustomerDialogVisible = false">
                            Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!-- Add/Edit dialog-->
            <el-dialog
                v-model="addCustomerDialogVisible"
                title="Add/Edit Customers"
                :width="windowWidth > 768 ? '30%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <form class="px-4">
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Name<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.name" type="text"
                                        name="form.name" required/>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Phone Number<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.phone_number" type="text"
                                        name="form.name"/>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Email</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.email" type="text"
                                        name="form.name"/>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">ERP Code</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.erp_code" type="text"
                                        name="form.name"/>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Longitude</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.longitude" type="text"
                                        name="form.name"/>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Latitude</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.latitude" type="text"
                                        name="form.name"/>
                                </div>
                            </div>
                        </div>

                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="addCustomer">
                            Save
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="addCustomerDialogVisible = false">
                            Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!--Table-->
            <div class="py-8 px-4">
                <el-table class="" :data="customers.data" stripe size="large" style="width: 100%">
                    <el-table-column prop="name" label="NAME"/>
                    <el-table-column prop="company.name" label="COMPANY"/>
                    <el-table-column prop="email" label="EMAIL"/>
                    <el-table-column prop="phone_number" label="PHONE NUMBER"/>
                    <el-table-column prop="erp_code" label="ERP CODE"/>
                    <el-table-column prop="longitude" label="LONGITUDE"/>
                    <el-table-column prop="latitude" label="LATITUDE"/>
                    <el-table-column prop="created_at" label="CREATED AT"/>
                    <el-table-column label="ACTIONS">
                        <template #default="scope">
                            <el-dropdown size="large">
                                <el-button>
                                    Actions
                                    <el-icon class="el-icon--right">
                                        <arrow-down/>
                                    </el-icon>
                                </el-button>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item @click.native="updateCustomer(scope.row)">EDIT
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="deleteCustomer(scope.row)">DELETE
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="flex flex-row p-5 w-full justify-end">
                <el-pagination
                    :current-page="customers.current_page"
                    :page-size="customers.per_page"
                    :page-sizes="[5,10,20,50]"
                    :small=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="customers.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </div>

    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {ArrowDown} from '@element-plus/icons-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
    data() {
        return {
            search: '',
            assignInspectionDialog: false,
            uploadCustomerDialogVisible: false,
            addCustomerDialogVisible: false,
            windowWidth: window.innerWidth,
            form: {},
            user: {},
            inspection: {},
            company_vehicle: {},
            filters: {
                paginate: true,
                per_page: 10
            },
        };
    },
    components: {
        ArrowDown

    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.getCustomersList({
            ...this.filters,
            id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
        })
    },
    computed: {
        ...mapGetters({
            customers: 'customers/customers',
            companies: 'companies/companies',
        })
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.getCustomersList({
                    ...this.filters,
                    id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
                })
            }
        },
        search: function (val) {
            this.getCustomersList({
                ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id,
                search: val
            });
        }
    },
    methods: {
        ...mapActions({
            getCustomersList: 'customers/getCustomers',
            createCustomers: 'customers/createCustomers',
            editCustomer: 'customers/editCustomer',
            removeCustomer: 'customers/deleteCustomer',
        }),
        handleSizeChange(size) {
            this.filters.per_page = size;
            this.getCustomersList({
                paginate: true, ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            });
        },
        handleCurrentChange(page) {
            this.filters.page = page;
            this.getCustomersList({
                paginate: true, ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            });
        },
        handleDelete(item) {
            this.deleteVehicleInspection({id: item.id}).then(() => {
                this.getFinalInspections({id: item.vehicle_id});
                this.getVehicleInspections({paginate: true, id: item.vehicle_id})
            })
        },
        addCustomer() {
            this.form.company_id = this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            if (this.form.id) {
                this.form.vehicle_id = this.form.id
                this.editCustomer(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addCustomerDialogVisible = false
                    }
                })
            } else {
                this.createCustomers(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addCustomerDialogVisible = false
                    }
                });
            }
        },
        updateCustomer(item) {
            this.addCustomerDialogVisible = true
            this.form = Object.assign({}, this.form, item);
        },
        deleteCustomer(item) {
            let conf = confirm(`Are you sure you want to remove this item?`)
            if (conf) {
                this.removeCustomer({
                    id: item.id
                })
            }
        },
        // upload() {
        //     this.company_vehicle.file = this.file;
        //     this.company_vehicle.company_id = this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id;
        //     this.uploadCustomers(this.company_vehicle)
        //         .then(() => {
        //             this.getCustomersList(this.filters)
        //             this.uploadCustomerDialogVisible = false
        //             this.$message.success('Customers uploaded successfully!')
        //         }).catch((e) => {
        //         this.$message.error(e.response.data.message)
        //     })
        // },
        // companyFile(e) {
        //     this.file = e.target.files[0]
        // },
        // exportCustomers() {
        //     this.downloadCustomers({...this.filters})
        // }

    }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: transparent;
    display: flex;
    align-items: center;
}
</style>
