<template>
    <div class="flex flex-col break-words w-full mb-6 shadow-md rounded border">

        <div class="rounded-t mb-0 px-4 py-5">
            <div class="flex flex-wrap items-center">
                <div class="flex flex-col relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-lg">
                        Role Permissions
                    </h3>
                    <div class="flex flex-col py-5 text-gray-100 px-4">
                        <div class="flex flex-row justify-start items-end mb-3 space-x-4">
                            <div class="flex flex-col">
                                <p class="text-md font-bold text-sm lg:text-md mb-3">Module</p>
                                <div class="flex flex-row items-start justify-start w-full">
                                    <div class="flex flex-row rounded-md w-full">
                                        <el-select
                                            style="width: 100%" multiple v-model="filters.module_id"
                                            placeholder="Filter by Module"
                                            size="default">
                                            <el-option
                                                v-for="item in modules.data"
                                                :key="item.id"
                                                :label="item.module"
                                                :value="item.id"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col">
                                <p class="text-md font-bold text-sm lg:text-md mb-3">Table</p>
                                <div class="flex flex-row items-start justify-start w-full">
                                    <div class="flex flex-row rounded-md w-full">
                                        <el-select
                                            style="width: 100%" multiple v-model="filters.table_name"
                                            placeholder="Filter by table"
                                            size="default">
                                            <el-option
                                                v-for="(table, index) in tables"
                                                :value="table"
                                                :key="index"
                                                :label="table"
                                            />
                                        </el-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div v-for="(item,index) in role_p" :key="index"
             class="flex flex-col">
            <div class="flex justify-center p-4 px-3 py-4">
                <div class="w-full">
                    <div class="bg-white border rounded-lg px-3 py-2 mb-4">
                        <div class="block text-gray-700 text-2xl font-semibold py-6 px-2">
                            {{ item[0].module_name }}
                        </div>
                        <div class="flex flex-row">
                            <div class="flex px-2 py-2 my-2 items-center">
                                <el-checkbox
                                    @change="checkPermission(index,$event)">
                                </el-checkbox>
                                <label class="font-medium px-6 text-lg" :for="'check'+index">
                                    CHECK ALL
                                </label>
                            </div>
                        </div>
                        <div v-for="(sub_item,index) in item" :key="index" class="py-3 text-sm border-t">
                            <div
                                class="flex items-center cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2">
                                <span class="">
                                  <el-checkbox
                                      size="large"
                                      :id="'check'+index"
                                      v-model="sub_item.status">
                                  </el-checkbox>
                                </span>
                                <div class="flex flex-row justify-between w-full">
                                    <label :for="'check'+index"
                                           class="font-medium px-12 text-lg">{{ sub_item.description }}</label>
                                    <label :for="'check'+index" class="font-medium px-12 text-lg text-gray-500">{{
                                            sub_item.table_name
                                        }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-row justify-end items-center space-x-4 text-white p-4 px-3">
            <button class="bg-red-700 px-12 py-2 rounded-lg"
                    data-dismiss="modal" @click="cancel()">
                Cancel
            </button>
            <button
                class="bg-sky-500 px-12 py-2 rounded-lg"
                @click="addPermission()">
                Save
            </button>
        </div>
        <br>
    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'RolePermissionsComponent',
    data() {
        return {
            search: '',
            form: {},
            filters: {
                paginate: false
            },
            checkAll: null,
            checkedPermissions: []
        }
    },
    mounted() {
        this.form = JSON.parse(localStorage.getItem('role'));
        this.getRolePermissions({...this.filters, ...this.form})
        this.getModules({...this.filters, ...this.form});

    },

    computed: {
        ...mapGetters({
            errors: 'roles/errors',
            has_errors: 'roles/has_errors',
            loading: 'roles/loading',
            role_permissions: 'roles/role_permissions',
            modules: 'modules/modules'
        }),
        role_p() {
            return this.role_permissions.data.map(function (item) {
                return item.map(function (item) {
                    item.status = item.status == 1 ? true : false;
                    return item;
                })
            });
        },
        tables() {
            let data = this.role_permissions.data.reduce((i, f) => {
                return i.concat(f.map(k => k.table_name))
            }, [])
            return new Set(data)
        },
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.form = JSON.parse(localStorage.getItem('role'));
                this.getRolePermissions({...this.filters, ...this.form})
            }
        },
    },
    methods: {
        ...mapActions({
            getRolePermissions: 'roles/getRolePermissions',
            saveRolePermissions: 'roles/saveRolePermissions',
            getModules: 'modules/getModules'
        }),
        cancel() {
            this.$router.push('/admin/roles')
        },
        checkPermission(index, value) {
            this.role_permissions.data[index] = this.role_permissions.data[index].map(function (item) {
                item.status = value;
                return item;
            });
            this.role_permissions.data = [
                ...this.role_permissions.data
            ];
        },
        addPermission() {
            this.saveRolePermissions({
                role_id: this.form.id,
                permissions: this.role_permissions.data
            })
        },

    }
}
</script>
