<template>
    <div class="w-full md:px-12 px-4 h-full">
        <div class="flex flex-row items-center justify-center h-full w-full">
            <div class="w-full md:px-12">
                <div
                    class="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg bg-white border md:px-12"
                >
                    <div class="rounded-t mb-0 px-6 py-6">
                        <div class="text-center mb-3">
                            <h6 class="text-slate-500 text-sm font-bold">
                                Sign in with
                            </h6>
                        </div>
                        <div class="btn-wrapper text-center">
                            <button
                                class="bg-white active:bg-slate-50 text-slate-700 px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                                type="button"
                                @click="socialLogin('Facebook')"
                            >
                                <img alt="..." class="w-5 mr-1" :src="facebook"/>
                                Facebook
                            </button>
                            <button
                                class="bg-white active:bg-slate-50 text-slate-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                                type="button"
                                @click="socialLogin('Google')"
                            >
                                <img alt="..." class="w-5 mr-1" :src="google"/>
                                Google
                            </button>
                        </div>
                        <hr class="mt-6 border-b-1 border-slate-300"/>
                    </div>
                    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                        <div class="text-slate-400 text-center mb-3 font-bold">
                            <small>Or sign in with credentials</small>
                        </div>
                        <form class="space-y-2" @submit.prevent="loginUser">
                            <div class="relative w-full mb-3">
                                <label
                                    class="block uppercase text-slate-600 text-xs font-bold mb-2"
                                >
                                    Email
                                </label>
                                <input
                                    type="email"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-secondary focus:border-secondary block w-full px-3 py-2 outline-none ease-linear transition-all duration-150"
                                    placeholder="Email"
                                    v-model="user.email"
                                />
                                <span class="text-red-500" v-if="errors.data.web_errors.email  && this.has_errors">
                                    {{ errors.data.web_errors.email[0] }}
                                </span>
                            </div>

                            <div class="relative w-full mb-3">
                                <label
                                    class="block uppercase text-slate-600 text-xs font-bold mb-2"
                                >
                                    Password
                                </label>
                                <input
                                    type="password"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-secondary focus:border-secondary block w-full px-3 py-2 outline-none ease-linear transition-all duration-150"
                                    placeholder="Password"
                                    v-model="user.password"
                                />
                                <span class="text-red-500" v-if="errors.data.web_errors.password  && this.has_errors">
                                    {{ errors.data.web_errors.password[0] }}
                                </span>
                            </div>
                            <div>
                                <label class="inline-flex items-center cursor-pointer">
                                    <input
                                        id="customCheckLogin"
                                        type="checkbox"
                                        class="form-checkbox rounded border-gray-300 text-slate-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                    />
                                    <span class="ml-2 text-sm font-semibold text-slate-600">
                    Remember me
                  </span>
                                </label>
                            </div>

                            <div class="text-center mt-6">
                                <button
                                    class="bg-secondary text-white active:bg-primary-800 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                    type="submit"
                                >
                                    Sign In
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="flex flex-wrap mt-6 relative">
                    <div class="w-1/2">
                        <router-link to="/forgot-password" class="text-slate-500">
                            <small>Forgot password?</small>
                        </router-link>
                    </div>
                    <div class="w-1/2 text-right">
                        <router-link to="/register" class="text-slate-500">
                            <small>Create new account</small>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import facebook from "@/assets/img/facebook.svg";
import google from "@/assets/img/google.svg";
import {mapActions, mapGetters} from "vuex";

export default {
    data() {
        return {
            facebook,
            google,
            submitted: false,
            loading: false,
            invalid: false,
            token_sent: false,
            errorMessage: '',
            message: '',
            user: {
                email: '',
                password: '',
                token: null
            },
        };
    },
    computed: {
        ...mapGetters({
            'errors': "auth/errors",
            'has_errors': 'auth/has_errors'
        })
    },
    methods: {
        ...mapActions({
            'login': 'auth/authenticate',
            'socialLogins': 'auth/socialLogin'
        }),

        async loginUser() {
            await this.login(this.user)
        },
        socialLogin(item) {
            this.socialLogins({social: item})
        },
        async googleRegister() {
            let register_response = await this.socialRegister({'company': 'google'})
            if (register_response.status) {
                this.isSuccessful = true
                this.successMessage = register_response.success
                this.$toast.success(this.successMessage)
                window.location.href = register_response.data.url
                await this.$router.push(register_response.data.url);
            }
            if (!register_response.success) {
                this.hasError = true
                this.errorMessage = register_response.data
            }
        },
    }
};
</script>
