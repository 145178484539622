import client from "./client";

export default {
    async getManufactures(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/manufactures', filters)));
    },

    async createManufactures(data) {
        return client.parseResponse(await client.post('/manufactures', data))
    },

    async getManufacture(id) {
        return client.parseResponse(await client.get('/manufactures/' + id));
    },

    async editManufacture(data) {
        return client.parseResponse(await client.put('/manufactures/' + data.id, data))
    },

    async deleteManufacture(payload) {
        return client.parseResponse(await client.delete('/manufactures/' + payload.id));
    },

    async uploadManufactures(payload) {
        return client.parseResponse(await client.upload('/import-manufactures', payload, payload.file))
    },

    async exportManufactures(filters) {
        return await client.get('/export-manufactures', filters, {responseType: 'blob'});
    },
}
