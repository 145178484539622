import client from "./client";

export default {
    async getTyreVendors(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/tyre-vendors', filters)));
    },

    async createTyreVendors(data) {
        return client.parseResponse(await client.post('/tyre-vendors', data))
    },

    async getTyreVendor(id) {
        return client.parseResponse(await client.get('/tyre-vendors/' + id));
    },

    async editTyreVendor(data) {
        return client.parseResponse(await client.put('/tyre-vendors/' + data.id, data))
    },

    async deleteTyreVendor(payload) {
        return client.parseResponse(await client.delete('/tyre-vendors/' + payload.id));
    },

    async uploadTyreVendors(payload) {
        return client.parseResponse(await client.upload('/import-tyre-vendors', payload, payload.file))
    },

    async exportTyreVendors(filters) {
        return await client.get('/export-tyre-vendors', filters, {responseType: 'blob'});
    },
}
