import client from "./client";

export default {
    async getTyrePunctureRepairs(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/tyre-puncture-repairs', filters)));
    },

    async createTyrePunctureRepairs(data) {
        return client.parseResponse(await client.post('/tyre-puncture-repairs', data))
    },

    async getTyrePunctureRepair(id) {
        return client.parseResponse(await client.get('/tyre-puncture-repairs/' + id));
    },

    async editTyrePunctureRepair(data) {
        return client.parseResponse(await client.put('/tyre-puncture-repairs/' + data.id, data))
    },

    async deleteTyrePunctureRepair(payload) {
        return client.parseResponse(await client.delete('/tyre-puncture-repairs/' + payload.id));
    },

    async uploadTyrePunctureRepairs(payload) {
        return client.parseResponse(await client.upload('/import-tyre-puncture-repairs', payload, payload.file))
    },

    async exportTyrePunctureRepairs(filters) {
        return await client.get('/export-tyre-puncture-repairs', filters, {responseType: 'blob'});
    },
}
