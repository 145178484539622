import client from "./client";

export default {
    async getTyreAxels(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/axle-structures', filters)));
    },

    async createTyreAxels(data) {
        return client.parseResponse(await client.post('/axle-structures', data))
    },

    async getTyreAxel(id) {
        return client.parseResponse(await client.get('/axle-structures/' + id));
    },

    async editTyreAxel(data) {
        return client.parseResponse(await client.put('/axle-structures/' + data.id, data))
    },

    async uploadTyreAxels(payload) {
        return client.parseResponse(await client.upload('/import-vehicle-axel-structures', payload, payload.file))
    },

    async exportTyreAxels(filters) {
        return await client.get('/export-vehicle-axel-structures', filters, {responseType: 'blob'});
    },
}
