import api from "../api";
import {ElMessage} from 'element-plus'

export default {
    namespaced: true,
    state: {
        tyre_logs: {data: [], current_page: 1, total: 0, per_page: 10},
        all_tyre_logs: {data: [], current_page: 1, total: 0, per_page: 10},
        loading: false
    },
    getters: {
        tyre_logs: state => state.tyre_logs,
        all_tyre_logs: state => state.all_tyre_logs,
        loading: state => state.loading,
    },
    mutations: {
        setTyreLogs(state, data) {
            state.tyre_logs = data;
        },
        setAllTyreLogs(state, data) {
            state.all_tyre_logs = data;
        },
        setLoading(state, loading) {
            state.loading = loading
        },
    },
    actions: {
        async getTyreLogs({commit}, filters) {
            try {
                const response = await api.getTyreLogs(filters);
                commit('setTyreLogs', response);
            } catch (e) {
                ElMessage({
                    type: 'error',
                    message: e.response.data.message,
                    duration: 10000
                });
            }
        },
        async exportTyreLogs(context, filters) {
            try {
                const response = await api.exportTyreLogs(filters);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data]));
                link.setAttribute('download',  'tyre_logs' + Date.now() + '.xlsx');
                document.body.appendChild(link);
                link.click();
            } catch (e) {
                ElMessage({
                    message: 'Error exporting!',
                    type: 'error',
                })
            }
        },
        async getAllTyreLogs({commit}, filters) {
            try {
                const response = await api.getAllTyreLogs(filters);
                commit('setAllTyreLogs', response);
            } catch (e) {
                ElMessage({
                    type: 'error',
                    message: e.response.data.message,
                    duration: 10000
                });
            }
        },
        async exportAllTyreLogs(context, filters) {
            try {
                const response = await api.exportAllTyreLogs(filters);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data]));
                link.setAttribute('download',  'all_tyre_logs' + Date.now() + '.xlsx');
                document.body.appendChild(link);
                link.click();
            } catch (e) {
                ElMessage({
                    message: 'Error exporting!',
                    type: 'error',
                })
            }
        },
    }
}
