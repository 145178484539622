<template>
  <h1 class="m-14 text-5xl font-normal">Logging you in...</h1>
</template>
<script>
  import {mapActions} from "vuex";

  export default {
    async created() {
      let google_response = await this.googleCallback(this.$route.query.code)
      if (!google_response){
        let facebook_response = await this.facebookCallback(this.$route.query.code)
        console.log(facebook_response)
      }

      await this.$router.push('/admin/dashboard');

    },
    methods: {
      ...mapActions({
        'googleCallback':'auth/googleCallback',
        'facebookCallback': 'auth/facebookCallback',
      }),
    }
  }
</script>
