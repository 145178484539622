import client from "./client";

export default {
    async getAppSettings(filters){
        return client.parseResponse(client.parseResponse(await client.get('/app-settings', filters)));
    },

    async createAppSettings(data) {
        return client.parseResponse(await client.post('/app-settings', data))
    },

    async getAppSetting(id) {
        return client.parseResponse(await client.get('/app-settings/' + id));
    },

    async editAppSetting(data) {
        return client.parseResponse(await client.put('/app-settings/' + data.id, data))
    },

    async deleteAppSetting(payload) {
        return client.parseResponse(await client.delete('/app-settings/' + payload.id));
    },
}
