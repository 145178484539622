import client from "./client";

export default {
    async getTyreModels(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/tyre-models', filters)));
    },

    async createTyreModels(data) {
        return client.parseResponse(await client.post('/tyre-models', data))
    },

    async getTyreModel(id) {
        return client.parseResponse(await client.get('/tyre-models/' + id));
    },

    async editTyreModel(data) {
        return client.parseResponse(await client.put('/tyre-models/' + data.id, data))
    },

    async deleteTyreModel(payload) {
        return client.parseResponse(await client.delete('/tyre-models/' + payload.id));
    },

    async uploadTyreModels(payload) {
        return client.parseResponse(await client.upload('/import-tyre-models', payload, payload.file))
    },

    async exportTyreModels(filters) {
        return await client.get('/export-tyre-models', filters, {responseType: 'blob'});
    },
}
