/* eslint-disable */
import {createApp} from "vue";
import Toaster from '@meforma/vue-toaster';
import store from './data/store';
import {VueCsvImportPlugin} from "vue-csv-import";
import 'maz-ui/css/main.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// styles
import "@/assets/styles/tailwind.css";
import "@/assets/styles/index.css";

//fontawesome
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
    faClipboard, faClipboardQuestion, faClipboardCheck, faClipboardList,
    faUsers, faUserTag, faRoad, faCity, faBoxOpen, faTruck, faChevronDown,
    faChevronUp, faCircleNotch, faGears, faTv, faDownload, faBars, faTimes,
    faIndustry, faBuilding, faTags, faCodeBranch, faTimeline, faMobile, faPeopleRoof, faRoadCircleCheck, faPlus, faAward, faMedal, faIdBadge
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faClipboard, faClipboardList, faClipboardQuestion, faClipboardCheck,
    faUsers, faUserTag, faRoad, faCity, faBoxOpen, faTruck, faChevronDown,
    faChevronUp, faCircleNotch, faGears, faTv, faDownload, faBars, faTimes,
    faIndustry, faBuilding, faTags, faCodeBranch, faTimeline, faMobile, faPeopleRoof,faRoadCircleCheck, faPlus, faAward, faMedal, faIdBadge
)


import App from "@/App.vue";
import MazBtn from 'maz-ui/components/MazBtn'
import MazInput from 'maz-ui/components/MazInput'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'

// routes
import router from './router'

const app = createApp(App)

app.component('MazBtn', MazBtn)
app.component('MazInput', MazInput)
app.component('MazPhoneNumberInput', MazPhoneNumberInput)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(ElementPlus)
app.use(store)
app.use(router)
app.use(VueCsvImportPlugin)
app.use(Toaster, {
    // One of the options
    position: 'top'
})
app.mount("#app");
