import client from "./client";

export default {
    async getVehicles(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/vehicle', filters)));
    },

    async createVehicles(data) {
        return client.parseResponse(await client.post('/vehicle', data))
    },

    async getVehicle(id) {
        return client.parseResponse(await client.get('/vehicle/' + id));
    },

    async editVehicle(data) {
        return client.parseResponse(await client.put('/vehicle/' + data.id, data))
    },

    async uploadVehicles(payload) {
        return client.parseResponse(await client.upload('/import-vehicles', payload, payload.file))
    },

    async exportVehicles(filters) {
        return await client.get('/export-vehicles', filters, {responseType: 'blob'});
    },
}
