import client from "./client";

export default {
    async getTyreSpecifications(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/tyre-specifications', filters)));
    },

    async createTyreSpecifications(data) {
        return client.parseResponse(await client.post('/tyre-specifications', data))
    },

    async getTyreSpecification(id) {
        return client.parseResponse(await client.get('/tyre-specifications/' + id));
    },

    async editTyreSpecification(data) {
        return client.parseResponse(await client.put('/tyre-specifications/' + data.id, data))
    },

    async deleteTyreSpecification(payload) {
        return client.parseResponse(await client.delete('/tyre-specifications/' + payload.id));
    },

    async uploadTyreSpecifications(payload) {
        return client.parseResponse(await client.upload('/import-tyre-specifications', payload, payload.file))
    },

    async exportTyreSpecifications(filters) {
        return await client.get('/export-tyre-specifications', filters, {responseType: 'blob'});
    },
}
