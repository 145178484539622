import client from "./client";

export default {
    async getCompanyBranches(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/company-branches', filters)));
    },

    async createCompanyBranches(data) {
        return client.parseResponse(await client.post('/company-branches', data))
    },

    async getCompanyBranch(id) {
        return client.parseResponse(await client.get('/company-branches/' + id));
    },

    async editCompanyBranch(data) {
        return client.parseResponse(await client.put('/company-branches/' + data.id, data))
    },

    async deleteCompanyBranch(payload) {
        return client.parseResponse(await client.delete('/company-branches/' + payload.id));
    },

    // async uploadCompanyBranches(payload) {
    //     return client.parseResponse(await client.upload('/import-branches', payload, payload.file))
    // },
    //
    // async exportCompanyBranches(filters) {
    //     return await client.get('/export-branches', filters, {responseType: 'blob'});
    // },
}
