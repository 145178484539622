import api from "../api";
import {ElMessage} from 'element-plus'

export default {
    namespaced: true,
    state: {
        roles: {data: [], current_page: 1, total: 0, per_page: 10},
        role_permissions: {data: [], current_page: 1, total: 0, per_page: 10},
        errors: {
            data: {
                errors: {
                    name: [],
                    description: []
                }
            }
        },
        has_errors: false,
        dialogVisible: false,
        loading: false
    },
    getters: {
        dialogVisible: state => state.dialogVisible,
        roles: state => state.roles,
        errors: state => state.errors,
        has_errors: state => state.has_errors,
        loading: state => state.loading,
        role_permissions: state => state.role_permissions
    },
    mutations: {
        setRolePermissions(state, data) {
            state.role_permissions = data;
        },
        setRoles(state, data) {
            state.roles = data;
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        setDialogVisible(state, status) {
            state.dialogVisible = status;
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        errors: {
                            name: [],
                            description: []
                        }
                    }
                };
            }
        }
    },
    actions: {
        async getRoles({commit}, filters) {
            try {
                const response = await api.getRoles(filters);
                commit('setRoles', response);
            } catch (e) {
                ElMessage({
                    type: 'error',
                    message: e.response.data.message,
                    duration: 10000
                });
            }
        },
        async createRole({dispatch, commit}, data) {
            try {
                commit('setLoading', true)
                commit('hasErrors', false)
                await api.createRole(data);
                dispatch('getRoles', {
                    paginate: true
                })
                ElMessage({
                    type: 'success',
                    message: 'Role created successfully!',
                    duration: 10000
                });
                commit('setLoading', false)
                commit('setDialogVisible', false)
            } catch (e) {
                commit('setLoading', false)
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    type: 'error',
                    message: e.response.data.message,
                    duration: 10000
                });
            }
        },

        async editRole({dispatch, commit}, data) {
            try {
                commit('setLoading', true)
                commit('hasErrors', false)
                await api.editRole(data)
                ElMessage({
                    type: 'success',
                    message: 'Role updated successfully!',
                    duration: 10000
                });
                commit('setLoading', false)
                commit('setDialogVisible', false)
                dispatch('getRoles', {
                    paginate: true
                })
            } catch (e) {
                commit('setLoading', false)
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    type: 'error',
                    message: e.response.data.message,
                    duration: 10000
                });
            }
        },
        async getRolePermissions({commit}, data) {
            console.log('here')
            try {
                const response = await api.getRolePermissions(data);
                commit('setRolePermissions', response);

            } catch (e) {
                ElMessage({
                    type: 'error',
                    message: e.response.data.message,
                    duration: 10000
                });
            }
        },
        async saveRolePermissions({commit}, data) {
            try {
                commit('setLoading', true)
                commit('hasErrors', false)
                await api.saveRolePermissions(data);
                commit('setLoading', false)
                ElMessage({
                    type: 'success',
                    message: 'Role Permissions added successfully!',
                    duration: 10000
                });
                window.location.href = '/admin/roles'
            } catch (e) {
                commit('setLoading', false)
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    type: 'error',
                    message: e.response.data.message,
                    duration: 10000
                });
            }
        },
        async deleteRole({dispatch, commit}, data) {
            try {
                commit('hasErrors', false);
                const response = await api.deleteRole(data);
                dispatch('getRoles', {
                    paginate: true
                });
                if (response.status === 401) {
                    ElMessage({
                        type: 'warning',
                        message: response.message,
                        duration: 10000
                    });
                } else {
                    ElMessage({
                        type: 'success',
                        message: response.message,
                        duration: 10000
                    });
                }
            } catch (e) {
                commit('hasErrors', true);
                commit('setErrors', e.response)
                ElMessage({
                    type: 'error',
                    message: e.response.data.message,
                    duration: 10000
                });
            }
        },


    }
}
