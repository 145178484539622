import api from "../api";
import {ElMessage} from 'element-plus'
import router from "@/router";

export default {
    namespaced: true,
    state: {
        inspection_logs: {data: [], current_page: 1, total: 0, per_page: 10},
        inspection_log:{
            inspection: {},
            user:{},
            vehicle:{}
        },
        errors: {
            data: {
                errors: {
                    name: [],
                }
            }
        },
        has_errors: false,
        loading:false
    },
    getters: {
        inspection_logs: state => state.inspection_logs,
        inspection_log: state => state.inspection_log,
        errors: state => state.errors,
        has_errors: state => state.has_errors,
        loading:state => state.loading
    },
    mutations: {
        setInspectionLogs(state, data) {
            state.inspection_logs = data;
        },
        setInspectionLog(state, data) {
            state.inspection_log = data;
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        errors: {
                            name: [],
                        }
                    }
                };
            }
        },
        setLoading(state, loading) {
            state.loading = loading
        },
    },
    actions: {
        async getInspectionLogs({commit}, filters) {
            try {
                const response = await api.getInspectionLogs(filters);
                commit('setInspectionLogs', response);
            } catch (e) {
                console.log(e)
            }
        },
        async getInspectionLog({commit}, id) {
            try {
                const response = await api.getInspectionLog(id);
                commit('setInspectionLog', response);
            } catch (e) {
                console.log(e)
            }
        },
        async createInspectionLogs({commit, dispatch}, data) {
            try {
                commit('hasErrors', false)
                const response = await api.createInspectionLogs(data);
                console.log(response)
                dispatch('getInspection', response.id)
                await router.push(`/question-edit/${response.id}`)
                ElMessage({
                    message: 'Inspection created successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error creating Inspection!',
                    type: 'error',
                })
            }
        },
        async editInspectionLog({dispatch}, data) {
            try {
                await api.editInspectionLog(data);
                dispatch('getInspectionLogs', {
                    paginate: true
                });
                ElMessage({
                    message: 'Inspection updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: 'Error updating Inspection',
                    type: 'error',
                })
            }
        },
        async deleteInspectionLog({dispatch, commit}, payload) {
            try {
                const {message} = await api.deleteInspectionLog(payload)
                dispatch('getInspectionLogs', {})
                commit('setInspection', {})
                ElMessage({
                    message: message,
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            }
        },
        async exportInspectionLogs(context, filters) {
            try {
                const response = await api.exportInspectionLogs(filters);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data]));
                link.setAttribute('download', 'inspection history ' + Date.now() + '.xlsx');
                document.body.appendChild(link);
                link.click();
            } catch (e) {
                ElMessage({
                    message: 'Error exporting!',
                    type: 'error',
                })
            }
        },
        async downloadInspectionReport({commit}, data) {
            commit('setLoading',true)
            try {
                const response = await api.downloadInspectionReport(data);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data]));
                link.setAttribute('download', 'inspection history ' + Date.now() + '.pdf');
                document.body.appendChild(link);
                link.click();
                commit('setLoading', false)
            } catch (e) {
                commit('setLoading',false)
                ElMessage({
                    message: 'Error exporting!',
                    type: 'error',
                })
            }
        },
    }
}
