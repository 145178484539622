import client from "./client";

export default {
    async getInspectionLogs(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/inspection-logs', filters)));
    },

    async getInspectionLog(id) {
        return client.parseResponse(await client.get('/inspection-logs/' + id));
    },
    async exportInspectionLogs(filters) {
        return await client.get('/export-inspection-logs', filters, {responseType: 'blob'});
    },
    async downloadInspectionReport(filters) {
        return await client.post('/download-inspection-details', filters, {responseType: 'blob'});
    },
}
