<template>
    <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded border bg-white">

        <div class="rounded-t mb-0 px-4 py-5 border-b">
            <div class="flex flex-wrap items-center">
                <div
                    class="md:flex-row flex-col relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-lg">
                        Journey Hops
                    </h3>
                    <div class="flex flex-row justify-end">
                        <button class="bg-secondary text-white px-10 py-2 font-semibold rounded-md"
                                @click="addJourneyHopDialogVisible = true">
                            Add Journey Hop
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!--    Filters and Search-->
        <div class="flex flex-col py-6 px-6 space-y-2 md:space-x-4 space-x-0">

            <div class="flex lg:flex-row flex-col justify-between md:items-start ">
                <div class="flex flex-row">
                    <el-button-group>
                        <el-button @click="exportJourneyHops" type="success" plain>
                            <span class="mr-3">Export</span>
                            <el-icon>
                                <Download/>
                            </el-icon>
                        </el-button>
                        <el-button @click="uploadJourneyHopsDialogVisible=!uploadJourneyHopsDialogVisible"
                                   type="warning"
                                   size="default"
                                   plain>
                            <span class="mr-3">Import</span>
                            <el-icon>
                                <Upload/>
                            </el-icon>
                        </el-button>
                    </el-button-group>
                </div>
                <form>
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                 stroke="currentColor" class="w-5 h-5 mr-3">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                            </svg>
                        </div>
                        <input v-model="search"
                               class="block outline-none w-full px-12 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-secondary focus:border-secondary"
                               placeholder="Search Journey Hops...">
                    </div>
                </form>
            </div>

        </div>

        <!--Import dialog-->
        <el-dialog
            v-model="uploadJourneyHopsDialogVisible"
            title="Upload Routes"
            :width="windowWidth > 768 ? '40%' : '90%'">
            <div class="flex flex-col gap-y-2">
                <div class="flex flex-row items-center w-full rounded mb-4">
                    <a class="py-2 px-6 text-sky-600"
                       href="/templates/journey_hops_template.csv" download="">
                        <font-awesome-icon class="mr-2 text-sm"
                                           :icon="['fas', 'download']"/>
                        Please click here to download the hops template.
                    </a>
                </div>
                <form class="px-4">
                    <div class="flex flex-col items-start justify-start mb-3 w-full">
                        <p class="text-md font-bold text-sm lg:text-md mb-3">File (.xls or .xlsx or .csv)<span
                            class="text-red-500 ml-2">*</span></p>
                        <div class="flex flex-row items-start justify-start w-full mb-1">
                            <div class="flex flex-row border p-2 rounded-md w-full">
                                <input
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    class="outline-none w-full" @change="hopsFile" type="file" required/>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="dialog-footer flex flex-row w-full justify-end px-4">
                    <el-button class="bg-sky-600 text-white" @click="upload">
                        Upload
                    </el-button>
                    <el-button class="bg-red-700 text-white" type="danger"
                               @click="uploadJourneyHopsDialogVisible = false">Cancel
                    </el-button>
                </div>
            </div>
        </el-dialog>


        <!-- Add/Edit dialog-->
        <el-dialog
            v-model="addJourneyHopDialogVisible"
            title="Add Journey"
            :width="windowWidth > 768 ? '30%' : '90%'">
            <div class="flex flex-col gap-y-2">
                <form class="px-4">
                    <div class="flex flex-col items-start justify-start mb-3 w-full">
                        <p class="text-md font-bold text-sm lg:text-md mb-3">Branch From<span
                            class="text-red-500 ml-2">*</span></p>
                        <div class="flex flex-row rounded-md w-full">
                            <el-select
                                filterable
                                style="width: 100%" v-model="form.branch_from_id"
                                placeholder="Select a branch"
                                size="large">
                                <el-option
                                    v-for="item in company_branches"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                />
                            </el-select>
                        </div>
                        <span class="text-red-500" v-if="errors.data.web_errors.branch_from_id  && this.has_errors">
                                    {{ errors.data.web_errors.branch_from_id[0] }}
                                </span>
                    </div>

                    <div class="flex flex-col items-start justify-start mb-3 w-full">
                        <p class="text-md font-bold text-sm lg:text-md mb-3">Branch To<span
                            class="text-red-500 ml-2">*</span></p>
                        <div class="flex flex-row rounded-md w-full">
                            <el-select
                                filterable
                                style="width: 100%" v-model="form.branch_to_id"
                                placeholder="Select a branch"
                                size="large">
                                <el-option
                                    v-for="item in company_branches"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                />
                            </el-select>
                        </div>
                        <span class="text-red-500" v-if="errors.data.web_errors.branch_to_id  && this.has_errors">
                            {{ errors.data.web_errors.branch_to_id[0] }}
                        </span>
                    </div>
                    <div class="flex flex-col items-start justify-start mb-3 w-full">
                        <p class="text-md font-bold text-sm lg:text-md mb-3">Duration(Hrs)<span
                            class="text-red-500 ml-2">*</span></p>
                        <div class="flex flex-row items-start justify-start w-full mb-1">
                            <div class="flex flex-row rounded-md w-full">
                                <input
                                    class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                    v-model="form.duration_in_hrs" type="number"
                                    name="form.Distance" required/>
                            </div>
                        </div>
                        <span class="text-red-500" v-if="errors.data.web_errors.duration_in_hrs  && this.has_errors">
                                    {{ errors.data.web_errors.duration_in_hrs[0] }}
                                </span>
                    </div>
                    <div class="flex flex-col items-start justify-start mb-3 w-full">
                        <p class="text-md font-bold text-sm lg:text-md mb-3">Is it loaded?</p>
                        <div class="flex flex-row items-center">
                            <span class="mr-4">Loaded?</span>
                            <div>
                                <el-radio v-model="form.is_loaded" :label="1">Yes
                                </el-radio>
                                <el-radio v-model="form.is_loaded" :label="0">No</el-radio>
                            </div>
                        </div>
                        <span class="text-red-500" v-if="errors.data.web_errors.is_loaded  && this.has_errors">
                                    {{ errors.data.web_errors.is_loaded[0] }}
                                </span>
                    </div>
                </form>
                <div class="dialog-footer flex flex-row w-full justify-end px-4">
                    <el-button class="bg-red-700 text-white" type="danger"
                               @click="addJourneyHopDialogVisible = false">
                        Cancel
                    </el-button>
                    <el-button class="bg-sky-600 text-white" @click="addJourneyHop">
                        Save Journey Hop
                    </el-button>
                </div>
            </div>
        </el-dialog>

        <div class="block w-full overflow-x-auto">
            <!--Table-->
            <div class="py-8 px-4">
                <el-table class="" :data="journey_hops.data" stripe size="large" style="width: 100%">
                    <el-table-column prop="branch_from.name" label="BRANCH FROM"/>
                    <el-table-column prop="branch_to.name" label="BRANCH TO"/>
                    <el-table-column prop="is_loaded" label="IS LOADED"/>
                    <!--                    <el-table-column label="IS LOADED">-->
                    <!--                        <template #default="scope">-->
                    <!--                            <el-tag effect="dark"-->
                    <!--                                    :type="scope.row.status === 'Open' ? 'warning' : scope.row.status === 'Closed' ? '' : 'success'">-->
                    <!--                                {{ scope.row.status }}-->
                    <!--                            </el-tag>-->
                    <!--                        </template>-->
                    <!--                    </el-table-column>-->
                    <el-table-column prop="duration_in_hrs" label="DURATION(HRS)"/>
                    <el-table-column label="ACTIONS">
                        <template #default="scope">
                            <el-dropdown size="large">
                                <el-button>
                                    Actions
                                    <el-icon class="el-icon--right">
                                        <arrow-down/>
                                    </el-icon>
                                </el-button>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item @click.native="updateJourneyHop(scope.row)">EDIT
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="removeJourneyHop(scope.row)">DELETE
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="flex flex-row p-5 w-full justify-end">
                <el-pagination
                    :current-page="journey_hops.current_page"
                    :page-size="journey_hops.per_page"
                    :page-sizes="[5,10,20,50]"
                    :small=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="journey_hops.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </div>

    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import {ArrowDown, Download, Upload} from "@element-plus/icons-vue";
import moment from "moment/moment";

export default {
    components: {Upload, Download, ArrowDown},
    data() {
        return {
            search: '',
            windowWidth: window.innerWidth,
            addJourneyHopDialogVisible: false,
            uploadJourneyHopsDialogVisible: false,
            form: {},
            user: {},
            journey_hop:{},
            filters: {
                paginate: true,
                per_page: 10
            },
            date_range: [],
            shortcuts: [
                {
                    text: 'Today',
                    value: new Date(),
                },
                {
                    text: 'Yesterday',
                    value: () => {
                        const date = new Date()
                        date.setTime(date.getTime() - 3600 * 1000 * 24)
                        return date
                    },
                },
                {
                    text: 'This week',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setHours(0, 0, 0, 0); // Start of the current day
                        const dayOfWeek = start.getDay(); // 0 (Sunday) to 6 (Saturday)
                        const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
                        start.setDate(start.getDate() - daysToSubtract);
                        return [start, end];
                    },
                },
                {
                    text: 'Last week',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                        return [start, end]
                    },
                },
                {
                    text: 'This month',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setDate(1); // Start of the current month
                        return [start, end];
                    },
                },
                {
                    text: 'Last month',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                        return [start, end]
                    },
                },
                {
                    text: 'Last 3 months',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                        return [start, end]
                    },
                },
                {
                    text: 'This year',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setMonth(0, 1); // Start of the current year
                        return [start, end];
                    },
                },
            ]
        };
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.getJourneyHops({
            ...this.filters,
            id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
        })
        this.getCompanyBranches({
            id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
        })
    },
    computed: {
        ...mapGetters({
            'errors': "journeys/errors",
            'has_errors': 'journeys/has_errors',
            journey_hops: 'journeys/journey_hops',
            company_branches: 'company_branches/company_branches',
        }),
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.getJourneyHops({
                    ...this.filters,
                    id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
                })
            }
        },
        search: function (val) {
            this.getJourneyHops({
                ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id,
                search: val
            });
        },
        'date_range': function (val) {
            this.filters.start = moment(val[0]).format('DD-MM-YYYY HH:MM')
            this.filters.end = moment(val[1]).format('DD-MM-YYYY HH:MM')
            this.getJourneyHops({
                ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id,
            })
        },

    },
    methods: {
        ...mapActions({
            getJourneyHops: 'journeys/getJourneyHops',
            createJourneyHops: 'journeys/createJourneyHops',
            editJourneyHop: 'journeys/editJourneyHop',
            deleteJourneyHop: 'journeys/deleteJourneyHop',
            getCompanyBranches: 'company_branches/getCompanyBranches',
            uploadJourneyHops:'journeys/uploadJourneyHops'
        }),
        handleSizeChange(size) {
            this.filters.per_page = size;
            this.getJourneyHops({
                paginate: true, ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            });
        },
        handleCurrentChange(page) {
            this.filters.page = page;
            this.getJourneyHops({
                paginate: true, ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            });
        },
        addJourneyHop() {
            this.form.company_id = this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            if (this.form.id) {
                this.editJourneyHop(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addJourneyHopDialogVisible = false
                    }
                })
            } else {
                this.createJourneyHops(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addJourneyHopDialogVisible = false
                    }
                });
            }
        },
        updateJourneyHop(item) {
            this.addJourneyHopDialogVisible = true
            this.form = Object.assign({}, this.form, item);
        },
        removeJourneyHop(item) {
            let conf = confirm(`Are you sure you want to remove this item?`)
            if (conf) {
                this.deleteJourneyHop({
                    id: item.id
                })
            }
        },
        hopsFile(e) {
            this.file = e.target.files[0]
        },
        upload() {
            this.journey_hop.file = this.file;
            this.journey_hop.company_id = this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            this.uploadJourneyHops(this.journey_hop)
                .then(() => {
                    this.getJourneyHops(this.filters)
                    this.uploadJourneyHopsDialogVisible = false
                    this.$message.success('Journey hops uploaded successfully!')
                }).catch((e) => {
                this.$message.error(e.response.data.message)
            })
        },
    }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: transparent;
    display: flex;
    align-items: center;
}
</style>
