import client from "./client";

export default {
    async getTyreSizes(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/tyre-sizes', filters)));
    },

    async createTyreSizes(data) {
        return client.parseResponse(await client.post('/tyre-sizes', data))
    },

    async getTyreSize(id) {
        return client.parseResponse(await client.get('/tyre-sizes/' + id));
    },

    async editTyreSize(data) {
        return client.parseResponse(await client.put('/tyre-sizes/' + data.id, data))
    },

    async deleteTyreSize(payload) {
        return client.parseResponse(await client.delete('/tyre-sizes/' + payload.id));
    },

    async uploadTyreSizes(payload) {
        return client.parseResponse(await client.upload('/import-tyre-sizes', payload, payload.file))
    },

    async exportTyreSizes(filters) {
        return await client.get('/export-tyre-sizes', filters, {responseType: 'blob'});
    },
}
