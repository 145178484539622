<template>
    <div class="flex flex-col min-w-0 break-words w-full mb-6">

        <div class="rounded-t mb-0 px-4 py-5 border-b">
            <div class="flex flex-wrap items-center">
                <div
                    class="md:flex-row flex-col relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-medium text-base">
                        TyreTreadPatterns
                    </h3>
                    <div class="flex flex-row justify-end">
                        <button v-if="user.is_admin" class="bg-secondary text-white px-10 py-2 font-semibold rounded-md"
                                @click="addTyreTreadPatternDialogVisible = true">Add Tyre Tread Pattern
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!--    Filters and Search-->
        <div
            class="flex lg:flex-row flex-col justify-between md:items-end md:space-y-0 space-y-2 md:space-x-4 space-x-0 py-8 px-6 border-b w-full">
            <div class="flex flex-row justify-start items-end mb-3 space-x-4">

                <!--        Export and Import-->
                <div v-if="user.is_admin" class="flex flex-row">
                    <el-button-group>
                        <el-button @click="exportTyreTreadPatterns" type="success" plain>
                            <span class="mr-3">Export</span>
                            <el-icon>
                                <Download/>
                            </el-icon>
                        </el-button>
                        <el-button @click="uploadTyreTreadPatternDialogVisible=!uploadTyreTreadPatternDialogVisible"
                                   type="warning"
                                   size="default"
                                   plain>
                            <span class="mr-3">Import</span>
                            <el-icon>
                                <Upload/>
                            </el-icon>
                        </el-button>
                    </el-button-group>
                </div>
            </div>

            <form>
                <div class="relative">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" class="w-5 h-5 mr-3">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                        </svg>
                    </div>
                    <input v-model="search"
                           class="block outline-none w-full px-12 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-secondary focus:border-secondary"
                           placeholder="Search Tyre Tread Patterns ...">
                </div>
            </form>
        </div>

        <div class="block w-full overflow-x-auto">

            <!--Import dialog-->
            <el-dialog
                v-model="uploadTyreTreadPatternDialogVisible"
                title="Upload Tyre Stores"
                :width="windowWidth > 768 ? '40%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <div class="flex flex-row items-center w-full rounded mb-4">
                        <a class="py-2 px-6 text-sky-600"
                           href="/templates/tyre_tread_patterns_template.csv" download="">
                            <font-awesome-icon class="mr-2 text-sm"
                                               :icon="['fas', 'download']"/>
                            Please click here to download the tyre_tread_patterns template.
                        </a>
                    </div>
                    <form class="px-4">
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">File (.xls or .xlsx or .csv)<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row border p-2 rounded-md w-full">
                                    <input
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        class="outline-none w-full" @change="companyFile" type="file" required/>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="upload">
                            Upload
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="uploadTyreTreadPatternDialogVisible = false">
                            Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!-- Add/Edit dialog-->
            <el-dialog
                v-model="addTyreTreadPatternDialogVisible"
                title="Add/Edit Tyre Stores"
                :width="windowWidth > 768 ? '30%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <form class="px-4">
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Tread Patterns Name
                                <span class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.tread_pattern_name" type="text"
                                        name="form.name" required/>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="addTyreTreadPattern">
                            Save
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="addTyreTreadPatternDialogVisible = false">
                            Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!--Table-->
            <div class="py-8 px-4">
                <el-table class="" :data="tyre_tread_patterns.data" stripe size="large" style="width: 100%">
                    <el-table-column prop="tread_pattern_name" label="TREAD PATTERN NAME"/>
                    <el-table-column prop="created_at" label="CREATED AT"/>
                    <el-table-column v-if="user.is_admin" label="ACTIONS">
                        <template #default="scope">
                            <el-dropdown size="large">
                                <el-button>
                                    Actions
                                    <el-icon class="el-icon--right">
                                        <arrow-down/>
                                    </el-icon>
                                </el-button>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item @click.native="updateTyreTreadPattern(scope.row)">EDIT
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="deleteTyreTreadPattern(scope.row)">DELETE
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="flex flex-row p-5 w-full justify-end">
                <el-pagination
                    :current-page="tyre_tread_patterns.current_page"
                    :page-size="tyre_tread_patterns.per_page"
                    :page-sizes="[5,10,20,50]"
                    :small=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tyre_tread_patterns.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </div>

    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {ArrowDown, Upload, Download} from '@element-plus/icons-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
    data() {
        return {
            search: '',
            uploadTyreTreadPatternDialogVisible: false,
            addTyreTreadPatternDialogVisible: false,
            windowWidth: window.innerWidth,
            form: {},
            tyre_tread_pattern: {},
            filters: {
                paginate: true,
                per_page: 10
            },
            user: {}
        };
    },
    components: {
        ArrowDown, Upload, Download

    },
    mounted() {
        this.getTyreTreadPatternsList(this.filters)
        this.getUser()
    },
    computed: {
        models() {
            var model_data = this.tyre_tread_patterns.data
            return model_data?.map(function (item) {
                return item.model;
            }).filter(
                (item, index, arr) => {
                    return arr.indexOf(item) === index
                }
            );
        },
        ...mapGetters({
            tyre_tread_patterns: 'tyre_tread_patterns/tyre_tread_patterns',
            companies: 'companies/companies'
        })
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.getTyreTreadPatternsList(this.filters)
            }
        },
        search: function (val) {
            this.getTyreTreadPatternsList({
                ...this.filters,
                search: val
            });
        }
    },
    methods: {
        ...mapActions({
            getTyreTreadPatternsList: 'tyre_tread_patterns/getTyreTreadPatterns',
            uploadTyreTreadPatterns: 'tyre_tread_patterns/uploadTyreTreadPatterns',
            downloadTyreTreadPatterns: 'tyre_tread_patterns/exportTyreTreadPatterns',
            createTyreTreadPatterns: 'tyre_tread_patterns/createTyreTreadPatterns',
            editTyreTreadPattern: 'tyre_tread_patterns/editTyreTreadPattern',
            removeTyreTreadPattern: 'tyre_tread_patterns/deleteTyreTreadPattern'
        }),
        getUser() {
            this.user = JSON.parse(localStorage.getItem('user'));
        },
        handleSizeChange(size) {
            this.filters.per_page = size;
            this.getTyreTreadPatternsList({paginate: true, ...this.filters});
        },
        handleCurrentChange(page) {
            this.filters.page = page;
            this.getTyreTreadPatternsList({paginate: true, ...this.filters});
        },
        addTyreTreadPattern() {
            if (this.form.id) {
                this.editTyreTreadPattern(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addTyreTreadPatternDialogVisible = false
                    }
                })
            } else {
                this.createTyreTreadPatterns(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addTyreTreadPatternDialogVisible = false
                    }
                });
            }
        },
        updateTyreTreadPattern(item) {
            this.addTyreTreadPatternDialogVisible = true
            this.form = Object.assign({}, this.form, item);
        },
        deleteTyreTreadPattern(item) {
            let conf = confirm(`Are you sure you want to remove this tyre tread pattern?`)
            if (conf) {
                this.removeTyreTreadPattern({
                    id: item.id
                })
            }
        },
        upload() {
            this.tyre_tread_pattern.file = this.file;
            this.uploadTyreTreadPatterns(this.tyre_tread_pattern)
                .then(() => {
                    this.getTyreTreadPatternsList(this.filters)
                    this.uploadTyreTreadPatternDialogVisible = false
                    this.$message.success('TyreTreadPatterns uploaded successfully!')
                }).catch((e) => {
                this.$message.error(e.response.data.message)
            })
        },
        companyFile(e) {
            this.file = e.target.files[0]
        },
        exportTyreTreadPatterns() {
            this.downloadTyreTreadPatterns({...this.filters})
        }

    }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: transparent;
    display: flex;
    align-items: center;
}
</style>
