<template>
    <div>
        <div class="container mx-auto px-4 py-8">
            <h1 class="text-3xl font-bold mb-4">Privacy Policy for Vuma Prime</h1>
            <p class="text-gray-600">Last updated: March 13, 2024</p>

            <p class="mt-8">This Privacy Policy describes the policies and procedures regarding the collection, use, and disclosure of personal information when users ("you") use the Vuma Prime mobile application ("the App") and outlines your privacy rights and protections provided by law.</p>

            <h2 class="text-xl font-bold mt-8">1. Interpretation and Definitions</h2>
            <p class="mt-4">1.1 <span class="font-semibold">Interpretation</span><br>The words capitalized in this Privacy Policy have specific meanings defined under certain conditions. These definitions are applicable regardless of whether they appear in singular or plural form.</p>
            <p class="mt-4">1.2 <span class="font-semibold">Definitions</span><br>For the purpose of this Privacy Policy:</p>
            <ul class="list-disc list-inside mt-2">
                <li><span class="font-semibold">App:</span> Refers to the Vuma Prime mobile application developed by Primemetrics.</li>
                <li><span class="font-semibold">Company:</span> Primemetrics, Nairobi, Kenya.</li>
                <li><span class="font-semibold">Personal Data:</span> Any information that identifies or can be used to identify an individual.</li>
            </ul>

            <h2 class="text-xl font-bold mt-8">2. Collection and Use of Personal Data</h2>
            <h3 class="text-lg font-semibold mt-4">2.1 Types of Data Collected</h3>
            <p class="mt-2">2.1.1 <span class="font-semibold">Personal Data</span><br>While using the App, we may collect certain personally identifiable information, including but not limited to:</p>
            <ul class="list-disc list-inside mt-2">
                <li>Email address</li>
                <li>Usage Data</li>
            </ul>
            <p class="mt-2">2.1.2 <span class="font-semibold">Usage Data</span><br>We automatically collect usage data when you use the App. This may include information such as your device's Internet Protocol (IP) address, pages of the App visited, time and date of visit, time spent on pages, and other diagnostic data.</p>
            <p class="mt-2">2.1.3 <span class="font-semibold">Location Information and Camera Access</span><br>With your prior permission, the App may collect information regarding your location and access pictures or other information from your device's camera and photo library. This information is used to provide features of the Service and improve user experience.</p>

            <h3 class="text-lg font-semibold mt-8">2.2 Use of Personal Data</h3>
            <p class="mt-2">We may use the collected personal data for the following purposes:</p>
            <ul class="list-disc list-inside mt-2">
                <li>To provide and maintain the App</li>
                <li>To manage your account and registration as a user</li>
                <li>To contact you regarding updates or informative communications related to the Service</li>
                <li>To provide news, special offers, and general information about goods, services, and events similar to those you have enquired about or purchased</li>
                <li>To manage and respond to your requests</li>
                <li>To analyze usage trends and improve the functionality of the App</li>
            </ul>

            <h2 class="text-xl font-bold mt-8">3. Sharing of Personal Data</h2>
            <p class="mt-4">We may share your personal information in the following circumstances:</p>
            <ul class="list-disc list-inside mt-2">
                <li>With Service Providers to facilitate the Service</li>
                <li>In connection with business transfers or transactions</li>
                <li>With affiliates and business partners</li>
                <li>With other users or as required by law</li>
            </ul>

            <h2 class="text-xl font-bold mt-8">4. Data Retention and Deletion</h2>
            <p class="mt-4">We will retain your personal data only for as long as necessary for the purposes outlined in this Privacy Policy. You have the right to request deletion of your personal data. Please contact us at <a href="mailto:info@primemetrics.net" class="text-blue-500">info@primemetrics.net</a> for assistance.</p>

            <h2 class="text-xl font-bold mt-8">5. Security of Personal Data</h2>
            <p class="mt-4">We take reasonable precautions to protect your personal data; however, no method of transmission over the internet or electronic storage is 100% secure. We cannot guarantee the absolute security of your data.</p>

            <h2 class="text-xl font-bold mt-8">6. Children's Privacy</h2>
            <p class="mt-4">The App is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe your child has provided us with personal data, please contact us.</p>

            <h2 class="text-xl font-bold mt-8">7. Links to Other Websites</h2>
            <p class="mt-4">The App may contain links to third-party websites. We are not responsible for the content or privacy practices of these websites. We encourage you to review the privacy policies of these websites.</p>

            <h2 class="text-xl font-bold mt-8">8. Changes to this Privacy Policy</h2>
            <p class="mt-4">We reserve the right to update or modify this Privacy Policy at any time. We will notify you of any changes by posting the updated Privacy Policy on this page.</p>

            <h2 class="text-xl font-bold mt-8">9. Contact Us</h2>
            <p class="mt-4">If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:info@primemetrics.net" class="text-blue-500">info@primemetrics.net</a>.</p>

            <p class="mt-4">By downloading, installing, or using the App, you consent to the collection and use of your personal data as outlined in this Privacy Policy.</p>

            <p class="mt-4">This Privacy Policy is effective as of the last updated date indicated at the top of this page.</p>
        </div>
    </div>
</template>
<script>

</script>
