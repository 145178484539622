import api from "../api";
import {ElMessage} from 'element-plus'

export default {
    namespaced: true,
    state: {
        towns: {data: [], current_page: 1, total: 0, per_page: 10},
        company_towns: {data: [], current_page: 1, total: 0, per_page: 10},
        errors: {
            data: {
                errors: {
                    name: [],
                }
            }
        },
        has_errors: false,
    },
    getters: {
        towns: state => state.towns,
        company_towns: state => state.company_towns,
        errors: state => state.errors,
        has_errors: state => state.has_errors,
    },
    mutations: {
        setTowns(state, data) {
            state.towns = data;
        },
        setCompanyTowns(state, data) {
            state.company_towns = data;
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        errors: {
                            name: [],
                        }
                    }
                };
            }
        }
    },
    actions: {
        async getTowns({commit}, filters) {
            try {
                const response = await api.getTowns(filters);
                commit('setTowns', response);
            } catch (e) {
                console.log(e)
            }
        },

        async createTowns({commit, dispatch}, data) {
            try {
                commit('hasErrors', false)
                await api.createTowns(data);
                dispatch('getTowns', {
                    paginate: true
                })

                ElMessage({
                    message: 'Town created successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error creating Town!',
                    type: 'error',
                })
            }
        },
        async editTown({dispatch}, data) {
            try {
                await api.editTown(data);
                dispatch('getTowns', {
                    paginate: true
                });
                ElMessage({
                    message: 'Town updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: 'Error updating Town',
                    type: 'error',
                })
            }
        },
        async getCompanyTowns({commit}, filters) {
            try {
                const response = await api.getCompanyTowns(filters);
                commit('setCompanyTowns', response);
            } catch (e) {
                console.log(e)
            }
        },

        async createCompanyTowns({commit, dispatch}, data) {
            try {
                commit('hasErrors', false)
                await api.createCompanyTowns(data);
                dispatch('getCompanyTowns', {
                    paginate: true
                })

                ElMessage({
                    message: 'Town created successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error creating Town!',
                    type: 'error',
                })
            }
        },
        async editCompanyTown({dispatch}, data) {
            try {
                await api.editCompanyTown(data);
                dispatch('getCompanyTowns', {
                    paginate: true
                });
                ElMessage({
                    message: 'Town updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: 'Error updating Town',
                    type: 'error',
                })
            }
        },
        async uploadTowns(context, payload) {
            return await api.uploadTowns(payload)
        },
        async exportTowns(context, filters) {
            try {
                const response = await api.exportTowns(filters);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data]));
                link.setAttribute('download', 'towns ' + Date.now() + '.xlsx');
                document.body.appendChild(link);
                link.click();
            } catch (e) {
                ElMessage({
                    message: 'Error exporting!',
                    type: 'error',
                })
            }
        },
        async uploadCompanyTowns(context, payload) {
            return await api.uploadCompanyTowns(payload)
        },
        async exportCompanyTowns(context, filters) {
            try {
                const response = await api.exportCompanyTowns(filters);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data]));
                link.setAttribute('download', 'company_towns ' + Date.now() + '.xlsx');
                document.body.appendChild(link);
                link.click();
            } catch (e) {
                ElMessage({
                    message: 'Error exporting!',
                    type: 'error',
                })
            }
        },

    }
}
