import api from "../api";
import {ElMessage} from 'element-plus'

export default {
    namespaced: true,
    state: {
        routes: {data: [], current_page: 1, total: 0, per_page: 10},
        errors: {
            data: {
                errors: {
                    name: [],
                }
            }
        },
        has_errors: false,
    },
    getters: {
        routes: state => state.routes,
        errors: state => state.errors,
        has_errors: state => state.has_errors,
    },
    mutations: {
        setRoutes(state, data) {
            state.routes = data;
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        errors: {
                            name: [],
                        }
                    }
                };
            }
        }
    },
    actions: {
        async getRoutes({commit}, filters) {
            try {
                const response = await api.getRoutes(filters);
                commit('setRoutes', response);
            } catch (e) {
                console.log(e)
            }
        },

        async createRoutes({commit, dispatch}, data) {
            try {
                commit('hasErrors', false)
                await api.createRoutes(data);
                dispatch('getRoutes', {
                    paginate: true,
                    company_id:data.company_id
                })

                ElMessage({
                    message: 'Route created successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error creating Route!',
                    type: 'error',
                })
            }
        },
        async editRoute({dispatch}, data) {
            try {
                await api.editRoute(data);
                dispatch('getRoutes', {
                    paginate: true,
                    company_id:data.company_id
                });
                ElMessage({
                    message: 'Route updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: 'Error updating Route',
                    type: 'error',
                })
            }
        },
        async deleteRoute({dispatch}, payload) {
            try {
                const {message} = await api.deleteRoute(payload)
                dispatch('getRoutes', {})
                ElMessage({
                    message: message,
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            }
        },
        async uploadRoutes(context, payload) {
            return await api.uploadRoutes(payload)
        },
        async exportRoutes(context, filters) {
            try {
                const response = await api.exportRoutes(filters);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data]));
                link.setAttribute('download', 'routes ' + Date.now() + '.xlsx');
                document.body.appendChild(link);
                link.click();
            } catch (e) {
                ElMessage({
                    message: 'Error exporting!',
                    type: 'error',
                })
            }
        },

    }
}
