<template>
  <div>
    <sidebar/>
    <div class="flex flex-col min-h-screen md:ml-64 bg-gray-50">
      <admin-navbar/>
      <div class="px-4 flex-grow mt-8 rounded-lg">
        <router-view/>
      </div>
      <footer-admin/>
    </div>

  </div>
</template>
<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import FooterAdmin from "@/components/Footers/FooterAdmin.vue";

export default {
  name: "admin-layout",
  components: {
    AdminNavbar,
    Sidebar,
    FooterAdmin,
  },
};
</script>
