<template>
    <div>
        <a
            class="text-slate-500 block"
            href="#"
            ref="btnDropdownRef"
            v-on:click="toggleDropdown($event)"
        >
            <div class="items-center flex">
        <span
            class="w-12 h-12 text-sm text-white bg-slate-200 inline-flex items-center justify-center rounded-full"
        >
          <img
              alt="..."
              class="w-full rounded-full align-middle border-none shadow-lg"
              src="@/assets/img/profile.png"
          />
        </span>
            </div>
        </a>
        <div
            ref="popoverDropdownRef"
            class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
            v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
        >
            <a
                href="javascript:void(0);"
                class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700 bg-green/20"
            >
                {{ user.first_name + " " + user.last_name}}
            </a>
            <div class="h-0 my-2 border border-solid border-slate-100"/>
            <button @click="logoutUser"
                    class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700">
                Logout
            </button>
        </div>
    </div>
</template>

<script>
import {createPopper} from "@popperjs/core";
import {mapActions} from "vuex";

export default {
    data() {
        return {
            dropdownPopoverShow: false,
            user: {}
        };
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('user'));
    },
    methods: {
        ...mapActions({
            logout: 'auth/logout',
        }),
        toggleDropdown: function (event) {
            event.preventDefault();
            if (this.dropdownPopoverShow) {
                this.dropdownPopoverShow = false;
            } else {
                this.dropdownPopoverShow = true;
                createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
                    placement: "bottom-start",
                });
            }
        },
        logoutUser() {
            this.logout();
        }
    },
};
</script>
