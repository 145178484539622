import client from "./client";

export default {
    async getCommodities(filters){
        return client.parseResponse(client.parseResponse(await client.get('/commodities', filters)));
    },

    async createCommodities(data) {
        return client.parseResponse(await client.post('/commodities', data))
    },

    async getCommodity(id) {
        return client.parseResponse(await client.get('/commodities/' + id));
    },

    async editCommodity(data) {
        return client.parseResponse(await client.put('/commodities/' + data.id, data))
    },

    async deleteCommodity(payload) {
        return client.parseResponse(await client.delete('/commodities/' + payload.id));
    },

    async uploadCommodities(payload) {
        return client.parseResponse(await client.upload('/import-commodities', payload, payload.file))
    },

    async exportCommodities(filters) {
        return await client.get('/export-commodities', filters, {responseType: 'blob'});
    },
}
