import client from "./client";

export default {
    async getVehicleTypes(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/vehicle-types', filters)));
    },

    async createVehicleTypes(data) {
        return client.parseResponse(await client.post('/vehicle-types', data))
    },

    async getVehicleType(id) {
        return client.parseResponse(await client.get('/vehicle-types/' + id));
    },

    async editVehicleType(data) {
        return client.parseResponse(await client.put('/vehicle-types/' + data.id, data))
    },

    async deleteVehicleType(payload) {
        return client.parseResponse(await client.delete('/vehicle-types/' + payload.id));
    },

    async uploadVehicleTypes(payload) {
        return client.parseResponse(await client.upload('/import-vehicle-axel-structures', payload, payload.file))
    },

    async exportVehicleTypes(filters) {
        return await client.get('/export-vehicle-axel-structures', filters, {responseType: 'blob'});
    },
}
