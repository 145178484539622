import client from "./client";

export default {
    async getFleets(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/fleets', filters)));
    },

    async createFleets(data) {
        return client.parseResponse(await client.post('/fleets', data))
    },

    async getFleet(id) {
        return client.parseResponse(await client.get('/fleets/' + id));
    },

    async editFleet(data) {
        return client.parseResponse(await client.put('/fleets/' + data.id, data))
    },

    async deleteFleet(payload) {
        return client.parseResponse(await client.delete('/fleets/' + payload.id));
    },

    async uploadFleets(payload) {
        return client.parseResponse(await client.upload('/import-fleets', payload, payload.file))
    },

    async exportFleets(filters) {
        return await client.get('/export-fleets', filters, {responseType: 'blob'});
    },
}
