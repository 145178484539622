import api from "../api";
import {ElMessage} from 'element-plus'
import router from "@/router";

export default {
    namespaced: true,
    state: {
        inspections: {data: [], current_page: 1, total: 0, per_page: 10},
        inspection_questions: {data: [], current_page: 1, total: 0, per_page: 10},
        vehicles: {data: [], current_page: 1, total: 0, per_page: 10},
        unassigned_vehicles: {data: [], current_page: 1, total: 0, per_page: 10},
        final_inspections: {},
        inspection: {},
        errors: {
            data: {
                errors: {
                    name: [],
                }
            }
        },
        has_errors: false,
    },
    getters: {
        inspection: state => state.inspection,
        inspections: state => state.inspections,
        vehicles: state => state.vehicles,
        unassigned_vehicles: state => state.unassigned_vehicles,
        final_inspections: state => state.final_inspections,
        errors: state => state.errors,
        has_errors: state => state.has_errors,
        inspection_questions: state => state.inspection_questions,
    },
    mutations: {
        setInspectionQuestions(state, data) {
            state.inspection_questions = data;
        },
        setInspection(state, data) {
            state.inspection = data;
        },
        setInspections(state, data) {
            state.inspections = data;
        },
        setVehicles(state, data) {
            state.vehicles = data;
        },
        setUnassignedVehicles(state, data) {
            state.unassigned_vehicles = data;
        },
        setFinalInspections(state, data) {
            state.final_inspections = data;
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        errors: {
                            name: [],
                        }
                    }
                };
            }
        }
    },
    actions: {
        async getInspectionVehicles({commit}, filters) {
            try {
                const response = await api.getInspectionVehicles(filters);
                commit('setVehicles', response);
            } catch (e) {
                console.log(e)
            }
        },
        async getFinalInspections({commit}, filters) {
            try {
                const response = await api.getFinalInspections(filters);
                commit('setFinalInspections', response);
            } catch (e) {
                console.log(e)
            }
        },
        async getUnassignedVehicles({commit}, filters) {
            try {
                const response = await api.getUnassignedVehicles(filters);
                commit('setUnassignedVehicles', response);
            } catch (e) {
                console.log(e)
            }
        },
        async getInspectionQuestions({commit}, filters) {
            try {
                const response = await api.getAllInspectionQuestions(filters);
                commit('setInspectionQuestions', response);
            } catch (e) {
                console.log(e)
            }
        },
        async getInspections({commit}, filters) {
            try {
                const response = await api.getInspections(filters);
                commit('setInspections', response);
            } catch (e) {
                console.log(e)
            }
        },
        async getInspection({commit}, id) {
            try {
                const response = await api.getInspection(id);
                commit('setInspection', response);
            } catch (e) {
                console.log(e)
            }
        },

        async createInspections({commit, dispatch}, data) {
            try {
                commit('hasErrors', false)
                const response = await api.createInspections(data);
                console.log(response)
                dispatch('getInspection', response.id)
                await router.push(`/question-edit/${response.id}`)
                ElMessage({
                    message: 'Inspection created successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error creating Inspection!',
                    type: 'error',
                })
            }
        },
        async editInspection({dispatch}, data) {
            try {
                await api.editInspection(data);
                dispatch('getInspections', {
                    paginate: true
                });
                ElMessage({
                    message: 'Inspection updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: 'Error updating Inspection',
                    type: 'error',
                })
            }
        },
        async deleteInspection({dispatch, commit}, payload) {
            try {
                const {message} = await api.deleteInspection(payload)
                dispatch('getInspections', {})
                commit('setInspection', {})
                ElMessage({
                    message: message,
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            }
        },
    }
}
