import client from "./client";

export default {
    async getModules(data) {
        return client.parseResponse(await client.get('/company-modules/' + data.company_id));
    },

    async saveCompanyModules(data) {
        return client.parseResponse(await client.post('/company-modules', data));
    },

}
