import api from "../api";
import {ElMessage} from "element-plus";

export default {
    namespaced: true,
    state: {
        defects: {data: [], current_page: 1, total: 0, per_page: 10},
        defect: {
            inspection: {},
            user: {},
            vehicle: {},
            fail_images: {},
            option_fail_images: {},
            inspection_log: {},
            fail_comments: {},
            option_fail_comments: {},
            inspection_question: {},
            inspection_question_option: {}
        },
    },
    getters: {
        defects: state => state.defects,
        defect: state => state.defect,
    },
    mutations: {
        setDefects(state, data) {
            state.defects = data;
        },
        setDefect(state, data) {
            state.defect = data;
        },
    },
    actions: {
        async getDefects({commit}, filters) {
            try {
                const response = await api.getDefects(filters);
                commit('setDefects', response);
            } catch (e) {
                console.log(e)
            }
        },
        async getDefect({commit}, id) {
            try {
                const response = await api.getDefect(id);
                commit('setDefect', response);
            } catch (e) {
                console.log(e)
            }
        },
        async editDefect({dispatch}, data) {
            try {
                await api.editDefect(data);
                dispatch('getDefect', data.id);
                ElMessage({
                    message: 'Status updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: 'Error updating Status',
                    type: 'error',
                })
            }
        },
        async exportDefects(context, filters) {
            try {
                const response = await api.exportDefects(filters);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data]));
                link.setAttribute('download', 'defects ' + Date.now() + '.xlsx');
                document.body.appendChild(link);
                link.click();
            } catch (e) {
                ElMessage({
                    message: 'Error exporting!',
                    type: 'error',
                })
            }
        },

    }
}
