import client from "./client";

export default {
    async getSmsUsers(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/sms-users', filters)));
    },

    async createSmsUsers(data) {
        return client.parseResponse(await client.post('/sms-users', data))
    },

    async getSmsUser(id) {
        return client.parseResponse(await client.get('/sms-users/' + id));
    },

    async editSmsUser(data) {
        return client.parseResponse(await client.put('/sms-users/' + data.id, data))
    },

    async deleteSmsUser(payload) {
        return client.parseResponse(await client.delete('/sms-users/' + payload.id));
    },

}
