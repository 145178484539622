import client from "./client";

export default {
    async getTyres(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/get-tyre', filters)));
    },

    async createTyres(data) {
        return client.parseResponse(await client.upload('/tyre-onboarding', data))
    },

    async getTyre(id) {
        return client.parseResponse(await client.get('/vehicle/' + id));
    },

    async editTyre(data) {
        return client.parseResponse(await client.post('/update-tyre/' + data.id, data))
    },

    async deleteTyre(payload) {
        return client.parseResponse(await client.delete('/delete-tyre/' + payload.id));
    },


    async uploadTyres(payload) {
        return client.parseResponse(await client.upload('/import-tyres', payload, payload.file))
    },

    async exportTyres(filters) {
        return await client.get('/export-tyres', filters, {responseType: 'blob'});
    },
}
