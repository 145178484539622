import companies from "@/data/api/companies.api"
import vehicles from "@/data/api/vehicles.api";
import tyres from "@/data/api/tyres.api";
import tyre_stores from "@/data/api/tyre_stores.api";
import tyre_sizes from "@/data/api/tyre_sizes.api";
import tyre_axels from "@/data/api/tyre_axels.api";
import tyre_brands from "@/data/api/tyre_brands.api";
import tyre_models from "@/data/api/tyre_models.api";
import tyre_specifications from "@/data/api/tyre_specifications.api";
import tyre_tread_patterns from "@/data/api/tyre_tread_patterns.api";
import tyre_vendors from "@/data/api/tyre_vendors.api";
import manufactures from "@/data/api/manufacturers.api";
import towns from "@/data/api/towns.api";
import routes from "@/data/api/routes.api"
import fleets from "@/data/api/fleets.api";
import tyre_defects from "@/data/api/tyre_defects.api";
import company_vehicles from "@/data/api/company_vehicles.api";
import company_routes from "@/data/api/company_routes.api";
import company_commodities from "@/data/api/company_commodities.api";
import auth from "@/data/api/auth.api";
import users from "@/data/api/users.api";
import roles from "@/data/api/roles.api";
import tyre_puncture_repairs from "@/data/api/tyre_puncture_repairs.api";
import modules from "@/data/api/modules.api";
import commodities from "@/data/api/commodities.api";
import vehicle_types from "@/data/api/vehicle_types.api";
import dismount_reasons from "@/data/api/dismount_reasons.api";
import inspections from "@/data/api/inspections.api";
import inspection_question_types from "@/data/api/inspection_question_types.api";
import inspection_questions from "@/data/api/inspection_questions.api";
import vehicle_inspections from "@/data/api/vehicle_inspections.api";
import inspection_logs from "@/data/api/inspection_logs.api";
import defects from "@/data/api/defects.api";
import permissions from "@/data/api/permissions.api";
import company_branches from "@/data/api/company_branches.api"
import tyre_logs from "@/data/api/tyre_logs.api"
import sms_users from "@/data/api/sms_users.api";
import app_settings from "@/data/api/app_settings.api"
import customers from "@/data/api/customers.api"
import journeys from "@/data/api/journeys.api"
import incentives from "@/data/api/incentives.api";
import configs from "@/data/api/configs.api"

export default {
    ...users,
    ...auth,
    ...companies,
    ...vehicles,
    ...tyres,
    ...tyre_stores,
    ...tyre_sizes,
    ...tyre_axels,
    ...tyre_models,
    ...tyre_brands,
    ...tyre_specifications,
    ...tyre_tread_patterns,
    ...tyre_vendors,
    ...towns,
    ...routes,
    ...manufactures,
    ...fleets,
    ...tyre_defects,
    ...company_vehicles,
    ...company_routes,
    ...company_commodities,
    ...roles,
    ...tyre_puncture_repairs,
    ...modules,
    ...commodities,
    ...vehicle_types,
    ...dismount_reasons,
    ...inspections,
    ...inspection_question_types,
    ...inspection_questions,
    ...vehicle_inspections,
    ...inspection_logs,
    ...defects,
    ...permissions,
    ...company_branches,
    ...tyre_logs,
    ...sms_users,
    ...app_settings,
    ...customers,
    ...journeys,
    ...incentives,
    ...configs
}
