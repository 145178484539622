import client from "./client";

export default {
    async getCompanyCommodities(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/company-commodities', filters)));
    },

    async createCompanyCommodities(data) {
        return client.parseResponse(await client.post('/company-commodities', data))
    },

    async getCompanyCommodity(id) {
        return client.parseResponse(await client.get('/company-commodities/' + id));
    },

    async editCompanyCommodity(data) {
        return client.parseResponse(await client.put('/company-commodities/' + data.id, data))
    },

    async deleteCompanyCommodity(payload) {
        return client.parseResponse(await client.delete('/company-commodities/' + payload.id));
    },

    async uploadCompanyCommodities(payload) {
        return client.parseResponse(await client.upload('/import-company-commodities', payload, payload.file))
    },

    async exportCompanyCommodities(filters) {
        return await client.get('/export-company-commodities', filters, {responseType: 'blob'});
    },
}
