import client from "./client";

export default {
    async getTyreLogs(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/tyre-logs', filters)));
    },

    async exportTyreLogs(filters) {
        return await client.get('/export-tyre-logs', filters, {responseType: 'blob'});
    },

    async getAllTyreLogs(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/all-tyre-logs', filters)));
    },

    async exportAllTyreLogs(filters) {
        return await client.get('/export-all-tyre-logs', filters, {responseType: 'blob'});
    },
}
