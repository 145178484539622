<template>
    <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded border bg-white">

        <div class="rounded-t mb-0 px-4 py-5 border-b">
            <div class="flex flex-wrap items-center">
                <div class="md:flex-row flex-col relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-lg">
                        App Settings
                    </h3>
                    <div class="flex flex-row justify-end">
                        <button class="bg-secondary text-white px-10 py-2 font-semibold rounded-md"
                                @click="addAppSettingDialogVisible = true">Add App Setting
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!--    Filters and Search-->
        <div
            class="flex lg:flex-row flex-col justify-between md:items-start md:space-y-0 space-y-2 md:space-x-4 space-x-0 py-8 px-6 border-b">
            <div class="flex flex-row justify-start items-end mb-3 space-x-4">
                <!--        Export and Import-->
            </div>

            <form>
                <div class="relative">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" class="w-5 h-5 mr-3">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                        </svg>
                    </div>
                    <input v-model="search"
                           class="block outline-none w-full px-12 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-secondary focus:border-secondary"
                           placeholder="Search App Settings ...">
                </div>
            </form>
        </div>

        <div class="block w-full overflow-x-auto">

            <!-- Add/Edit dialog-->
            <el-dialog
                v-model="addAppSettingDialogVisible"
                title="Add/Edit AppSettings"
                :width="windowWidth > 768 ? '30%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <form class="px-4">

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Label<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.label" type="text"
                                        name="form.label" required/>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Value<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.value" type="text"
                                        name="form.value" required/>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="addAppSetting">
                            Save
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="addAppSettingDialogVisible = false">Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!--Table-->
            <div class="py-8 px-4">
                <el-table class="" :data="app_settings.data" stripe size="large" style="width: 100%">
                    <el-table-column prop="label" label="LABEL"/>
                    <el-table-column prop="value" label="VALUE"/>
                    <el-table-column label="ACTIONS">
                        <template #default="scope">
                            <el-dropdown size="large">
                                <el-button>
                                    Actions
                                    <el-icon class="el-icon--right">
                                        <arrow-down/>
                                    </el-icon>
                                </el-button>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item @click.native="updateAppSetting(scope.row)">EDIT
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="deleteAppSetting(scope.row)">DELETE
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="flex flex-row p-5 w-full justify-end">
                <el-pagination
                    :current-page="app_settings.current_page"
                    :page-size="app_settings.per_page"
                    :page-sizes="[5,10,20,50]"
                    :small=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="app_settings.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </div>

    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {ArrowDown} from '@element-plus/icons-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
    data() {
        return {
            search: '',
            uploadAppSettingDialogVisible: false,
            addAppSettingDialogVisible: false,
            windowWidth: window.innerWidth,
            form: {},
            app_setting: {},
            filters: {
                paginate: true,
                per_page: 10
            },
        };
    },
    components: {
        ArrowDown

    },
    mounted() {
        this.getAppSettingsList(this.filters)
    },
    computed: {
        ...mapGetters({
            app_settings: 'app_settings/app_settings',
        })
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.getAppSettingsList(this.filters)
            }
        },
        search: function (val) {
            this.getAppSettingsList({
                ...this.filters,
                search: val
            });
        }
    },
    methods: {
        ...mapActions({
            getAppSettingsList: 'app_settings/getAppSettings',
            createAppSettings: 'app_settings/createAppSettings',
            editAppSetting: 'app_settings/editAppSetting',
            removeAppSetting: 'app_settings/deleteAppSetting'
        }),
        handleSizeChange(size) {
            this.filters.per_page = size;
            this.getAppSettingsList({paginate: true, ...this.filters});
        },
        handleCurrentChange(page) {
            this.filters.page = page;
            this.getAppSettingsList({paginate: true, ...this.filters});
        },
        addAppSetting() {
            if (this.form.id) {
                this.editAppSetting(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addAppSettingDialogVisible = false
                    }
                })
            } else {
                this.createAppSettings(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addAppSettingDialogVisible = false
                    }
                });
            }
        },
        updateAppSetting(item) {
            this.addAppSettingDialogVisible = true
            this.form = Object.assign({}, this.form, item);
        },
        deleteAppSetting(item) {
            let conf = confirm(`Are you sure you want to remove this item?`)
            if (conf) {
                this.removeAppSetting({
                    id: item.id
                })
            }
        },

    }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: transparent;
    display: flex;
    align-items: center;
}
</style>
