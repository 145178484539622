import client from "./client";

export default {
    async getConfigs(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/company-configs', filters)));
    },

    async createConfigs(data) {
        return client.parseResponse(await client.post('/company-configs', data))
    },

    async getConfig(id) {
        return client.parseResponse(await client.get('/company-configs/' + id));
    },

    async editConfig(data) {
        return client.parseResponse(await client.put('/company-configs/' + data.id, data))
    },

    async deleteConfig(payload) {
        return client.parseResponse(await client.delete('/company-configs/' + payload.id));
    },
}
