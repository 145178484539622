<template>
    <div class="flex flex-col space-y-10">
        <div class="flex md:flex-row flex-col justify-between bg-white border px-12 py-8">
            <h1 class="text-2xl font-bold">
                {{
                    defect.inspection_question_option ? defect.inspection_question_option.option : defect.inspection_question ? defect.inspection_question.title : defect.inspection.title
                }}</h1>
            <div class="flex flex-col md:flex-row">
                <el-button :disabled="defect.status === 'in progress' || defect.status === 'corrected'"
                           @click="changeStatus('in progress')" type="primary" plain>
                    <span class="mr-2">Mark as In Progress</span>
                    <el-icon>
                        <Loading/>
                    </el-icon>
                </el-button>
                <el-button :disabled="defect.status === 'corrected'" @click="changeStatus('corrected')" type="success"
                           plain>
                    <span class="mr-2">Mark as Corrected</span>
                    <el-icon>
                        <Check/>
                    </el-icon>
                </el-button>
            </div>
        </div>
        <div class="flex flex-col md:flex-row w-full space-x-6">
            <div class="flex flex-col md:w-1/2 w-full bg-white border">
                <div class="flex border-b items-center px-6 py-4">
                    <span class="text-xl">Details</span>
                </div>
                <div class="px-2 md:px-6 py-6 flex flex-col w-full">
                    <table class="w-full text-sm text-left text-gray-500 table-auto">
                        <tbody>
                        <tr class="bg-white border-b">
                            <th scope="row" class="px-6 py-5 font-medium text-gray-900 md:whitespace-nowrap">
                                Status
                            </th>
                            <td class="px-6 py-4">
                                <el-tag effect="dark"
                                        :type="defect.status === 'new' ? 'warning' : defect.status === 'in progress' ? '' : 'success'">
                                    {{ defect.status }}
                                </el-tag>
                            </td>
                        </tr>
                        <tr class="bg-white border-b">
                            <th scope="row" class="px-6 py-5 font-medium text-gray-900 md:whitespace-nowrap">
                                Vehicle Registration Number
                            </th>
                            <td class="flex flex-row px-6 py-4 items-center">
                                <span
                                    class="w-12 h-12 text-sm text-white bg-slate-200 inline-flex items-center justify-center rounded-full mr-2">
                                    <img alt="..." class="w-full rounded-full align-middle border-none shadow-lg"
                                     src="https://cdn-s3-assets.whip-around.com/vehicles/profilepics/no_image_vehicle.png">
                                </span>
                                {{ defect.vehicle ? defect.vehicle.reg_number : '' }}
                            </td>
                        </tr>
                        <tr class="bg-white border-b">
                            <th scope="row" class="px-6 py-5 font-medium text-gray-900 md:whitespace-nowrap">
                                User
                            </th>
                            <td class="flex flex-row px-6 py-4 items-center">
                                <span
                                    class="w-12 h-12 text-sm text-white bg-slate-200 inline-flex items-center justify-center rounded-full mr-2"><img
                                    alt="..." class="w-full rounded-full align-middle border-none shadow-lg"
                                    src="/spa/assets/img/profile.8c421f27.png">
                                </span>
                                {{ defect.user ? defect.user.first_name + ' ' + defect.user.last_name : '' }}
                            </td>
                        </tr>
                        <tr class="bg-white border-b">
                            <th scope="row" class="px-6 py-5 font-medium text-gray-900 md:whitespace-nowrap">
                                Date Created
                            </th>
                            <td class="px-6 py-4">
                                {{ defect.created_at }}
                            </td>
                        </tr>
                        <tr class="bg-white border-b">
                            <th scope="row" class="px-6 py-5 font-medium text-gray-900 md:whitespace-nowrap">
                                Date In Progress
                            </th>
                            <td class="px-6 py-4">
                                {{ defect.date_in_progress ? defect.date_in_progress : '-' }}
                            </td>
                        </tr>
                        <tr class="bg-white border-b">
                            <th scope="row" class="px-6 py-5 font-medium text-gray-900 md:whitespace-nowrap">
                                Date Corrected
                            </th>
                            <td class="px-6 py-4">
                                {{ defect.date_corrected ?  defect.date_corrected : '-'}}
                            </td>
                        </tr>
                        <tr class="bg-white border-b">
                            <th scope="row" class="px-6 py-5 font-medium text-gray-900 md:whitespace-nowrap">
                                Inspection Form
                            </th>
                            <td class="px-6 py-4">
                                {{ defect.inspection ? defect.inspection.title : '' }}
                            </td>
                        </tr>
                        <tr class="bg-white border-b">
                            <th scope="row" class="px-6 py-5 font-medium text-gray-900 md:whitespace-nowrap">
                                Defect Card
                            </th>
                            <td class="px-6 py-4">
                                {{ defect.inspection_question ? defect.inspection_question.title : '' }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="flex flex-col md:w-1/2 w-full md:space-y-10 space-y-2">
                <div class="flex flex-col bg-white border md:mt-0 mt-2">
                    <div class="flex border-b items-center px-6 py-4">
                        <span class="text-xl">Attachments</span>
                    </div>
                    <div class="text-sm font-medium text-center text-gray-500 border-gray-200 py-6 px-6">
                        <ul class="flex flex-wrap -mb-px space-x-6 border-b">
                            <li class="mr-2">
                                <button @click="activeAttachmentTab = 'photos'"
                                        class="inline-block py-4 px-6 border-b-2 rounded-t-lg"
                                        :class="
                                        {'text-blue-600 border-blue-600 transition-colors duration-300 ease-in-out ' : activeAttachmentTab === 'photos',
                                         'hover:text-blue-400 border-transparent' : activeAttachmentTab !== 'photos'
                                        }">
                                    Photos
                                </button>
                            </li>
                            <!--                            <li class="mr-2">-->
                            <!--                                <button @click="activeAttachmentTab = 'documents'"-->
                            <!--                                        class="inline-block py-4 px-6 border-b-2 rounded-t-lg"-->
                            <!--                                        aria-current="page"-->
                            <!--                                        :class="-->
                            <!--                                        {'text-blue-600 border-blue-600 transition-colors duration-300 ease-in-out' : activeAttachmentTab === 'documents',-->
                            <!--                                         'hover:text-blue-400 border-transparent' : activeAttachmentTab !== 'documents'-->
                            <!--                                        }">-->
                            <!--                                    Documents-->
                            <!--                                </button>-->
                            <!--                            </li>-->
                        </ul>
                    </div>
                    <div class="px-5 py-3" v-if="activeAttachmentTab === 'photos'">
                        <div class="flex flex-wrap" v-if="defect.option_fail_images.length">
                            <div v-for="image in defect.option_fail_images" :key="image.id">
                                <el-image style="width: 100px; height: 100px" :src="image.image" fit="fill"/>
                            </div>

                        </div>
                        <div class="flex flex-wrap" v-else-if="defect.fail_images.length">
                            <div v-for="image in defect.fail_images" :key="image.id">
                                <el-image style="width: 100px; height: 100px" :src="image.image" fit="fill"/>
                            </div>
                        </div>
                        <div class="flex items-center justify-center p-8" v-else>
                            <span>
                                No images were added to this defect
                            </span>
                        </div>
                    </div>
                    <!--                    <div v-if="activeAttachmentTab === 'documents'">-->

                    <!--                    </div>-->
                </div>
                <div class="flex flex-col bg-white border">
                    <div class="flex border-b items-center px-6 py-4">
                        <span class="text-xl">Activity</span>
                    </div>
                    <div class="text-sm font-medium text-center text-gray-500 border-gray-200 py-6 px-6">
                        <ul class="flex flex-wrap -mb-px space-x-6 border-b">
                            <li class="mr-2">
                                <button @click="activeActivityTab = 'photos'"
                                        class="inline-block py-4 px-6 border-b-2 rounded-t-lg"
                                        :class="
                                        {'text-blue-600 border-blue-600 transition-colors duration-300 ease-in-out ' : activeActivityTab === 'comments',
                                         'hover:text-blue-400 border-transparent' : activeActivityTab !== 'comments'
                                        }">
                                    Comments
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="px-5 py-3" v-if="activeActivityTab === 'comments'">
                        <div class="flex flex-col" v-if="defect.option_fail_comments.length">
                            <div class="border-b flex flex-row" v-for="comment in defect.option_fail_comments"
                                 :key="comment.id">
                                <div class="flex flex-row space-x-6">
                                    <div>
                                        <span
                                            class="w-12 h-12 text-sm text-white bg-slate-200 inline-flex items-center justify-center rounded-full"><img
                                            alt="..." class="w-full rounded-full align-middle border-none shadow-lg"
                                            src="/spa/assets/img/profile.8c421f27.png">
                                        </span>
                                    </div>
                                    <div class="flex flex-col space-y-2 text-sm">
                                        <span
                                            class="font-bold">{{
                                                defect.user ? defect.user.first_name + ' ' + defect.user.last_name : ''
                                            }}</span>
                                        <span class="pb-3">
                                            {{ comment.created_at }}
                                        </span>
                                        <p>{{ comment.comment }}</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div v-else-if="defect.fail_comments.length">
                            <div v-for="comment in defect.fail_comments" :key="comment.id">
                                <div class="flex flex-row space-x-6">
                                    <div>
                                        <span
                                            class="w-12 h-12 text-sm text-white bg-slate-200 inline-flex items-center justify-center rounded-full"><img
                                            alt="..." class="w-full rounded-full align-middle border-none shadow-lg"
                                            src="/spa/assets/img/profile.8c421f27.png">
                                        </span>
                                    </div>
                                    <div class="flex flex-col space-y-2 text-sm pt-2">
                                        <span
                                            class="font-bold">{{
                                                defect.user ? defect.user.first_name + ' ' + defect.user.last_name : ''
                                            }}</span>
                                        <span class="pb-3">
                                            {{ comment.created_at }}
                                        </span>
                                        <p>{{ comment.comment }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center justify-center p-8" v-else>
                            <span>
                                No comments were added to this defect
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {Check, Loading} from "@element-plus/icons-vue";

export default {
    components: {Check, Loading},
    data() {
        return {
            activeAttachmentTab: 'photos',
            form: {},
            activeActivityTab: 'comments'
        }
    },
    mounted() {
        this.getDefect(this.$route.params.id)
    },
    computed: {
        ...mapGetters({
            defect: 'defects/defect',
        })
    },
    methods: {
        ...mapActions({
            getDefect: 'defects/getDefect',
            updateStatus: 'defects/editDefect'
        }),
        changeStatus(status) {
            this.form.id = this.defect.id
            this.form.status = status
            this.updateStatus(this.form)
        }

    }
}
</script>
