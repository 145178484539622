import api from "../api";
import {ElMessage} from 'element-plus'

export default {
    namespaced: true,
    state: {
        dismount_reasons: {data: [], current_page: 1, total: 0, per_page: 10},
        errors: {
            data: {
                errors: {
                    name: [],
                }
            }
        },
        has_errors: false,
    },
    getters: {
        dismount_reasons: state => state.dismount_reasons,
        errors: state => state.errors,
        has_errors: state => state.has_errors,
    },
    mutations: {
        setDismountReasons(state, data) {
            state.dismount_reasons = data;
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        errors: {
                            name: [],
                        }
                    }
                };
            }
        }
    },
    actions: {
        async getDismountReasons({commit}, filters) {
            try {
                const response = await api.getDismountReasons(filters);
                commit('setDismountReasons', response);
            } catch (e) {
                console.log(e)
            }
        },

        async createDismountReasons({commit, dispatch}, data) {
            try {
                commit('hasErrors', false)
                await api.createDismountReasons(data);
                dispatch('getDismountReasons', {
                    paginate: true
                })
                ElMessage({
                    message: 'DismountReason created successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error creating DismountReason!',
                    type: 'error',
                })
            }
        },
        async editDismountReason({dispatch}, data) {
            try {
                await api.editDismountReason(data);
                dispatch('getDismountReasons', {
                    paginate: true
                });
                ElMessage({
                    message: 'DismountReason updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: 'Error updating DismountReason',
                    type: 'error',
                })
            }
        },
        async deleteDismountReason({dispatch}, payload) {
            try {
                const {message} = await api.deleteDismountReason(payload)
                dispatch('getDismountReasons', {})
                ElMessage({
                    message: message,
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            }
        },
        async uploadDismountReasons(context, payload) {
            return await api.uploadDismountReasons(payload)
        },
        async exportDismountReasons(context, filters) {
            try {
                const response = await api.exportDismountReasons(filters);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data]));
                link.setAttribute('download', 'dismount_reason ' + Date.now() + '.xlsx');
                document.body.appendChild(link);
                link.click();
            } catch (e) {
                ElMessage({
                    message: 'Error exporting!',
                    type: 'error',
                })
            }
        },

    }
}
