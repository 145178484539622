import client from "./client";

export default {
    async getVehicleInspections(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/get-vehicle-inspections', filters)));
    },

    async createVehicleInspections(data) {
        return client.parseResponse(await client.post('/vehicle-inspections', data))
    },

    async getVehicleInspection(id) {
        return client.parseResponse(await client.get('/vehicle-inspections/' + id));
    },

    async editVehicleInspection(data) {
        return client.parseResponse(await client.put('/vehicle-inspections/' + data.id, data))
    },

    async deleteVehicleInspection(payload) {
        return client.parseResponse(await client.delete('/vehicle-inspections/' + payload.id));
    },

}
