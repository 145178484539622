<template>
  <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded border bg-white">

    <div class="rounded-t mb-0 px-4 py-5 border-b">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-lg">
            Vehicles
          </h3>
          <button class="bg-red-700 text-white px-4 py-2 font-semibold rounded-md"
                  @click="addVehicleDialogVisible = true">Add Vehicle
          </button>
        </div>
      </div>
    </div>

    <!--    Filters and Search-->
    <div class="flex flex-col py-5 text-gray-100 border-b px-4">
      <div class="flex flex-row justify-start items-end mb-3 space-x-4">
        <div class="flex flex-col">
          <p class="text-md font-bold text-sm lg:text-md mb-3">Company</p>
          <div class="flex flex-row items-start justify-start w-full">
            <div class="flex flex-row rounded-md w-full">
              <el-select
                  style="width: 100%" multiple v-model="filters.company_id" placeholder="Select company" size="default">
                <el-option
                    v-for="item in companies.data"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <p class="text-md font-bold text-sm lg:text-md mb-3">Model</p>
          <div class="flex flex-row items-start justify-start w-full">
            <div class="flex flex-row rounded-md w-full">
              <el-select
                  style="width: 100%" multiple v-model="filters.model" placeholder="Select model" size="default">
                <el-option
                    v-for="(model, index) in models"
                    :value="model"
                    :key="index"
                    :label="model"
                />
              </el-select>
            </div>
          </div>
        </div>
        <!--        Export and Import-->
        <div class="flex flex-row">
          <el-button-group>
            <el-button @click="exportVehicles" type="success" plain>
              <span class="mr-3">Export</span>
              <el-icon>
                <Download/>
              </el-icon>
            </el-button>
            <el-button @click="uploadVehicleDialogVisible=!uploadVehicleDialogVisible" type="warning" size="default"
                       plain>
              <span class="mr-3">Import</span>
              <el-icon>
                <Upload/>
              </el-icon>
            </el-button>
          </el-button-group>
        </div>
      </div>

      <div class="flex flex-row justify-end">
        <!--      search-->
        <div class="flex flex-row justify-center items-center rounded-md border border-[#e5e7eb] p-1 mr-2">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-5 h-5 mr-3">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
            </svg>
          </div>
          <input v-model="search" placeholder="Search here ..."
                 class="w-full outline-none px-5"/>
        </div>
      </div>
    </div>

    <div class="block w-full overflow-x-auto">

      <!--Import dialog-->
      <el-dialog
          v-model="uploadVehicleDialogVisible"
          title="Upload Vehicles"
          :width="windowWidth > 768 ? '40%' : '90%'">
        <div class="flex flex-col gap-y-2">
          <div class="flex flex-row items-center w-full rounded mb-4">
            <a class="py-2 px-6 text-sky-600"
               href="/templates/vehicles_template.csv" download="">
                <font-awesome-icon class="mr-2 text-sm"
                                   :icon="['fas', 'download']" />
              Please click here to download the vehicles template.
            </a>
          </div>
          <form class="px-4">
            <div class="flex flex-col items-start justify-start mb-3 w-full">
              <p class="text-md font-bold text-sm lg:text-md mb-3">Company</p>
              <div class="flex flex-row items-start justify-start w-full mb-1">
                <div class="flex flex-row rounded-md w-full">
                  <el-select
                      style="width: 100%" v-model="vehicle.company_id" placeholder="Select company" size="default">
                    <el-option
                        v-for="item in companies.data"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                  </el-select>
                </div>
              </div>
            </div>
            <div class="flex flex-col items-start justify-start mb-3 w-full">
              <p class="text-md font-bold text-sm lg:text-md mb-3">File (.xls or .xlsx or .csv)<span
                  class="text-red-500 ml-2">*</span></p>
              <div class="flex flex-row items-start justify-start w-full mb-1">
                <div class="flex flex-row border p-2 rounded-md w-full">
                  <input
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      class="outline-none w-full" @change="companyFile" type="file" required/>
                </div>
              </div>
            </div>
          </form>
          <div class="dialog-footer flex flex-row w-full justify-end px-4">
            <el-button class="bg-sky-600 text-white" @click="upload">
              Upload
            </el-button>
            <el-button class="bg-red-700 text-white" type="danger" @click="uploadVehicleDialogVisible = false">Cancel
            </el-button>
          </div>
        </div>
      </el-dialog>

      <!-- Add/Edit dialog-->
      <el-dialog
          v-model="addVehicleDialogVisible"
          title="Add/Edit Vehicles"
          :width="windowWidth > 768 ? '30%' : '90%'">
        <div class="flex flex-col gap-y-2">
          <form class="px-4">
            <div class="flex flex-col items-start justify-start mb-3 w-full">
              <p class="text-md font-bold text-sm lg:text-md mb-3">Registration Number<span
                  class="text-red-500 ml-2">*</span></p>
              <div class="flex flex-row items-start justify-start w-full mb-1">
                <div class="flex flex-row rounded-md w-full">
                  <input
                      class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                      v-model="form.reg_number" type="text"
                      name="form.name" required/>
                </div>
              </div>
            </div>

            <div class="flex flex-col items-start justify-start mb-3 w-full">
              <p class="text-md font-bold text-sm lg:text-md mb-3">Company</p>
              <div class="flex flex-row items-start justify-start w-full mb-1">
                <div class="flex flex-row rounded-md w-full">
                  <el-select
                      style="width: 100%" v-model="form.company_id" placeholder="Select company" size="large">
                    <el-option
                        v-for="item in companies.data"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                  </el-select>
                </div>
              </div>
            </div>
            <div class="flex flex-col items-start justify-start mb-3 w-full">
              <p class="text-md font-bold text-sm lg:text-md mb-3">Model</p>
              <div class="flex flex-row items-start justify-start w-full mb-1">
                <div class="flex flex-row rounded-md w-full">
                  <input
                      class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                      v-model="form.model" type="text"
                      name="form.name"/>
                </div>
              </div>
            </div>
          </form>
          <div class="dialog-footer flex flex-row w-full justify-end px-4">
            <el-button class="bg-sky-600 text-white" @click="addVehicle">
              Save
            </el-button>
            <el-button class="bg-red-700 text-white" type="danger" @click="addVehicleDialogVisible = false">Cancel
            </el-button>
          </div>
        </div>
      </el-dialog>

      <!--Table-->
      <div class="py-8 px-4">
        <el-table class="" :data="vehicles.data" stripe size="large" style="width: 100%">
          <el-table-column prop="reg_number" label="REGISTRATION NO"/>
          <el-table-column prop="company.name" label="COMPANY"/>
          <el-table-column prop="model" label="MODEL"/>
          <el-table-column prop="created_at" label="CREATED AT"/>
          <el-table-column label="ACTIONS">
            <template #default="scope">
              <el-dropdown size="large">
                <el-button>
                  Actions
                  <el-icon class="el-icon--right">
                    <arrow-down/>
                  </el-icon>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click.native="updateVehicle(scope.row)">EDIT</el-dropdown-item>
                    <el-dropdown-item @click.native="deleteVehicle(scope.row)">DELETE</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex flex-row p-5 w-full justify-end">
        <el-pagination
            :current-page="vehicles.current_page"
            :page-size="vehicles.per_page"
            :page-sizes="[5,10,20,50]"
            :small=false
            layout="total, sizes, prev, pager, next, jumper"
            :total="vehicles.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
      </div>
    </div>

  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {ArrowDown, Upload, Download} from '@element-plus/icons-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  data() {
    return {
      search: '',
      uploadVehicleDialogVisible: false,
      addVehicleDialogVisible: false,
      windowWidth: window.innerWidth,
      form: {},
      vehicle: {},
      filters: {
        paginate: true,
        per_page: 10
      },
    };
  },
  components: {
    ArrowDown, Upload, Download

  },
  mounted() {
    this.getVehiclesList(this.filters)
    this.getCompanies(this.filters)
  },
  computed: {
    models() {
      var model_data = this.vehicles.data
      return model_data?.map(function (item) {
        return item.model;
      }).filter(
          (item, index, arr) => {
            return arr.indexOf(item) === index
          }
      );
    },
    ...mapGetters({
      vehicles: 'vehicles/vehicles',
      companies: 'companies/companies'
    })
  },
  watch: {
    filters: {
      deep: true,
      handler: function () {
        this.getVehiclesList(this.filters)
      }
    },
    search: function (val) {
      this.getVehiclesList({
        ...this.filters,
        search: val
      });
    }
  },
  methods: {
    ...mapActions({
      getVehiclesList: 'vehicles/getVehicles',
      uploadVehicles: 'vehicles/uploadVehicles',
      downloadVehicles: 'vehicles/exportVehicles',
      createVehicles: 'vehicles/createVehicles',
      editVehicle: 'vehicles/editVehicle',
      getCompanies: 'companies/getCompanies'
    }),
    handleSizeChange(size) {
      this.filters.per_page = size;
      this.getVehiclesList({paginate: true, ...this.filters});
    },
    handleCurrentChange(page) {
      this.filters.page = page;
      this.getVehiclesList({paginate: true, ...this.filters});
    },
    addVehicle() {
      if (this.form.id) {
        this.editVehicle(this.form).then(() => {
          if (!this.has_errors) {
            this.form = {}
            this.addVehicleDialogVisible = false
          }
        })
      } else {
        this.createVehicles(this.form).then(() => {
          if (!this.has_errors) {
            this.form = {}
            this.addVehicleDialogVisible = false
          }
        });
      }
    },
    updateVehicle(item) {
      this.addVehicleDialogVisible = true
      this.form = Object.assign({}, this.form, item);
    },
    deleteVehicle() {

    },
    upload() {
      this.vehicle.file = this.file;
      this.uploadVehicles(this.vehicle)
          .then(() => {
            this.getVehiclesList(this.filters)
            this.uploadVehicleDialogVisible = false
            this.$message.success('Vehicles uploaded successfully!')
          }).catch((e) => {
        this.$message.error(e.response.data.message)
      })
    },
    companyFile(e) {
      this.file = e.target.files[0]
    },
    exportVehicles() {
      this.downloadVehicles({...this.filters})
    }

  }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
  cursor: pointer;
  color: transparent;
  display: flex;
  align-items: center;
}
</style>
