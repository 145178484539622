import client from "./client";

export default {
    async getDismountReasons(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/dismount-reasons', filters)));
    },

    async createDismountReasons(data) {
        return client.parseResponse(await client.post('/dismount-reasons', data))
    },

    async getDismountReason(id) {
        return client.parseResponse(await client.get('/dismount-reasons/' + id));
    },

    async editDismountReason(data) {
        return client.parseResponse(await client.put('/dismount-reasons/' + data.id, data))
    },

    async deleteDismountReason(payload) {
        return client.parseResponse(await client.delete('/dismount-reasons/' + payload.id));
    },

    async uploadDismountReasons(payload) {
        return client.parseResponse(await client.upload('/import-dismount-reasons', payload, payload.file))
    },

    async exportDismountReasons(filters) {
        return await client.get('/export-dismount-reasons', filters, {responseType: 'blob'});
    },
}
