import client from "./client";

export default {
    async getCustomers(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/customers', filters)));
    },

    async createCustomers(data) {
        return client.parseResponse(await client.post('/customers', data))
    },

    async getCustomer(id) {
        return client.parseResponse(await client.get('/customers/' + id));
    },

    async editCustomer(data) {
        return client.parseResponse(await client.put('/customers/' + data.id, data))
    },

    async deleteCustomer(payload) {
        return client.parseResponse(await client.delete('/customers/' + payload.id));
    },

    async uploadCustomers(payload) {
        return client.parseResponse(await client.upload('/import-branches', payload, payload.file))
    },

    async exportCustomers(filters) {
        return await client.get('/export-branches', filters, {responseType: 'blob'});
    },
}
