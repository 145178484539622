import api from "../api";
import {ElMessage} from 'element-plus'

export default {
    namespaced: true,
    state: {
        company_commodities: {data: [], current_page: 1, total: 0, per_page: 10},
        errors: {
            data: {
                errors: {
                    name: [],
                }
            }
        },
        has_errors: false,
    },
    getters: {
        company_commodities: state => state.company_commodities,
        errors: state => state.errors,
        has_errors: state => state.has_errors,
    },
    mutations: {
        setCompanyCommodities(state, data) {
            state.company_commodities = data;
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        errors: {
                            name: [],
                        }
                    }
                };
            }
        }
    },
    actions: {
        async getCompanyCommodities({commit}, filters) {
            try {
                const response = await api.getCompanyCommodities(filters);
                commit('setCompanyCommodities', response);
            } catch (e) {
                console.log(e)
            }
        },

        async createCompanyCommodities({commit, dispatch}, data) {
            try {
                commit('hasErrors', false)
                await api.createCompanyCommodities(data);
                dispatch('getCompanyCommodities', {
                    paginate: true,
                    id:data.company_id
                })
                ElMessage({
                    message: 'CompanyCommodity created successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error creating CompanyCommodity!',
                    type: 'error',
                })
            }
        },
        async editCompanyCommodity({dispatch}, data) {
            try {
                await api.editCompanyCommodity(data);
                dispatch('getCompanyCommodities', {
                    paginate: true
                });
                ElMessage({
                    message: 'CompanyCommodity updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: 'Error updating CompanyCommodity',
                    type: 'error',
                })
            }
        },
        async deleteCompanyCommodity({dispatch}, payload) {
            try {
                const {message} = await api.deleteCompanyCommodity(payload)
                dispatch('getCompanyCommodities', {})
                ElMessage({
                    message: message,
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            }
        },
        async uploadCompanyCommodities(context, payload) {
            return await api.uploadCompanyCommodities(payload)
        },
        async exportCompanyCommodities(context, filters) {
            try {
                const response = await api.exportCompanyCommodities(filters);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data]));
                link.setAttribute('download', 'company_commodities ' + Date.now() + '.xlsx');
                document.body.appendChild(link);
                link.click();
            } catch (e) {
                ElMessage({
                    message: 'Error exporting!',
                    type: 'error',
                })
            }
        },

    }
}
