<template>
    <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded border bg-white">

        <div class="rounded-t mb-0 px-4 py-5 border-b">
            <div class="flex flex-wrap items-center">
                <div class="md:flex-row flex-col relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-lg">
                        CompanyCommodities
                    </h3>
                    <div class="flex flex-row justify-end">
                        <button class="bg-secondary text-white px-10 py-2 font-semibold rounded-md"
                                @click="addCompanyCommodityDialogVisible = true">Add CompanyCommodity
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!--    Filters and Search-->
        <div class="flex flex-col py-5 text-gray-100 border-b px-4">
            <div class="flex lg:flex-row flex-col justify-between md:items-end md:space-y-0 space-y-2 md:space-x-4 space-x-0">
                <!--        Export and Import-->
                <div class="flex flex-row">
                    <el-button-group>
                        <el-button @click="exportCompanyCommodities" type="success" plain>
                            <span class="mr-3">Export</span>
                            <el-icon>
                                <Download/>
                            </el-icon>
                        </el-button>
                        <el-button @click="uploadCompanyCommodityDialogVisible=!uploadCompanyCommodityDialogVisible"
                                   type="warning"
                                   size="default"
                                   plain>
                            <span class="mr-3">Import</span>
                            <el-icon>
                                <Upload/>
                            </el-icon>
                        </el-button>
                    </el-button-group>
                </div>
                <!--      search-->
                <form>
                    <div class="relative text-black">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                 stroke="currentColor" class="w-5 h-5 mr-3">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                            </svg>
                        </div>
                        <input v-model="search"
                               class="block outline-none w-full px-12 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-secondary focus:border-secondary"
                               placeholder="Search Commodities ...">
                    </div>
                </form>
            </div>

            <!--            <div class="flex flex-row justify-end">-->
            <!--                &lt;!&ndash;      search&ndash;&gt;-->
            <!--                <div class="flex flex-row justify-center items-center rounded-md border border-[#e5e7eb] p-1 mr-2">-->
            <!--                    <div>-->
            <!--                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"-->
            <!--                             stroke="currentColor" class="w-5 h-5 mr-3">-->
            <!--                            <path stroke-linecap="round" stroke-linejoin="round"-->
            <!--                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>-->
            <!--                        </svg>-->
            <!--                    </div>-->
            <!--                    <input v-model="search" placeholder="Search here ..."-->
            <!--                           class="w-full outline-none px-5"/>-->
            <!--                </div>-->
            <!--            </div>-->
        </div>

        <div class="block w-full overflow-x-auto">

            <!--Import dialog-->
            <el-dialog
                v-model="uploadCompanyCommodityDialogVisible"
                title="Upload CompanyCommodities"
                :width="windowWidth > 768 ? '40%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <div class="flex flex-row items-center w-full rounded mb-4">
                        <a class="py-2 px-6 text-sky-600"
                           href="/templates/company_commodities_template.csv" download="">
                            <font-awesome-icon class="mr-2 text-sm"
                                               :icon="['fas', 'download']"/>
                            Please click here to download the company_commodities template.
                        </a>
                    </div>
                    <form class="px-4">
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">File (.xls or .xlsx or .csv)<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row border p-2 rounded-md w-full">
                                    <input
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        class="outline-none w-full" @change="companyFile" type="file" required/>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="upload">
                            Upload
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="uploadCompanyCommodityDialogVisible = false">
                            Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!-- Add/Edit dialog-->
            <el-dialog
                v-model="addCompanyCommodityDialogVisible"
                title="Add/Edit CompanyCommodities"
                :width="windowWidth > 768 ? '30%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <form class="px-4">
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Commodity</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.commodity_id" placeholder="Select commodity"
                                        size="default">
                                        <el-option
                                            v-for="item in commodities"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Manufacture</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.manufacture_id" placeholder="Select manufacture"
                                        size="default">
                                        <el-option
                                            v-for="item in manufactures"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Status</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.active" placeholder="Select status"
                                        size="default">
                                        <el-option value="1">Active</el-option>
                                        <el-option value="0">Inactive</el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>


                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="addCompanyCommodity">
                            Save
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="addCompanyCommodityDialogVisible = false">
                            Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!--Table-->
            <div class="py-8 px-4">
                <el-table class="" :data="company_commodities.data" stripe size="large" style="width: 100%">
                    <el-table-column prop="company.name" label="COMPANY"/>
                    <el-table-column prop="commodity.name" label="COMMODITY"/>
                    <el-table-column prop="manufacture.name" label="MANUFACTURE"/>
                    <el-table-column label="STATUS">
                        <template #default="scope">
                            {{ scope.row.active === 1 ? 'Active' : 'Inactive' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="created_at" label="CREATED AT"/>
                    <el-table-column label="ACTIONS">
                        <template #default="scope">
                            <el-dropdown size="large">
                                <el-button>
                                    Actions
                                    <el-icon class="el-icon--right">
                                        <arrow-down/>
                                    </el-icon>
                                </el-button>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item @click.native="updateCompanyCommodity(scope.row)">EDIT
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="deleteCompanyCommodity(scope.row)">DELETE
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="flex flex-row p-5 w-full justify-end">
                <el-pagination
                    :current-page="company_commodities.current_page"
                    :page-size="company_commodities.per_page"
                    :page-sizes="[5,10,20,50]"
                    :small=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="company_commodities.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </div>

    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {ArrowDown, Upload, Download} from '@element-plus/icons-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
    data() {
        return {
            search: '',
            uploadCompanyCommodityDialogVisible: false,
            addCompanyCommodityDialogVisible: false,
            windowWidth: window.innerWidth,
            form: {},
            company_commodity: {},
            filters: {
                paginate: true,
                per_page: 10
            },
            user: {}
        };
    },
    components: {
        ArrowDown, Upload, Download

    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.getCompanyCommoditiesList({
            ...this.filters,
            id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
        })
        this.getCommodities({})
        this.getManufactures({
            id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
        })
    },
    computed: {
        ...mapGetters({
            company_commodities: 'company_commodities/company_commodities',
            companies: 'companies/companies',
            commodities: 'commodities/commodities',
            manufactures:'manufactures/manufactures'
        })
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.getCompanyCommoditiesList(this.filters)
            }
        },
        search: function (val) {
            this.getCompanyCommoditiesList({
                ...this.filters,
                search: val
            });
        }
    },
    methods: {
        ...mapActions({
            getCompanyCommoditiesList: 'company_commodities/getCompanyCommodities',
            uploadCompanyCommodities: 'company_commodities/uploadCompanyCommodities',
            downloadCompanyCommodities: 'company_commodities/exportCompanyCommodities',
            createCompanyCommodities: 'company_commodities/createCompanyCommodities',
            editCompanyCommodity: 'company_commodities/editCompanyCommodity',
            removeCompanyCommodity: 'company_commodities/deleteCompanyCommodity',
            getCompanies: 'companies/getCompanies',
            getCommodities: 'commodities/getCommodities',
            getManufactures:'manufactures/getManufactures'
        }),
        handleSizeChange(size) {
            this.filters.per_page = size;
            this.getCompanyCommoditiesList({paginate: true, ...this.filters});
        },
        handleCurrentChange(page) {
            this.filters.page = page;
            this.getCompanyCommoditiesList({paginate: true, ...this.filters});
        },
        addCompanyCommodity() {
            this.form.company_id = this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            if (this.form.id) {
                this.editCompanyCommodity(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addCompanyCommodityDialogVisible = false
                    }
                })
            } else {
                this.createCompanyCommodities(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addCompanyCommodityDialogVisible = false
                    }
                });
            }
        },
        updateCompanyCommodity(item) {
            this.addCompanyCommodityDialogVisible = true
            this.form = Object.assign({}, this.form, item);
        },
        deleteCompanyCommodity(item) {
            let conf = confirm(`Are you sure you want to remove this item?`)
            if (conf) {
                this.removeCompanyCommodity({
                    id: item.id
                })
            }
        },
        upload() {
            this.company_commodity.file = this.file;
            this.company_commodity.company_id = this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id;
            this.uploadCompanyCommodities(this.company_commodity)
                .then(() => {
                    this.getCompanyCommoditiesList({
                        ...this.filters,
                        id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
                    })
                    this.uploadCompanyCommodityDialogVisible = false
                    this.$message.success('CompanyCommodities uploaded successfully!')
                }).catch((e) => {
                this.$message.error(e.response.data.message)
            })
        },
        companyFile(e) {
            this.file = e.target.files[0]
        },
        exportCompanyCommodities() {
            this.downloadCompanyCommodities({
                ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            })
        }

    }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: transparent;
    display: flex;
    align-items: center;
}
</style>
