import api from "../api";
import {ElMessage} from 'element-plus'

export default {
    namespaced: true,
    state: {
        users: {data: [], current_page: 1, total: 0, per_page: 10},
        user_roles: {data: [], current_page: 1, total: 0, per_page: 10},
        user: null,
        authorization_error: null,
        authenticating: false,
        dialogVisible: false,
        permissions: {},
        loading: false,
        errors: {
            data: {
                web_errors: {
                    first_name: [],
                    last_name: [],
                    email: [],
                    phone_number: [],
                    country_code: [],
                    address_one: [],
                    address_two: [],
                    password: [],
                    password_confirmation: [],
                    code:[],
                    input:[]
                }
            }
        },
        has_errors: false,
    },
    getters: {
        loading: state => state.loading,
        loggedIn() {
            return !!window.localStorage.getItem('sat_token');
        },
        dialogVisible: state => state.dialogVisible,
        user: state => state.user === null ? JSON.parse(localStorage.getItem('user')) : state.user,
        errors: state => state.errors,
        has_errors: state => state.has_errors,
        authenticating: state => state.authenticating,
        authorization_error: state => state.authorization_error,
        users: state => state.users,
        user_roles: state => state.user_roles,
        permissions: state => state.permissions,
    },
    mutations: {
        setLoading(state, status) {
            state.loading = status
        },
        setPermissions(state, status) {
            state.permissions = status
        },
        setDialogVisible(state, status) {
            state.dialogVisible = status;
        },
        authenticating(state, val) {
            state.authenticating = val
        },
        setUserRoles(state, data) {
            state.user_roles = data;
        },
        setUsers(state, data) {
            state.users = data;
        },
        setUser(state, user) {
            state.user = user;
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        setAuthorizationError(state, val) {
            state.authorization_error = val
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                // no errors
                state.errors = {
                    data: {
                        web_errors: {
                            first_name: [],
                            last_name: [],
                            email: [],
                            phone_number: [],
                            country_code: [],
                            address_one: [],
                            address_two: [],
                            password: [],
                            password_confirmation: [],
                            code:[],
                            input:[]
                        }
                    }
                };
            }
        }
    },
    actions: {
        async verifyEmail({commit}, data) {
            try {
                commit('hasErrors', false)
                commit('setLoading', true)
                const response = await api.verifyEmail(data)
                localStorage.setItem('id', response.id);
                commit('setLoading', false)
                window.location.href = '/verify-email'
                ElMessage({
                    message: 'A code has been successfully sent to your email!',
                    type: 'success',
                })
            } catch (e) {
                commit('setLoading', false)
                let status = e.response.status
                if (status === 403) {
                    ElMessage({
                        message: e.response.data.message,
                        type: 'error',
                    })
                }
                if (status === 422) {
                    commit('hasErrors', true)
                    commit('setErrors', e.response)
                    ElMessage({
                        message: 'please correct the errors below',
                        type: 'error',
                    })
                }
            }
        },
        async verifyAccount({commit}, data) {
            try {
                commit('hasErrors', false)
                commit('setLoading', true)
                await api.verifyAccount(data)
                commit('setLoading', false)
                window.location.href = '/login'
                ElMessage({
                    message: 'Correct code entered!',
                    type: 'success',
                })
            } catch (e) {
                commit('setLoading', false)
                let status = e.response.status
                if (status === 404) {
                    ElMessage({
                        message: e.response.data.message,
                        type: 'error',
                    })
                }
                if (status === 422) {
                    commit('hasErrors', true)
                    commit('setErrors', e.response)
                    ElMessage({
                        message: 'please correct the errors below',
                        type: 'error',
                    })
                }
            }
        },
        async verifyOtp({commit}, data) {
            try {
                commit('hasErrors', false)
                commit('setLoading', true)
                await api.verifyOtp(data)
                commit('setLoading', false)
                window.location.href = '/reset-password'
                ElMessage({
                    message: 'Correct code entered!',
                    type: 'success',
                })
            } catch (e) {
                commit('setLoading', false)
                let status = e.response.status
                if (status === 404) {
                    ElMessage({
                        message: e.response.data.message,
                        type: 'error',
                    })
                }
                if (status === 422) {
                    commit('hasErrors', true)
                    commit('setErrors', e.response)
                    ElMessage({
                        message: 'please correct the errors below',
                        type: 'error',
                    })
                }
            }
        },
        async resetPassword({commit}, data) {
            try {
                commit('hasErrors', false)
                commit('setLoading', true)
                await api.resetPassword(data)
                commit('setLoading', false)
                ElMessage({
                    message: 'Password reset successfully, please login',
                    type: 'success',
                })
                window.location.href = '/login'
            } catch (e) {
                commit('setLoading', false)
                let status = e.response.status
                if (status === 422) {
                    commit('hasErrors', true)
                    commit('setErrors', e.response)
                    ElMessage({
                        message: 'please correct the errors below',
                        type: 'error',
                    })
                } else {
                    ElMessage({
                        message: e.response.data.message,
                        type: 'error',
                    })
                }

            }
        },
        async resendOTP(context, id) {
            try {
                await api.resendOtp(id);
                ElMessage({
                    message: 'Verification code resent successfully',
                    type: 'success',
                    duration: 10000
                })
            } catch (e) {
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                })
            }
        },
        async authenticate({commit}, credentials) {
            try {
                commit('authenticating', true)
                commit('hasErrors', false)
                const response = await api.login(credentials);
                await localStorage.setItem('userToken', response.userToken);
                await localStorage.setItem('user', JSON.stringify(response.user))
                await localStorage.setItem('permissions', JSON.stringify(response.permissions))
                commit('authenticating', false)
                commit('setUser', response.user);
                commit('setPermissions', response.permissions);
                window.location.href = '/admin/dashboard'
            } catch (e) {
                commit('authenticating', false)
                let status = e.response.status
                if (status === 401) {
                    commit('setAuthorizationError', e.response.data.message)
                }
                if (status === 422) {
                    commit('hasErrors', true)
                    commit('setErrors', e.response)
                    commit('setAuthorizationError', 'Please correct the errors below')
                }
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                })

            }
        },

        async logout(context, filters = {}) {
            await api.logout(filters);
            localStorage.removeItem('userToken');
            localStorage.removeItem('user');
            localStorage.removeItem('role');
            localStorage.removeItem('permissions');
            window.location.href = '/login';
        },
        async getUsers({commit}, filters) {
            try {
                commit('activateLoading', 'user/list', {root: true});
                const response = await api.getUsers(filters);
                commit('setUsers', response);
                commit('deactivateLoading', 'user/list', {root: true});
            } catch (e) {
                commit('deactivateLoading', 'user/list', {root: true});
                this._vm.$message.error(e.response.data.message);
            }
        },
        async registerUser({commit}, data) {
            try {
                commit('hasErrors', false)
                const response = await api.registerUser(data);
                await localStorage.setItem('id', response.user.id);
                ElMessage({
                    message: 'User created successfully! Please verify your account',
                    type: 'success',
                    duration: 10000
                })
                window.location.href = '/verify-account'
            } catch (e) {
                commit('authenticating', false)
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Please correct the errors below!',
                    type: 'error',
                })
            }
        },
        async socialLogin({commit}, data) {
            try {
                commit('authenticating', true)
                commit('hasErrors', false)
                const response = await api.socialLogin(data);
                await localStorage.setItem('userToken', response.userToken);
                await localStorage.setItem('user', JSON.stringify(response.user))
                commit('authenticating', false)
                commit('setUser', response.user);
                window.location.href = '/admin/dashboard'
            } catch (e) {
                commit('authenticating', false)
                let status = e.response.status
                if (status === 401) {
                    commit('setAuthorizationError', e.response.data.message)
                }
                if (status === 422) {
                    commit('hasErrors', true)
                    commit('setErrors', e.response)
                    commit('setAuthorizationError', 'Please correct the errors below')
                }
            }
        },

        async createUser({dispatch, commit}, data) {
            try {
                commit('hasErrors', false)
                await api.createUser(data);
                commit('setDialogVisible', false)
                this._vm.$message.success('User created successfully!');
                dispatch('getUsers', {
                    paginate: true
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                this._vm.$message.error(e.response.data.message);
            }
        },
        async editUser({dispatch, commit}, data) {
            try {
                commit('hasErrors', false)
                await api.editUser(data)
                commit('setDialogVisible', false)
                dispatch('getUsers', {
                    paginate: true
                })
                this._vm.$message.success({
                    message: 'User updated successfully!',
                    duration: 10000
                });


            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                this._vm.$message.error({
                    message: e.response.data.message,
                    duration: 10000
                });
            }
        },

        openDialogVisible({commit}, status) {
            commit('setDialogVisible', status);
        },


    }
}
