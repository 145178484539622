<template>
    <div class="flex flex-col mx-10 space-y-8">

        <div @click="back"
             class="flex flex-row space-x-6 cursor-pointer border border-transparent hover:border-secondary rounded max-w-fit p-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"/>
            </svg>
            <span>Back to Journeys Page</span>
        </div>

        <div class="flex flex-row justify-between">
            <span class="font-semibold text-xl py-2">
                Journey S/NO :  {{ journey.serial_number }}
                - <span
                :class="{'text-blue-500': journey.status === 'Closed', 'text-orange-300' : journey.status === 'Open'}"
                class="ml-2 uppercase">{{ journey.status }}</span>
            </span>
            <el-button
                :disabled="journey_details.data.some(obj => obj.status === 'Open') || journey.status === 'Closed'"
                @click="closeTrip()"
                type="success"
                size="large"
                plain>
                <span class="mr-2">Mark Journey {{ journey.serial_number }} as Closed</span>
                <el-icon>
                    <Check/>
                </el-icon>
            </el-button>
        </div>

        <div class="flex flex-row justify-between">
            <el-button
                :disabled="journey_details.data.some(obj => obj.status === 'Open') || journey.status === 'Closed'"
                size="large" @click="show_form = true"
                class="bg-secondary text-white space-x-2 border border-secondary hover:bg-primary-600 hover:text-white">
                <el-icon>
                    <Plus/>
                </el-icon>
                <span class="mr-2">
                    Add new Sector
                </span>
            </el-button>
            <el-button v-if="journey_details.data.length" size="large" @click="downloadPdf"
                       class="bg-secondary text-white space-x-2 border border-secondary hover:bg-primary-600 hover:text-white">
                <el-icon>
                    <Download/>
                </el-icon>
                <span class="mr-2">
                    Download Journey Report
                </span>
            </el-button>
        </div>

        <div v-if="show_form" class="flex flex-col border rounded shadow p-6 bg-white">
            <div class="space-y-2">
                <div class="flex flex-col items-start justify-start w-full border px-10 bg-white rounded-md py-10">
                    <p class="text-md font-bold text-sm lg:text-md mb-3">Checkpoint Type</p>
                    <div class="flex flex-row items-start justify-start w-full mb-1">
                        <div class="flex flex-row rounded-md w-full">
                            <el-select
                                style="width: 100%" v-model="form.checkpoint_type"
                                placeholder="Select checkpoint type" size="large">
                                <el-option value="Forward">Forward</el-option>
                                <el-option value="Return">Return</el-option>
                                <el-option value="Customer">Customer</el-option>
                            </el-select>
                        </div>
                    </div>
                    <span class="text-red-500" v-if="errors.data.web_errors.checkpoint_type && this.has_errors">
                                    {{ errors.data.web_errors.checkpoint_type[0] }}
                                </span>
                </div>
                <div class="flex flex-col border bg-white rounded-md">
                    <div class="flex flex-row w-full border-b justify-center py-2">
                   <span class="font-semibold text-xl py-2">
                       Sectors
                   </span>
                    </div>
                    <div class="flex px-10 flex-col py-10">
                        <div v-if="form.checkpoint_type === 'Forward'" class="grid grid-row-3">
                            <div class="flex flex-row space-x-6">
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Customer<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-select
                                                style="width: 100%" v-model="form.customer_id"
                                                placeholder="Select a customer"
                                                filterable
                                                size="large">
                                                <el-option
                                                    v-for="item in customers"
                                                    :key="item.id"
                                                    :label="item.name"
                                                    :value="item.id"
                                                />
                                            </el-select>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.customer_id && this.has_errors">
                                    {{ errors.data.web_errors.customer_id[0] }}
                                </span>
                                </div>
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Location Of Loading<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-select
                                                style="width: 100%" v-model="form.location_of_loading_id"
                                                placeholder="Select a location"
                                                filterable
                                                size="large">
                                                <el-option
                                                    v-for="item in company_towns"
                                                    :key="item.town_id"
                                                    :label="item.town ? item.town.name : item.town_id"
                                                    :value="item.town_id"
                                                />
                                            </el-select>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.location_of_loading_id && this.has_errors">
                                    {{ errors.data.web_errors.location_of_loading_id[0] }}
                                </span>
                                </div>
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Loading Date<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-date-picker
                                                size="large"
                                                style="width: 100%"
                                                v-model="form.loading_date"
                                                type="date"
                                                placeholder="Pick the date">
                                            </el-date-picker>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.loading_date && this.has_errors">
                                    {{ errors.data.web_errors.loading_date[0] }}
                                </span>
                                </div>
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Loading Time<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-time-picker
                                                format="HH:mm"
                                                arrow-control
                                                style="width: 100%"
                                                v-model="form.loading_time"
                                                placeholder="Pick the time"
                                                size="large"
                                            />
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.loading_time && this.has_errors">
                                    {{ errors.data.web_errors.loading_time[0] }}
                                </span>
                                </div>
                            </div>
                            <div class="flex flex-row space-x-6">
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Delivery Number<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <input
                                                v-model="form.delivery_number"
                                                class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:ring-secondary focus:border-secondary w-full"
                                                type="text"
                                                placeholder="Delivery no"
                                                name="form.name" required/>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.delivery_number && this.has_errors">
                                    {{ errors.data.web_errors.delivery_number[0] }}
                                </span>
                                </div>
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Departure Date<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-date-picker
                                                size="large"
                                                style="width: 100%"
                                                v-model="form.departure_date"
                                                type="date"
                                                placeholder="Departure date">
                                            </el-date-picker>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.departure_date && this.has_errors">
                                    {{ errors.data.web_errors.departure_date[0] }}
                                </span>
                                </div>
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Departure Time<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-time-picker
                                                style="width: 100%"
                                                size="large"
                                                format="HH:mm"
                                                v-model="form.departure_time"
                                                :picker-options="{ selectableRange: '18:30:00 - 20:30:00' }"
                                                placeholder="Departure time">
                                            </el-time-picker>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.departure_time && this.has_errors">
                                    {{ errors.data.web_errors.departure_time[0] }}
                                </span>
                                </div>
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Destination<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-select
                                                filterable
                                                style="width: 100%" v-model="form.destination_id"
                                                placeholder="Select a destination"
                                                size="large">
                                                <el-option
                                                    v-for="item in company_towns"
                                                    :key="item.town_id"
                                                    :label="item.town ? item.town.name : item.town_id"
                                                    :value="item.town_id"
                                                />
                                            </el-select>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.destination_id && this.has_errors">
                                    {{ errors.data.web_errors.destination_id[0] }}
                                </span>
                                </div>
                            </div>

                            <div class="flex flex-row space-x-6">
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Product<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-select
                                                filterable
                                                style="width: 100%" v-model="form.product_id"
                                                placeholder="Select a product"
                                                size="large">
                                                <el-option
                                                    v-for="item in commodities"
                                                    :key="item.commodity_id"
                                                    :label="item.commodity ? item.commodity.name : item.id"
                                                    :value="item.commodity_id"
                                                />
                                            </el-select>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.product_id && this.has_errors">
                                    {{ errors.data.web_errors.product_id[0] }}
                                </span>
                                </div>
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Expected Date of Arrival<span
                                            class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-date-picker
                                                size="large"
                                                style="width: 100%"
                                                v-model="form.date_of_delivery"
                                                type="date"
                                                placeholder="Pick the date">
                                            </el-date-picker>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.date_of_delivery && this.has_errors">
                                    {{ errors.data.web_errors.date_of_delivery[0] }}
                                </span>
                                </div>
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Expected Time of Arrival<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-time-picker
                                                format="HH:mm"
                                                arrow-control
                                                style="width: 100%"
                                                v-model="form.expected_arrival_date"
                                                placeholder="Pick the time"
                                                size="large"
                                            />
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.expected_arrival_date && this.has_errors">
                                    {{ errors.data.web_errors.expected_arrival_date[0] }}
                                </span>
                                </div>
                            </div>
                        </div>
                        <div v-if="form.checkpoint_type === 'Return'" class="grid grid-row-3">
                            <div class="flex flex-row space-x-6">
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Truck Status<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-select
                                                style="width: 100%" v-model="form.truck_status"
                                                placeholder="Select a location"
                                                size="large">
                                                <el-option value="loaded">Loaded</el-option>
                                                <el-option value="empty">Empty</el-option>
                                            </el-select>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.truck_status && this.has_errors">
                                    {{ errors.data.web_errors.truck_status[0] }}
                                </span>
                                </div>
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Customer<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-select
                                                style="width: 100%" v-model="form.customer_id"
                                                placeholder="Select a location"
                                                filterable
                                                size="large">
                                                <el-option
                                                    v-for="item in customers"
                                                    :key="item.id"
                                                    :label="item.name"
                                                    :value="item.id"
                                                />
                                            </el-select>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.customer_id && this.has_errors">
                                    {{ errors.data.web_errors.customer_id[0] }}
                                </span>
                                </div>
                            </div>
                            <div v-if="form.truck_status === 'loaded' || !form.truck_status"
                                 class="flex flex-row space-x-6">
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Location Of Loading<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-select
                                                filterable
                                                style="width: 100%" v-model="form.location_of_loading_id"
                                                placeholder="Select a location"
                                                size="large">
                                                <el-option
                                                    v-for="item in company_towns"
                                                    :key="item.town_id"
                                                    :label="item.town ? item.town.name : item.town_id"
                                                    :value="item.town_id"
                                                />
                                            </el-select>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.location_of_loading_id && this.has_errors">
                                    {{ errors.data.web_errors.location_of_loading_id[0] }}
                                </span>
                                </div>
                                <div v-if="form.truck_status === 'loaded' || !form.truck_status"
                                     class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Loading Date<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-date-picker
                                                size="large"
                                                style="width: 100%"
                                                v-model="form.loading_date"
                                                type="date"
                                                placeholder="Pick the date">
                                            </el-date-picker>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.loading_date && this.has_errors">
                                    {{ errors.data.web_errors.loading_date[0] }}
                                </span>
                                </div>
                                <div v-if="form.truck_status === 'loaded' || !form.truck_status"
                                     class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Loading Time<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-time-picker
                                                format="HH:mm"
                                                arrow-control
                                                style="width: 100%"
                                                v-model="form.loading_time"
                                                placeholder="Pick the time"
                                                size="large"
                                            />
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.loading_time && this.has_errors">
                                    {{ errors.data.web_errors.loading_time[0] }}
                                </span>
                                </div>
                                <div v-if="form.truck_status === 'loaded' || !form.truck_status"
                                     class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Delivery Number<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <input
                                                v-model="form.delivery_number"
                                                class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:ring-secondary focus:border-secondary w-full"
                                                type="text"
                                                placeholder="Delivery no"
                                                name="form.name" required/>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.delivery_number && this.has_errors">
                                    {{ errors.data.web_errors.delivery_number[0] }}
                                </span>
                                </div>
                            </div>
                            <div class="flex flex-row space-x-6">
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Departure Date<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-date-picker
                                                size="large"
                                                style="width: 100%"
                                                v-model="form.departure_date"
                                                type="date"
                                                placeholder="Departure date">
                                            </el-date-picker>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.departure_date && this.has_errors">
                                    {{ errors.data.web_errors.departure_date[0] }}
                                </span>
                                </div>
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Departure Time<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-time-picker
                                                style="width: 100%"
                                                size="large"
                                                format="HH:mm"
                                                v-model="form.departure_time"
                                                :picker-options="{ selectableRange: '18:30:00 - 20:30:00' }"
                                                placeholder="Departure time">
                                            </el-time-picker>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.departure_time && this.has_errors">
                                    {{ errors.data.web_errors.departure_time[0] }}
                                </span>
                                </div>
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Destination<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-select
                                                filterable
                                                style="width: 100%" v-model="form.destination_id"
                                                placeholder="Select a destination"
                                                size="large">
                                                <el-option
                                                    v-for="item in company_towns"
                                                    :key="item.town_id"
                                                    :label="item.town ? item.town.name : item.town_id"
                                                    :value="item.town_id"
                                                />
                                            </el-select>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.destination_id && this.has_errors">
                                    {{ errors.data.web_errors.destination_id[0] }}
                                </span>
                                </div>
                            </div>
                            <div class="flex flex-row space-x-6">
                                <div v-if="form.truck_status === 'loaded' || !form.truck_status"
                                     class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Product<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-select
                                                filterable
                                                style="width: 100%" v-model="form.product_id"
                                                placeholder="Select a product"
                                                size="large">
                                                <el-option
                                                    v-for="item in commodities"
                                                    :key="item.commodity_id"
                                                    :label="item.commodity ? item.commodity.name : item.id"
                                                    :value="item.commodity_id"
                                                />
                                            </el-select>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.product_id && this.has_errors">
                                    {{ errors.data.web_errors.product_id[0] }}
                                </span>
                                </div>
                                <div
                                     class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Expected Date of Arrival<span
                                            class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-date-picker
                                                size="large"
                                                style="width: 100%"
                                                v-model="form.date_of_delivery"
                                                type="date"
                                                placeholder="Pick the date">
                                            </el-date-picker>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.date_of_delivery && this.has_errors">
                                    {{ errors.data.web_errors.date_of_delivery[0] }}
                                </span>
                                </div>
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Expected Time of Arrival<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-time-picker
                                                format="HH:mm"
                                                arrow-control
                                                style="width: 100%"
                                                v-model="form.expected_arrival_date"
                                                placeholder="Pick the time"
                                                size="large"
                                            />
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.expected_arrival_date && this.has_errors">
                                    {{ errors.data.web_errors.expected_arrival_date[0] }}
                                </span>
                                </div>
                            </div>
                        </div>
                        <div v-if="form.checkpoint_type === 'Customer'" class="grid grid-row-3">
                            <div class="flex flex-row space-x-6">
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Port Of Discharge<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-select
                                                style="width: 100%" v-model="form.port_of_discharge_id"
                                                placeholder="Select a location"
                                                filterable
                                                size="large">
                                                <el-option
                                                    v-for="item in company_towns"
                                                    :key="item.town_id"
                                                    :label="item.town ? item.town.name : item.town_id"
                                                    :value="item.town_id"
                                                />
                                            </el-select>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.port_of_discharge_id && this.has_errors">
                                    {{ errors.data.web_errors.port_of_discharge_id[0] }}
                                </span>
                                </div>
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Date<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-date-picker
                                                size="large"
                                                style="width: 100%"
                                                v-model="form.date"
                                                type="date"
                                                placeholder="Date">
                                            </el-date-picker>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.date && this.has_errors">
                                    {{ errors.data.web_errors.date[0] }}
                                </span>
                                </div>

                            </div>
                            <div class="flex flex-row space-x-6">
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Departure Date<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-date-picker
                                                size="large"
                                                style="width: 100%"
                                                v-model="form.departure_date"
                                                type="date"
                                                placeholder="Departure date">
                                            </el-date-picker>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.departure_date && this.has_errors">
                                    {{ errors.data.web_errors.departure_date[0] }}
                                </span>
                                </div>
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Departure Time<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-time-picker
                                                style="width: 100%"
                                                size="large"
                                                format="HH:mm"
                                                v-model="form.departure_time"
                                                :picker-options="{ selectableRange: '18:30:00 - 20:30:00' }"
                                                placeholder="Departure time">
                                            </el-time-picker>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.departure_time && this.has_errors">
                                    {{ errors.data.web_errors.departure_time[0] }}
                                </span>
                                </div>
                            </div>
                            <div class="flex flex-row space-x-6">
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Arrival Date<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-date-picker
                                                size="large"
                                                style="width: 100%"
                                                v-model="form.arrival_date"
                                                type="date"
                                                placeholder="Departure date">
                                            </el-date-picker>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.arrival_date && this.has_errors">
                                    {{ errors.data.web_errors.arrival_date[0] }}
                                </span>
                                </div>
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Arrival Time<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <el-time-picker
                                                style="width: 100%"
                                                size="large"
                                                format="HH:mm"
                                                v-model="form.arrival_time"
                                                :picker-options="{ selectableRange: '18:30:00 - 20:30:00' }"
                                                placeholder="Departure time">
                                            </el-time-picker>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.arrival_time && this.has_errors">
                                    {{ errors.data.web_errors.arrival_time[0] }}
                                </span>
                                </div>
                            </div>
                            <div class="flex flex-row space-x-6">
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Issue one</p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <input
                                                v-model="form.issue_one"
                                                class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:ring-secondary focus:border-secondary w-full"
                                                type="text"
                                                placeholder="Issue one"
                                                name="form.name"/>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.issue_one && this.has_errors">
                                    {{ errors.data.web_errors.issue_one[0] }}
                                </span>
                                </div>
                                <div class="flex flex-col items-center justify-start w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Trucking Status Compliance<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-col">
                                        <div>
                                            <span class="mr-2">Compliant?</span>
                                            <el-radio v-model="form.trucking_status_compliance" :label="1">Yes
                                            </el-radio>
                                            <el-radio v-model="form.trucking_status_compliance" :label="0">No</el-radio>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-center justify-start w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Alcoblow Test Compliance<span
                                        class="text-red-500 ml-2">*</span></p>
                                    <div class="flex flex-col">
                                        <div>
                                            <span class="mr-2">Compliant?</span>
                                            <el-radio v-model="form.alcoblow_test_compliance" :label="1">Yes</el-radio>
                                            <el-radio v-model="form.alcoblow_test_compliance" :label="0">No</el-radio>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-row space-x-6">
                                <div class="flex flex-col items-start justify-start mb-3 w-full">
                                    <p class="text-md font-bold text-sm lg:text-md mb-3">Comments</p>
                                    <div class="flex flex-row items-start justify-start w-full mb-1">
                                        <div class="flex flex-row rounded-md w-full">
                                            <textarea
                                                v-model="form.comments"
                                                class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:ring-secondary focus:border-secondary w-full"
                                                type="text"
                                                name="form.name">
                                            </textarea>
                                        </div>
                                    </div>
                                    <span class="text-red-500" v-if="errors.data.web_errors.comments && this.has_errors">
                                    {{ errors.data.web_errors.comments[0] }}
                                </span>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-row justify-end items-end">
                            <el-button @click="saveJourneyDetails"
                                       class="bg-secondary text-white px-6 py-5 hover:bg-primary-800 hover:text-white">
                                {{ form.id ? ' Update Journey Details' : ' Save Journey Details' }}
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col space-y-6 border rounded shadow p-6 bg-white">
            <div class="flex flex-row justify-center border-b">
                     <span class="font-semibold text-xl py-2 text-center">
                         Journey Details
                     </span>
            </div>
            <div v-if="!journey_details.data.length" class="flex flex-col justify-center items-center">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" class="h-32 w-32"
                         viewBox="0 0 647.63626 632.17383" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <path
                            d="M687.3279,276.08691H512.81813a15.01828,15.01828,0,0,0-15,15v387.85l-2,.61005-42.81006,13.11a8.00676,8.00676,0,0,1-9.98974-5.31L315.678,271.39691a8.00313,8.00313,0,0,1,5.31006-9.99l65.97022-20.2,191.25-58.54,65.96972-20.2a7.98927,7.98927,0,0,1,9.99024,5.3l32.5498,106.32Z"
                            transform="translate(-276.18187 -133.91309)" fill="#f2f2f2"/>
                        <path
                            d="M725.408,274.08691l-39.23-128.14a16.99368,16.99368,0,0,0-21.23-11.28l-92.75,28.39L380.95827,221.60693l-92.75,28.4a17.0152,17.0152,0,0,0-11.28028,21.23l134.08008,437.93a17.02661,17.02661,0,0,0,16.26026,12.03,16.78926,16.78926,0,0,0,4.96972-.75l63.58008-19.46,2-.62v-2.09l-2,.61-64.16992,19.65a15.01489,15.01489,0,0,1-18.73-9.95l-134.06983-437.94a14.97935,14.97935,0,0,1,9.94971-18.73l92.75-28.4,191.24024-58.54,92.75-28.4a15.15551,15.15551,0,0,1,4.40966-.66,15.01461,15.01461,0,0,1,14.32032,10.61l39.0498,127.56.62012,2h2.08008Z"
                            transform="translate(-276.18187 -133.91309)" fill="#3f3d56"/>
                        <path
                            d="M398.86279,261.73389a9.0157,9.0157,0,0,1-8.61133-6.3667l-12.88037-42.07178a8.99884,8.99884,0,0,1,5.9712-11.24023l175.939-53.86377a9.00867,9.00867,0,0,1,11.24072,5.9707l12.88037,42.07227a9.01029,9.01029,0,0,1-5.9707,11.24072L401.49219,261.33887A8.976,8.976,0,0,1,398.86279,261.73389Z"
                            transform="translate(-276.18187 -133.91309)" fill="#a8e063"/>
                        <circle cx="190.15351" cy="24.95465" r="20" fill="#a8e063"/>
                        <circle cx="190.15351" cy="24.95465" r="12.66462" fill="#fff"/>
                        <path
                            d="M878.81836,716.08691h-338a8.50981,8.50981,0,0,1-8.5-8.5v-405a8.50951,8.50951,0,0,1,8.5-8.5h338a8.50982,8.50982,0,0,1,8.5,8.5v405A8.51013,8.51013,0,0,1,878.81836,716.08691Z"
                            transform="translate(-276.18187 -133.91309)" fill="#e6e6e6"/>
                        <path
                            d="M723.31813,274.08691h-210.5a17.02411,17.02411,0,0,0-17,17v407.8l2-.61v-407.19a15.01828,15.01828,0,0,1,15-15H723.93825Zm183.5,0h-394a17.02411,17.02411,0,0,0-17,17v458a17.0241,17.0241,0,0,0,17,17h394a17.0241,17.0241,0,0,0,17-17v-458A17.02411,17.02411,0,0,0,906.81813,274.08691Zm15,475a15.01828,15.01828,0,0,1-15,15h-394a15.01828,15.01828,0,0,1-15-15v-458a15.01828,15.01828,0,0,1,15-15h394a15.01828,15.01828,0,0,1,15,15Z"
                            transform="translate(-276.18187 -133.91309)" fill="#3f3d56"/>
                        <path
                            d="M801.81836,318.08691h-184a9.01015,9.01015,0,0,1-9-9v-44a9.01016,9.01016,0,0,1,9-9h184a9.01016,9.01016,0,0,1,9,9v44A9.01015,9.01015,0,0,1,801.81836,318.08691Z"
                            transform="translate(-276.18187 -133.91309)" fill="#a8e063"/>
                        <circle cx="433.63626" cy="105.17383" r="20" fill="#a8e063"/>
                        <circle cx="433.63626" cy="105.17383" r="12.18187" fill="#fff"/>
                    </svg>
                </div>
                <span>
                        No journeys added
                    </span>
            </div>
            <div v-else>
                <div class="block w-full overflow-x-auto">
                    <!--Table-->
                    <div>
                        <el-table :data="journey_details.data" stripe size="default" style="width: 100%">
                            <el-table-column prop="checkpoint_type" label="CHECKPOINT TYPE"/>
                            <el-table-column prop="delivery_number" label="DELIVERY NO."/>
                            <!--                                <el-table-column prop="date_of_delivery" label="DELIVERY DATE"/>-->
                            <el-table-column label="DEPARTURE DATE & TIME">
                                <template #default="scope">
                                    {{ scope.row.departure_date + "  " + scope.row.departure_time }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="expected_arrival_date" label="EXPECTED ARRIVAL TIME">
                            </el-table-column>
                            <el-table-column prop="product.name" label="PRODUCT"/>
                            <el-table-column prop="destination.name" label="DESTINATION"/>
                            <el-table-column prop="customer.name" label="CUSTOMER"/>
                            <el-table-column label="STATUS">
                                <template #default="scope">
                                    <el-tag effect="dark"
                                            :type="scope.row.status === 'Open' ? 'warning' : scope.row.status === 'Closed' ? '' : 'success'">
                                        {{ scope.row.status }}
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="ACTIONS">
                                <template #default="scope">
                                    <el-dropdown size="large">
                                        <el-button>
                                            Actions
                                            <el-icon class="el-icon--right">
                                                <arrow-down/>
                                            </el-icon>
                                        </el-button>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item @click.native="updateJourneyDetail(scope.row)">EDIT
                                                </el-dropdown-item>
                                                <el-dropdown-item v-if="scope.row.checkpoint_type !== 'Customer'"
                                                                  @click.native="viewCheckpoints(scope.row)">CHECKPOINTS
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="flex flex-row p-5 w-full justify-end">
                        <el-pagination
                            :current-page="journey_details.current_page"
                            :page-size="journey_details.per_page"
                            :page-sizes="[5,10,20,50]"
                            :small=false
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="journey_details.total"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                        />
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
            v-model="closeJourneyDialogVisible"
            title="Close Journey"
            :width="windowWidth > 768 ? '30%' : '90%'">
            <div class="flex flex-col gap-y-2">
                <form class="px-4">
                    <div class="flex flex-col items-start justify-start mb-3 w-full">
                        <p class="text-md font-bold text-sm lg:text-md mb-3">Summary<span
                            class="text-red-500 ml-2">*</span></p>
                        <div class="flex flex-row items-start justify-start w-full mb-1">
                            <div class="flex flex-row rounded-md w-full">
                                <textarea
                                    class="py-2 text-slate-600 relative bg-white rounded text-sm border border-secondary outline-none focus:ring-secondary focus:outline-none focus:border-secondary w-full"
                                    v-model="close_form.summary"
                                    name="form.name" required
                                >
                                </textarea>
                            </div>
                        </div>
                        <span class="text-red-500" v-if="errors.data.web_errors.summary  && this.has_errors">
                                    {{ errors.data.web_errors.summary[0] }}
                                </span>
                    </div>
                    <div class="flex flex-col items-start justify-start mb-3 w-full">
                        <p class="text-md font-bold text-sm lg:text-md mb-3">Compliant Status<span
                            class="text-red-500 ml-2">*</span></p>
                        <div class="flex flex-col">
                            <span class="mr-2">Is Compliant?</span>
                            <div>
                                <el-radio v-model="close_form.is_compliant" :label="1">Yes
                                </el-radio>
                                <el-radio v-model="close_form.is_compliant" :label="0">No</el-radio>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col items-start justify-start mb-3 w-full">
                        <p class="text-md font-bold text-sm lg:text-md mb-3">Comments<span
                            class="text-red-500 ml-2">*</span></p>
                        <div class="flex flex-row items-start justify-start w-full mb-1">
                            <div class="flex flex-row rounded-md w-full">
                                <textarea
                                    class="py-2 text-slate-600 relative bg-white rounded text-sm border border-secondary outline-none focus:ring-secondary focus:outline-none focus:border-secondary w-full"
                                    v-model="close_form.comments"
                                    name="form.name" required
                                >
                                </textarea>
                            </div>
                        </div>
                        <span class="text-red-500" v-if="errors.data.web_errors.comments  && this.has_errors">
                                    {{ errors.data.web_errors.comments[0] }}
                                </span>
                    </div>
                    <div class="flex flex-col items-start justify-start mb-3 w-full">
                        <p class="text-md font-bold text-sm lg:text-md mb-3">Close Date<span
                            class="text-red-500 ml-2">*</span></p>
                        <div class="flex flex-row items-start justify-start w-full mb-1">
                            <div class="flex flex-row rounded-md w-full">
                                <el-date-picker
                                    size="large"
                                    style="width: 100%"
                                    v-model="close_form.date_closed"
                                    type="datetime"
                                    placeholder="Date Closed">
                                </el-date-picker>
                            </div>
                        </div>
                        <span class="text-red-500" v-if="errors.data.web_errors.date_closed  && this.has_errors">
                                    {{ errors.data.web_errors.date_closed[0] }}
                                </span>
                    </div>
                </form>
                <div class="dialog-footer flex flex-row w-full justify-end px-4">
                    <el-button class="bg-sky-600 text-white" @click="closeJourney">
                        Close Journey
                    </el-button>
                    <el-button class="bg-red-700 text-white" type="danger"
                               @click="closeJourneyDialogVisible = false">
                        Cancel
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {Plus, Download, Check, ArrowDown} from '@element-plus/icons-vue'
import moment from "moment";

export default {
    data() {
        return {
            search: '',
            windowWidth: window.innerWidth,
            addJourneyDialogVisible: false,
            closeJourneyDialogVisible: false,
            form: {
                checkpoint_type: 'Forward'
            },
            close_form: {},
            user: {},
            inspection: {},
            company_vehicle: {},
            show_form: false,
            filters: {
                paginate: true,
                per_page: 10
            },
        };
    },
    components: {
        ArrowDown,
        Check,
        Download,
        Plus
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.getCompanyBranchesList({
            id: this.user.user_company.company_id
        })
        this.getJourney(this.$route.params.id).then(() => {
            this.getJourneyDetails({
                journey_id: this.journey.id,
                ...this.filters,
            })
        })
        this.getCompanyCommodities({
            id: this.user.user_company.company_id
        })
        this.getCompanyTownsList({
            id: this.user.user_company.company_id
        })
        this.getCustomers({
            id: this.user.user_company.company_id
        })
    },
    computed: {
        disabledSeconds(hour, minute) {
            if (hour === 18 && minute === 30) {
                return this.makeRange(1, 59);
            }
            return false
        },
        ...mapGetters({
            'errors': "journeys/errors",
            'has_errors': 'journeys/has_errors',
            company_branches: 'company_branches/company_branches',
            journey: 'journeys/journey',
            journey_detail: 'journeys/journey_detail',
            journey_details: 'journeys/journey_details',
            company_towns: 'towns/company_towns',
            customers: 'customers/customers',
            commodities:'company_commodities/company_commodities'
        }),
    },
    methods: {
        ...mapActions({
            getCompanyBranchesList: 'company_branches/getCompanyBranches',
            getJourney: 'journeys/getJourney',
            createJourneyDetails: 'journeys/createJourneyDetails',
            editJourneyDetail: 'journeys/editJourneyDetail',
            getJourneyDetail: 'journeys/getJourneyDetail',
            getJourneyDetails: 'journeys/getJourneyDetails',
            downloadJourneyReport: 'journeys/downloadJourneyReport',
            getCommodities: 'commodities/getCommodities',
            updateJourney: 'journeys/editJourney',
            getCompanyTownsList: 'towns/getCompanyTowns',
            getCustomers: 'customers/getCustomers',
            getCompanyCommodities:'company_commodities/getCompanyCommodities'
        }),
        handleSizeChange(size) {
            this.filters.per_page = size;
            this.getJourneyDetails({paginate: true, ...this.filters});
        },
        handleCurrentChange(page) {
            this.filters.page = page;
            this.getJourneyDetails({paginate: true, ...this.filters});
        },
        makeRange(start, end) {
            const result = [];
            for (let i = start; i <= end; i++) {
                result.push(i);
            }
            return result;
        },
        updateJourneyDetail(item) {
            this.show_form = true
            this.form = Object.assign({}, this.form, item);
            if (this.form.departure_time) {
                const currentDate = moment().format("YYYY-MM-DD"); // Get today's date in YYYY-MM-DD format
                const dateTimeString = `${currentDate} ${this.form.departure_time}`; // Combine date and time
                this.form.departure_time = moment(dateTimeString, "YYYY-MM-DD HH:mm");
            }
            if (this.form.arrival_time) {
                const currentDate = moment().format("YYYY-MM-DD"); // Get today's date in YYYY-MM-DD format
                const dateTimeString = `${currentDate} ${this.form.arrival_time}`; // Combine date and time
                this.form.arrival_time = moment(dateTimeString, "YYYY-MM-DD HH:mm");
            }
            if (this.form.expected_arrival_date) {
                const currentDate = moment().format("YYYY-MM-DD"); // Get today's date in YYYY-MM-DD format
                const dateTimeString = `${currentDate} ${this.form.expected_arrival_date}`; // Combine date and time
                this.form.expected_arrival_date = moment(dateTimeString, "YYYY-MM-DD HH:mm");
            }
            if (this.form.loading_time) {
                const currentDate = moment().format("YYYY-MM-DD"); // Get today's date in YYYY-MM-DD format
                const dateTimeString = `${currentDate} ${this.form.loading_time}`; // Combine date and time
                this.form.loading_time = moment(dateTimeString, "YYYY-MM-DD HH:mm");
            }
        },
        saveJourneyDetails() {
            this.form.journey_id = this.journey.id
            if (this.form.checkpoint_type === 'Customer') {
                this.form.status = 'Closed'
            }
            if (this.form.id) {
                this.editJourneyDetail(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.form.checkpoint_type = 'Forward'
                        this.show_form = false
                    }else{
                        this.show_form = true
                    }
                })
            } else {
                this.createJourneyDetails(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.form.checkpoint_type = 'Forward'
                        this.show_form = false
                    }
                });
            }

        },
        viewCheckpoints(item) {
            this.$router.push('/checkpoints/' + item.id)
        },
        closeTrip() {
            this.closeJourneyDialogVisible = true
            this.close_form = Object.assign({}, this.close_form, this.journey);
        },
        downloadPdf() {
            this.downloadJourneyReport({id: this.journey.id})
        },
        closeJourney() {
            console.log(this.close_form)
            this.close_form.status = 'Closed'
            this.updateJourney(this.close_form).then(() => {
                this.getJourney(this.$route.params.id)
                if (!this.has_errors) {
                    this.close_form = {}
                    this.closeJourneyDialogVisible = false
                }
            });
        },
        back() {
            this.$router.push('/journeys')
        }

    }
}
</script>
