import client from "./client";

export default {
    async getIncentives(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/incentives', filters)));
    },

    async createIncentives(data) {
        return client.parseResponse(await client.post('/incentives', data))
    },

    async getIncentive(id) {
        return client.parseResponse(await client.get('/incentives/' + id));
    },

    async editIncentive(data) {
        return client.parseResponse(await client.put('/incentives/' + data.id, data))
    },

    async deleteIncentive(payload) {
        return client.parseResponse(await client.delete('/incentives/' + payload.id));
    },



    async getInspectionIncentives(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/inspection-incentives', filters)));
    },

    async createInspectionIncentives(data) {
        return client.parseResponse(await client.post('/inspection-incentives', data))
    },

    async getInspectionIncentive(id) {
        return client.parseResponse(await client.get('/inspection-incentives/' + id));
    },

    async editInspectionIncentive(data) {
        return client.parseResponse(await client.put('/inspection-incentives/' + data.id, data))
    },

    async deleteInspectionIncentive(payload) {
        return client.parseResponse(await client.delete('/inspection-incentives/' + payload.id));
    },



    async getUserIncentives(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/user-incentives', filters)));
    },
    async authorizeIncentive(data) {
        return client.parseResponse(await client.put('/authorize-incentive/' + data.id, data))
    },

}
