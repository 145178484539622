import client from "./client";

export default {
    async getJourneys(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/journeys_v1', filters)));
    },

    async createJourneys(data) {
        return client.parseResponse(await client.post('/journeys_v1', data))
    },

    async getJourney(id) {
        return client.parseResponse(await client.get('/journeys_v1/' + id));
    },

    async editJourney(data) {
        return client.parseResponse(await client.put('/journeys_v1/' + data.id, data))
    },

    async deleteJourney(payload) {
        return client.parseResponse(await client.delete('/journeys_v1/' + payload.id));
    },
    async exportJourneys(filters) {
        return await client.get('/export-journeys', filters, {responseType: 'blob'});
    },


    async getJourneyDetails(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/journey-details', filters)));
    },

    async createJourneyDetails(data) {
        return client.parseResponse(await client.post('/journey-details', data))
    },

    async getJourneyDetail(id) {
        return client.parseResponse(await client.get('/journey-details/' + id));
    },

    async editJourneyDetail(data) {
        return client.parseResponse(await client.put('/journey-details/' + data.id, data))
    },

    async deleteJourneyDetail(payload) {
        return client.parseResponse(await client.delete('/journey-details/' + payload.id));
    },


    async getJourneyCheckpoints(filters) {
        return client.parseResponse(await client.get('/journey-checkpoints', filters));
    },

    async createJourneyCheckpoints(data) {
        return client.parseResponse(await client.post('/journey-checkpoints', data))
    },

    async getJourneyCheckpoint(id) {
        return client.parseResponse(await client.get('/journey-checkpoints/' + id));
    },

    async editJourneyCheckpoint(data) {
        return client.parseResponse(await client.put('/journey-checkpoints/' + data.id, data))
    },

    async deleteJourneyCheckpoint(payload) {
        return client.parseResponse(await client.delete('/journey-checkpoints/' + payload.id));
    },


    async downloadJourneyReport(filters) {
        return await client.post('/download-journey-details', filters, {responseType: 'blob'});
    },


    async getSkipReasons(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/skip-reasons', filters)));
    },
    async getNonComplianceReasons(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/non-compliance-reasons', filters)));
    },


    async getJourneyHops(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/journey-hops', filters)));
    },

    async createJourneyHops(data) {
        return client.parseResponse(await client.post('/journey-hops', data))
    },

    async getJourneyHop(id) {
        return client.parseResponse(await client.get('/journey-hops/' + id));
    },

    async editJourneyHop(data) {
        return client.parseResponse(await client.put('/journey-hops/' + data.id, data))
    },

    async deleteJourneyHop(payload) {
        return client.parseResponse(await client.delete('/journey-hops/' + payload.id));
    },
    async uploadJourneyHops(payload) {
        return client.parseResponse(await client.upload('/import-journey-hops', payload, payload.file))
    },
    async exportJourneyHops(filters) {
        return await client.get('/export-journey-hops', filters, {responseType: 'blob'});
    },

    async getTruckOpenJourneys(filters) {
        return client.parseResponse(await client.get('/trucks-open-journeys', filters));
    },

    async closeJourneyDetail(filters) {
        return client.parseResponse(await client.put('/close-journey-detail' ,filters))
    },

}
