import api from "../api";
import {ElMessage} from 'element-plus'
import store from "@/data/store/index";

export default {
    namespaced: true,
    state: {
        inspection_questions: {data: [], current_page: 1, total: 0, per_page: 10},
        inspection_question: {},
        inspection_dropdowns:{},
        errors: {
            data: {
                errors: {
                    name: [],
                }
            }
        },
        has_errors: false,
    },
    getters: {
        inspection_question: state => state.inspection_question,
        inspection_questions: state => state.inspection_questions,
        inspection_dropdowns: state => state.inspection_dropdowns,
        errors: state => state.errors,
        has_errors: state => state.has_errors,
    },
    mutations: {
        setInspectionQuestion(state, data) {
            state.inspection_question = data;
        },
        setInspectionDropdown(state, data) {
            state.inspection_dropdowns = data;
        },
        setInspectionQuestions(state, data) {
            console.log(data)
            state.inspection_questions = data;
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        errors: {
                            name: [],
                        }
                    }
                };
            }
        }
    },
    actions: {
        async getInspectionQuestions({commit}, filters) {
            try {
                const response = await api.getInspectionQuestions(filters);
                commit('setInspectionQuestions', response);
            } catch (e) {
                console.log(e)
            }
        },
        async getInspectionQuestion({commit}, id) {
            try {
                const response = await api.getInspectionQuestion(id);
                commit('setInspectionQuestion', response);
            } catch (e) {
                console.log(e)
            }
        },

        async createInspectionQuestions({commit}, data) {
            try {
                commit('hasErrors', false)
                const response = await api.createInspectionQuestions(data);
                await store.dispatch('inspections/getInspectionQuestions', {id: response.id})
                ElMessage({
                    message: 'InspectionQuestion created successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error creating InspectionQuestion!',
                    type: 'error',
                })
            }
        },
        async editInspectionQuestion(context, data) {
            try {
                await api.editInspectionQuestion(data);
                await store.dispatch('inspections/getInspectionQuestions', {id:data.inspection_id});
                ElMessage({
                    message: 'InspectionQuestion updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: 'Error updating InspectionQuestion',
                    type: 'error',
                })
            }
        },
        async editInspectionQuestions(context, data) {
                await api.editInspectionQuestion(data);
        },
        async deleteInspectionQuestion(context,payload) {
            try {
                const {message} = await api.deleteInspectionQuestion(payload)
                ElMessage({
                    message: message,
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            }
        },
        async getInspectionDropdowns({commit}, filters) {
            try {
                const response = await api.getInspectionDropdowns(filters);
                commit('setInspectionDropdown', response);
            } catch (e) {
                console.log(e)
            }
        },
    }
}
