<template>
    <div class="flex flex-col">
        <div class="flex md:flex-row flex-col bg-white border w-full justify-between px-6 mb-6 pb-6">
            <div class="mb-6 md:p-5 px-0 py-4 md:w-6/12 w-full">
                <label for="title" class="block mb-2 text-2xl font-medium text-gray-900">Inspection Title</label>
                <input @focusout="saveInspection" v-model="inspection.title" type="text" id="title"
                       placeholder="Title"
                       class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring focus:ring-sky-400 focus:border-sky-400 outline-none block w-full p-3"
                       required>
            </div>
            <div class="flex flex-row items-center justify-end">
                <div>
                    <button
                        @click="cancelInspection"
                        class="bg-red-500 text-white text-sm font-bold uppercase px-12 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
        <div class="flex md:flex-row flex-col w-full justify-between gap-y-2">
            <div class="flex flex-col w-full mr-4 gap-y-2">
                <div class="flex flex-col bg-white border">
                    <div class="flex border-b items-center px-6 py-4">
                        <span class="text-xl">Add Card</span>
                    </div>
                    <div class="px-6 py-6 flex flex-col w-full">
                        <div class="w-full pb-16 border-b">
                            <span class="font-semibold">Card Type</span>
                            <Listbox v-model="form.question_type">
                                <div class="relative mt-1">
                                    <ListboxButton
                                        class="relative md:w-6/12 w-full h-12 border cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                        <div class="flex flex-row space-x-2">
                                            <span v-html="form.question_type.avatar"></span>
                                            <span class="block truncate">{{ form.question_type.name }}</span>
                                        </div>
                                        <span
                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"/>
                                    </svg>
                                    </span>

                                    </ListboxButton>

                                    <transition
                                        leave-active-class="transition duration-100 ease-in"
                                        leave-from-class="opacity-100"
                                        leave-to-class="opacity-0">
                                        <ListboxOptions
                                            class="absolute mt-1 w-1/2 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            <ListboxOption
                                                v-slot="{ active, selected }"
                                                v-for="question_type in inspection_question_types"
                                                :key="question_type.name"
                                                :value="question_type"
                                                as="template">
                                                <li
                                                    :class="[
                                                          active ? 'bg-amber-100 text-amber-900' : 'text-gray-900',
                                                          'relative cursor-default select-none py-2 pl-10 pr-4',
                                                        ]">
                                                    <div class="flex flex-row space-x-2">
                                                        <span v-html="question_type.avatar"></span>
                                                        <span
                                                            :class="[
                                                    selected ? 'font-medium' : 'font-normal',
                                                    'block truncate',
                                                  ]">
                                                    {{ question_type.name }}
                                                </span>
                                                    </div>
                                                    <span
                                                        v-if="selected"
                                                        class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                                         class="w-5 h-5">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                              d="M4.5 12.75l6 6 9-13.5"/>
                                                    </svg>
                                                </span>
                                                </li>
                                            </ListboxOption>
                                        </ListboxOptions>
                                    </transition>
                                </div>
                            </Listbox>
                        </div>
                        <div class="mt-6">
                            <form @submit.prevent="saveInspectionQuestion">
                                <div v-if="form.question_type.name === 'Single Check'">
                                    <div class="mb-6">
                                        <label for="title" class="block mb-2 text-sm font-medium text-gray-900">
                                            Card Title
                                            <span class="text-red-600">*</span></label>
                                        <input v-model="form.title" type="text" id="title"
                                               class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                                               required>
                                    </div>
                                    <div class="flex flex-row space-x-2 mb-12">
                                        <Switch
                                            v-model="form.is_defect"
                                            :class="form.is_defect ? 'bg-amber-700' : 'bg-amber-400'"
                                            class="inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                            <span
                                                aria-hidden="true"
                                                :class="form.is_defect ? 'translate-x-6' : 'translate-x-0'"
                                                class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
                                            />
                                        </Switch>
                                        <span>
                                            Alert on Defect
                                        </span>
                                    </div>
                                    <div v-show="form.is_defect" class="flex flex-row space-x-2 mb-12">
                                        <Switch
                                            v-model="form.sms_on_defect"
                                            :class="form.sms_on_defect ? 'bg-amber-700' : 'bg-amber-400'"
                                            class="inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                            <span
                                                aria-hidden="true"
                                                :class="form.sms_on_defect ? 'translate-x-6' : 'translate-x-0'"
                                                class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
                                            />
                                        </Switch>
                                        <span>
                                            Send sms on Defect
                                        </span>
                                    </div>
                                    <div v-show="form.sms_on_defect" class="flex flex-col items-start justify-start mb-3 w-full">
                                        <p class="text-md font-bold text-sm lg:text-md mb-3">Select sms recipients</p>
                                        <div class="flex flex-row items-start justify-start w-full mb-1">
                                            <div class="flex flex-row rounded-md w-full">
                                                <el-select
                                                    style="width: 100%" v-model="form.sms_user_id" placeholder="Select user"
                                                    size="default" multiple>
                                                    <el-option
                                                        v-for="item in sms_users"
                                                        :key="item.id"
                                                        :label="item.first_name + ' ' + item.last_name"
                                                        :value="item.id"
                                                    />
                                                </el-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-col items-start justify-start mb-4 w-full">
                                        <label for="photo"
                                               class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Card Category
                                        </label>
                                        <div class="flex flex-row items-start justify-start w-full mb-1">
                                            <div class="flex flex-row rounded-md w-full">
                                                <el-select
                                                    style="width: 100%" v-model="form.inspection_question_category_id" placeholder="Select card category"
                                                    size="default">
                                                    <el-option
                                                        v-for="item in inspection_question_categories"
                                                        :key="item.id"
                                                        :label="item.category"
                                                        :value="item.id"
                                                    />
                                                </el-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-6">
                                        <label for="photo"
                                               class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Photo Reference(Optional)
                                        </label>
                                        <input
                                            @change="photoReference"
                                            class="block md:w-6/12 w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2"
                                            id="file_input" type="file">
                                    </div>
                                </div>
                                <div v-if="form.question_type.name === 'Multi Check'">
                                    <div class="mb-6">
                                        <label for="title" class="block mb-2 text-sm font-medium text-gray-900">
                                            Card Title
                                            <span class="text-red-600">*</span></label>
                                        <input v-model="form.title" type="text" id="title"
                                               class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                                               required>
                                    </div>

                                    <div class="mb-6">
                                        <div v-for="(option,index) in form.options" :key="index" class="">
                                            <div class="flex flex-row w-full bg-gray-50 mb-6 p-5 items-start">
                                                <button :disabled="index === 0 && form.options.length <=1" class="mr-3"
                                                        @click="optionStatus('remove',index)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24"
                                                         stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                              d="M6 18L18 6M6 6l12 12"/>
                                                    </svg>
                                                </button>
                                                <div class="w-full">
                                                    <label for="title"
                                                           class="block mb-2 text-sm font-medium text-gray-900">
                                                        Option {{ index + 1 }}
                                                    </label>
                                                    <input :value="option.option"
                                                           @input="setOptionInput($event.target.value,index)"
                                                           class="shadow-sm bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                                                           required>
                                                    <div class="flex flex-row space-x-2 mt-3 mb-3">
                                                        <Switch
                                                            v-model="option.is_defect"
                                                            :class="option.is_defect ? 'bg-amber-700' : 'bg-amber-400'"
                                                            class="inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                                            <span
                                                                aria-hidden="true"
                                                                :class="option.is_defect ? 'translate-x-6' : 'translate-x-0'"
                                                                class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"/>
                                                        </Switch>
                                                        <p>
                                                            Alert on Defect
                                                        </p>
                                                    </div>
                                                    <div v-if="option.is_defect" class="flex flex-row space-x-2 mt-3 mb-3">
                                                        <Switch
                                                            v-model="option.sms_on_defect"
                                                            :class="option.sms_on_defect ? 'bg-amber-700' : 'bg-amber-400'"
                                                            class="inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                                            <span
                                                                aria-hidden="true"
                                                                :class="option.sms_on_defect ? 'translate-x-6' : 'translate-x-0'"
                                                                class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"/>
                                                        </Switch>
                                                        <p>
                                                           Send sms on defect
                                                        </p>
                                                    </div>
                                                    <div v-show="option.sms_on_defect" class="flex flex-col items-start justify-start mb-3 w-full">
                                                        <p class="text-md font-bold text-sm lg:text-md mb-3">Select sms recipients</p>
                                                        <div class="flex flex-row items-start justify-start w-full mb-1">
                                                            <div class="flex flex-row rounded-md w-full">
                                                                <el-select
                                                                    style="width: 100%" v-model="option.sms_user_id" placeholder="Select user"
                                                                    size="default" multiple>
                                                                    <el-option
                                                                        v-for="item in sms_users"
                                                                        :key="item.id"
                                                                        :label="item.first_name + ' ' + item.last_name"
                                                                        :value="item.id"
                                                                    />
                                                                </el-select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <button class="flex flex-row items-center hover:bg-blue-100 p-2 rounded-lg"
                                                    type="button" :disabled="form.options.length >= 10"
                                                    @click="optionStatus('add', null)">
                                                <div class="mr-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24"
                                                         stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                    </svg>
                                                </div>
                                                Add Another Option
                                            </button>
                                        </div>
                                    </div>
                                    <div class="flex flex-col items-start justify-start mb-4 w-full">
                                        <label for="photo"
                                               class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Card Category
                                        </label>
                                        <div class="flex flex-row items-start justify-start w-full mb-1">
                                            <div class="flex flex-row rounded-md w-full">
                                                <el-select
                                                    style="width: 100%" v-model="form.inspection_question_category_id" placeholder="Select card category"
                                                    size="default">
                                                    <el-option
                                                        v-for="item in inspection_question_categories"
                                                        :key="item.id"
                                                        :label="item.category"
                                                        :value="item.id"
                                                    />
                                                </el-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-6">
                                        <label for="photo"
                                               class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Photo Reference(Optional)
                                        </label>
                                        <input
                                            @change="photoReference"
                                            class="block w-1/2 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2"
                                            id="file_input" type="file">
                                    </div>
                                </div>
                                <div v-if="form.question_type.name === 'Multiple Choice'">
                                    <div class="mb-6">
                                        <label for="title" class="block mb-2 text-sm font-medium text-gray-900">
                                            Card Title
                                            <span class="text-red-600">*</span></label>
                                        <input v-model="form.title" type="text" id="title"
                                               class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                                               required>
                                    </div>

                                    <div class="mb-6">
                                        <div v-for="(option,index) in form.options" :key="index" class="">
                                            <div class="flex flex-row w-full bg-gray-50 mb-6 p-5 items-start">
                                                <button :disabled="[0,1].includes(index) && form.options.length <=2"
                                                        class="mr-3"
                                                        @click="optionStatus('remove',index)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24"
                                                         stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                              d="M6 18L18 6M6 6l12 12"/>
                                                    </svg>
                                                </button>
                                                <div class="w-full">
                                                    <label for="title"
                                                           class="block mb-2 text-sm font-medium text-gray-900">
                                                        Option {{ index + 1 }}
                                                    </label>
                                                    <input :value="option.option"
                                                           @input="setOptionInput($event.target.value,index)"
                                                           class="shadow-sm bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                                                           required>
                                                    <div class="flex flex-row space-x-2 mt-3 mb-3">
                                                        <Switch
                                                            v-model="option.is_defect"
                                                            :class="option.is_defect ? 'bg-amber-700' : 'bg-amber-400'"
                                                            class="inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                                            <span
                                                                aria-hidden="true"
                                                                :class="option.is_defect ? 'translate-x-6' : 'translate-x-0'"
                                                                class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"/>
                                                        </Switch>
                                                        <p>
                                                            Create Defect if selected
                                                        </p>
                                                    </div>
                                                    <div v-if="option.is_defect" class="flex flex-row space-x-2 mt-3 mb-3">
                                                        <Switch
                                                            v-model="option.sms_on_defect"
                                                            :class="option.sms_on_defect ? 'bg-amber-700' : 'bg-amber-400'"
                                                            class="inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                                            <span
                                                                aria-hidden="true"
                                                                :class="option.sms_on_defect ? 'translate-x-6' : 'translate-x-0'"
                                                                class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"/>
                                                        </Switch>
                                                        <p>
                                                            Send sms on defect
                                                        </p>
                                                    </div>
                                                    <div v-show="option.sms_on_defect" class="flex flex-col items-start justify-start mb-3 w-full">
                                                        <p class="text-md font-bold text-sm lg:text-md mb-3">Select sms recipients</p>
                                                        <div class="flex flex-row items-start justify-start w-full mb-1">
                                                            <div class="flex flex-row rounded-md w-full">
                                                                <el-select
                                                                    style="width: 100%" v-model="option.sms_user_id" placeholder="Select user"
                                                                    size="default" multiple>
                                                                    <el-option
                                                                        v-for="item in sms_users"
                                                                        :key="item.id"
                                                                        :label="item.first_name + ' ' + item.last_name"
                                                                        :value="item.id"
                                                                    />
                                                                </el-select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <button class="flex flex-row items-center hover:bg-blue-100 p-2 rounded-lg"
                                                    type="button" :disabled="form.options.length >= 10"
                                                    @click="optionStatus('add', null)">
                                                <div class="mr-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24"
                                                         stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                    </svg>
                                                </div>
                                                Add Another Option
                                            </button>
                                        </div>
                                    </div>
                                    <div class="flex flex-col items-start justify-start mb-4 w-full">
                                        <label for="photo"
                                               class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Card Category
                                        </label>
                                        <div class="flex flex-row items-start justify-start w-full mb-1">
                                            <div class="flex flex-row rounded-md w-full">
                                                <el-select
                                                    style="width: 100%" v-model="form.inspection_question_category_id" placeholder="Select card category"
                                                    size="default">
                                                    <el-option
                                                        v-for="item in inspection_question_categories"
                                                        :key="item.id"
                                                        :label="item.category"
                                                        :value="item.id"
                                                    />
                                                </el-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-6">
                                        <label for="photo"
                                               class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Photo Reference(Optional)
                                        </label>
                                        <input
                                            @change="photoReference"
                                            class="block w-1/2 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2"
                                            id="file_input" type="file">
                                    </div>
                                </div>
                                <div v-if="form.question_type.name === 'Checkboxes'">
                                    <div class="mb-6">
                                        <label for="title" class="block mb-2 text-sm font-medium text-gray-900">
                                            Card Title
                                            <span class="text-red-600">*</span></label>
                                        <input v-model="form.title" type="text" id="title"
                                               class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                                               required>
                                    </div>

                                    <div class="mb-6">
                                        <div v-for="(option,index) in form.options" :key="index" class="">
                                            <div class="flex flex-row w-full bg-gray-50 mb-6 p-5 items-start">
                                                <button :disabled="[0,1].includes(index) && form.options.length <=2"
                                                        class="mr-3"
                                                        @click="optionStatus('remove',index)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24"
                                                         stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                              d="M6 18L18 6M6 6l12 12"/>
                                                    </svg>
                                                </button>
                                                <div class="w-full">
                                                    <label for="title"
                                                           class="block mb-2 text-sm font-medium text-gray-900">
                                                        Option {{ index + 1 }}
                                                    </label>
                                                    <input :value="option.option"
                                                           @input="setOptionInput($event.target.value,index)"
                                                           class="shadow-sm bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                                                           required>
                                                    <div class="flex flex-row space-x-2 mt-3 mb-3">
                                                        <Switch
                                                            v-model="option.is_defect"
                                                            :class="option.is_defect ? 'bg-amber-700' : 'bg-amber-400'"
                                                            class="inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                                            <span
                                                                aria-hidden="true"
                                                                :class="option.is_defect ? 'translate-x-6' : 'translate-x-0'"
                                                                class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"/>
                                                        </Switch>
                                                        <p>
                                                            Create Defect if selected
                                                        </p>
                                                    </div>
                                                    <div v-if="option.is_defect" class="flex flex-row space-x-2 mt-3 mb-3">
                                                        <Switch
                                                            v-model="option.sms_on_defect"
                                                            :class="option.sms_on_defect ? 'bg-amber-700' : 'bg-amber-400'"
                                                            class="inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                                            <span
                                                                aria-hidden="true"
                                                                :class="option.sms_on_defect ? 'translate-x-6' : 'translate-x-0'"
                                                                class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"/>
                                                        </Switch>
                                                        <p>
                                                            Send sms on defect
                                                        </p>
                                                    </div>
                                                    <div v-show="option.sms_on_defect" class="flex flex-col items-start justify-start mb-3 w-full">
                                                        <p class="text-md font-bold text-sm lg:text-md mb-3">Select sms recipients</p>
                                                        <div class="flex flex-row items-start justify-start w-full mb-1">
                                                            <div class="flex flex-row rounded-md w-full">
                                                                <el-select
                                                                    style="width: 100%" v-model="option.sms_user_id" placeholder="Select user"
                                                                    size="default" multiple>
                                                                    <el-option
                                                                        v-for="item in sms_users"
                                                                        :key="item.id"
                                                                        :label="item.first_name + ' ' + item.last_name"
                                                                        :value="item.id"
                                                                    />
                                                                </el-select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <button class="flex flex-row items-center hover:bg-blue-100 p-2 rounded-lg"
                                                    type="button" :disabled="form.options.length >= 10"
                                                    @click="optionStatus('add', null)">
                                                <div class="mr-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24"
                                                         stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                    </svg>
                                                </div>
                                                Add Another Option
                                            </button>
                                        </div>
                                    </div>
                                    <div class="flex flex-col items-start justify-start mb-4 w-full">
                                        <label for="photo"
                                               class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Card Category
                                        </label>
                                        <div class="flex flex-row items-start justify-start w-full mb-1">
                                            <div class="flex flex-row rounded-md w-full">
                                                <el-select
                                                    style="width: 100%" v-model="form.inspection_question_category_id" placeholder="Select card category"
                                                    size="default">
                                                    <el-option
                                                        v-for="item in inspection_question_categories"
                                                        :key="item.id"
                                                        :label="item.category"
                                                        :value="item.id"
                                                    />
                                                </el-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-6">
                                        <label for="photo"
                                               class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Photo Reference(Optional)
                                        </label>
                                        <input
                                            @change="photoReference"
                                            class="block w-1/2 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2"
                                            id="file_input" type="file">
                                    </div>
                                </div>
                                <div v-if="form.question_type.name === 'Photo'">
                                    <div class="mb-6">
                                        <label for="title" class="block mb-2 text-sm font-medium text-gray-900">
                                            Card Title
                                            <span class="text-red-600">*</span></label>
                                        <input v-model="form.title" type="text" id="title"
                                               class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                                               required>
                                    </div>
                                    <div class="flex flex-col items-start justify-start mb-4 w-full">
                                        <label for="photo"
                                               class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Card Category
                                        </label>
                                        <div class="flex flex-row items-start justify-start w-full mb-1">
                                            <div class="flex flex-row rounded-md w-full">
                                                <el-select
                                                    style="width: 100%" v-model="form.inspection_question_category_id" placeholder="Select card category"
                                                    size="default">
                                                    <el-option
                                                        v-for="item in inspection_question_categories"
                                                        :key="item.id"
                                                        :label="item.category"
                                                        :value="item.id"
                                                    />
                                                </el-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-6">
                                        <label for="photo"
                                               class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Photo Reference(Optional)
                                        </label>
                                        <input
                                            @change="photoReference"
                                            class="block w-1/2 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2"
                                            id="file_input" type="file">
                                    </div>
                                </div>
                                <div v-if="form.question_type.name === 'Mileage'">
                                    <div class="mb-6">
                                        <label for="title" class="block mb-2 text-sm font-medium text-gray-900">
                                            Card Title
                                            <span class="text-red-600">*</span></label>
                                        <input v-model="form.title" type="text" id="title"
                                               class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                                               required>
                                    </div>
                                    <div class="flex flex-col items-start justify-start mb-4 w-full">
                                        <label for="photo"
                                               class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Card Category
                                        </label>
                                        <div class="flex flex-row items-start justify-start w-full mb-1">
                                            <div class="flex flex-row rounded-md w-full">
                                                <el-select
                                                    style="width: 100%" v-model="form.inspection_question_category_id" placeholder="Select card category"
                                                    size="default">
                                                    <el-option
                                                        v-for="item in inspection_question_categories"
                                                        :key="item.id"
                                                        :label="item.category"
                                                        :value="item.id"
                                                    />
                                                </el-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-6">
                                        <label for="photo"
                                               class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Photo Reference(Optional)
                                        </label>
                                        <input
                                            @change="photoReference"
                                            class="block w-1/2 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2"
                                            id="file_input" type="file">
                                    </div>
                                </div>
                                <div v-if="form.question_type.name === 'Engine Hours'">
                                    <div class="mb-6">
                                        <label for="title" class="block mb-2 text-sm font-medium text-gray-900">
                                            Card Title
                                            <span class="text-red-600">*</span></label>
                                        <input v-model="form.title" type="text" id="title"
                                               class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                                               required>
                                    </div>
                                    <div class="flex flex-col items-start justify-start mb-4 w-full">
                                        <label for="photo"
                                               class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Card Category
                                        </label>
                                        <div class="flex flex-row items-start justify-start w-full mb-1">
                                            <div class="flex flex-row rounded-md w-full">
                                                <el-select
                                                    style="width: 100%" v-model="form.inspection_question_category_id" placeholder="Select card category"
                                                    size="default">
                                                    <el-option
                                                        v-for="item in inspection_question_categories"
                                                        :key="item.id"
                                                        :label="item.category"
                                                        :value="item.id"
                                                    />
                                                </el-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-6">
                                        <label for="photo"
                                               class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Photo Reference(Optional)
                                        </label>
                                        <input
                                            @change="photoReference"
                                            class="block w-1/2 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2"
                                            id="file_input" type="file">
                                    </div>
                                </div>
                                <div v-if="form.question_type.name === 'Data Entry Alphanumeric'">
                                    <div class="mb-6">
                                        <label for="title" class="block mb-2 text-sm font-medium text-gray-900">
                                            Card Title
                                            <span class="text-red-600">*</span></label>
                                        <input v-model="form.title" type="text" id="title"
                                               class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                                               required>
                                    </div>
                                    <div class="flex flex-col items-start justify-start mb-4 w-full">
                                        <label for="photo"
                                               class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Card Category
                                        </label>
                                        <div class="flex flex-row items-start justify-start w-full mb-1">
                                            <div class="flex flex-row rounded-md w-full">
                                                <el-select
                                                    style="width: 100%" v-model="form.inspection_question_category_id" placeholder="Select card category"
                                                    size="default">
                                                    <el-option
                                                        v-for="item in inspection_question_categories"
                                                        :key="item.id"
                                                        :label="item.category"
                                                        :value="item.id"
                                                    />
                                                </el-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-6">
                                        <label for="photo"
                                               class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Photo Reference(Optional)
                                        </label>
                                        <input
                                            @change="photoReference"
                                            class="block w-1/2 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2"
                                            id="file_input" type="file">
                                    </div>
                                </div>
                                <div v-if="form.question_type.name === 'Data Entry Numeric'">
                                    <div class="mb-6">
                                        <label for="title" class="block mb-2 text-sm font-medium text-gray-900">
                                            Card Title
                                            <span class="text-red-600">*</span></label>
                                        <input v-model="form.title" type="text" id="title"
                                               class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                                               required>
                                    </div>
                                    <div class="flex flex-col items-start justify-start mb-4 w-full">
                                        <label for="photo"
                                               class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Card Category
                                        </label>
                                        <div class="flex flex-row items-start justify-start w-full mb-1">
                                            <div class="flex flex-row rounded-md w-full">
                                                <el-select
                                                    style="width: 100%" v-model="form.inspection_question_category_id" placeholder="Select card category"
                                                    size="default">
                                                    <el-option
                                                        v-for="item in inspection_question_categories"
                                                        :key="item.id"
                                                        :label="item.category"
                                                        :value="item.id"
                                                    />
                                                </el-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-6">
                                        <label for="photo"
                                               class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Photo Reference(Optional)
                                        </label>
                                        <input
                                            @change="photoReference"
                                            class="block w-1/2 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2"
                                            id="file_input" type="file">
                                    </div>
                                </div>
                                <div v-if="form.question_type.name === 'Signature'">
                                    <div class="mb-6">
                                        <label for="title" class="block mb-2 text-sm font-medium text-gray-900">
                                            Card Title
                                            <span class="text-red-600">*</span></label>
                                        <input v-model="form.title" type="text" id="title"
                                               class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                                               required>
                                    </div>
                                    <div class="flex flex-col items-start justify-start mb-4 w-full">
                                        <label for="photo"
                                               class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Card Category
                                        </label>
                                        <div class="flex flex-row items-start justify-start w-full mb-1">
                                            <div class="flex flex-row rounded-md w-full">
                                                <el-select
                                                    style="width: 100%" v-model="form.inspection_question_category_id" placeholder="Select card category"
                                                    size="default">
                                                    <el-option
                                                        v-for="item in inspection_question_categories"
                                                        :key="item.id"
                                                        :label="item.category"
                                                        :value="item.id"
                                                    />
                                                </el-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="form.question_type.name === 'Dropdown'">
                                    <div class="mb-6">
                                        <label for="title" class="block mb-2 text-sm font-medium text-gray-900">
                                            Card Title
                                            <span class="text-red-600">*</span></label>
                                        <input v-model="form.title" type="text" id="title"
                                               class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                                               required>
                                    </div>
                                    <div class="mb-6">
                                        <label for="title" class="block mb-2 text-sm font-medium text-gray-900">
                                            Dropdown Data
                                            <span class="text-red-600">*</span></label>
                                        <el-select v-model="form.url" placeholder="Select dropdown data" class="w-full">
                                            <el-option
                                                v-for="(item, index) in inspection_dropdowns"
                                                :key="index"
                                                :label="item.title"
                                                :value="item.url"
                                            />
                                        </el-select>
                                    </div>
                                    <div class="flex flex-col items-start justify-start mb-4 w-full">
                                        <label for="photo"
                                               class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Card Category
                                        </label>
                                        <div class="flex flex-row items-start justify-start w-full mb-1">
                                            <div class="flex flex-row rounded-md w-full">
                                                <el-select
                                                    style="width: 100%" v-model="form.inspection_question_category_id" placeholder="Select card category"
                                                    size="default">
                                                    <el-option
                                                        v-for="item in inspection_question_categories"
                                                        :key="item.id"
                                                        :label="item.category"
                                                        :value="item.id"
                                                    />
                                                </el-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-6">
                                        <label for="photo"
                                               class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Photo Reference(Optional)
                                        </label>
                                        <input
                                            @change="photoReference"
                                            class="block md:w-6/12 w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2"
                                            id="file_input" type="file">
                                    </div>
                                </div>
                                <div>
                                    <button @click="this.cancelQuestionDialog = true" type="button"
                                            class="focus:outline-none text-white bg-red-500 font-medium rounded-lg text-sm px-5 py-2 mr-2 mb-2">
                                        Cancel
                                    </button>
                                    <button type="submit"
                                            class="focus:outline-none text-white bg-sky-600 font-medium rounded-lg text-sm px-5 py-2 mr-2 mb-2">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col bg-white border py-6 space-y-4">
                    <div class="flex border-b items-center px-6 py-4">
                        <span class="text-xl">Inspection Settings</span>
                    </div>
                    <div class="px-6 flex flex-col w-full">
                        <div @click="assignInspection"
                             class="border-b py-5 cursor-pointer flex flex-row justify-between items-center">
                            <span class="text-xl font-medium">Assigned Assets ({{ inspection.vehicles_count ? inspection.vehicles_count : 0 }})</span>
                            <button class="border rounded py-2 px-3 hover:bg-blue-100">Add/Remove</button>
                        </div>
                    </div>
                    <div class="px-6 flex flex-col w-full">
                        <div @click="incentivesDialogVisible = true"
                             class="border-b py-5 cursor-pointer flex flex-row justify-between items-center">
                            <span class="text-xl font-medium">Incentives</span>
                            <button class="border rounded py-2 px-3 hover:bg-blue-100">Add/Remove</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="md:w-5/12 h-screen overflow-y-scroll">
                <div class="flex flex-col bg-white border">
                    <div class="flex border-b items-center px-6 py-4">
                        <span class="text-xl">Cards</span>
                    </div>
                    <div class="p-5">
                        <div v-if="!inspection_questions"
                             class="flex flex-row items-center justify-center px-5 py-20 bg-gray-50 border">
                            <span>No cards yet</span>
                        </div>
                    </div>
                    <div class="flex flex-col p-2">
                        <div class="relative w-full mb-4">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400"
                                     fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                          clip-rule="evenodd"></path>
                                </svg>
                            </div>
                            <input v-model="search" type="text" id="search"
                                   class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                                   placeholder="Search Cards" required>
                        </div>

                        <draggable
                            :list="inspection_questions.data"
                            item-key="id"
                            class="list-group"
                            ghost-class="ghost"
                            @start="dragging = true"
                            @end="updateCardOrder"
                            :disabled="!enabled">
                            <template #item="{ element }">
                                <div class="flex flex-col border bg-gray-50 p-4 mb-4 cursor-move"
                                     :class="{ 'not-draggable': !enabled }">
                                    <h1 class="text-xl text-gray-500 font-medium mb-6">{{ element.title }}</h1>
                                    <div class="">
                                        <ul class="max-w-md text-gray-500 list-inside dark:text-gray-400">
                                            <li v-for="option in element.inspection_question_options" :key="option.id"
                                                class="flex items-center mb-6">
                                                <svg class="w-2 h-2 mr-2 text-emerald-500 flex-shrink-0"
                                                     fill="currentColor"
                                                     viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd"
                                                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                          clip-rule="evenodd"></path>
                                                </svg>
                                                <span class="text-base text-gray-500">{{ option.option }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="flex flex-row justify-between">
                                        <div>
                                            <button @click="deleteCard(element)"
                                                    class="border border-gray-400 p-1 rounded-md mr-2 hover:text-emerald-600">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                    <path stroke-linecap="round" stroke-linejoin="round"
                                                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
                                                </svg>
                                            </button>
                                            <button @click="updateCard(element)"
                                                    class="border border-gray-400 p-1 rounded-md hover:text-emerald-600">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                    <path stroke-linecap="round" stroke-linejoin="round"
                                                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"/>
                                                </svg>
                                            </button>
                                        </div>
                                        <div class="text-amber-900">
                                            <span>{{ element.inspection_question_type.name }}</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </draggable>
                    </div>
                </div>
            </div>
        </div>

        <!--Cancel Question dialog-->
        <el-dialog
            v-model="cancelQuestionDialog"
            title="Clear Changes"
            :width="windowWidth > 768 ? '30%' : '60%'"
            center>
            <div class="flex flex-col gap-y-2">
                <span
                    class="pb-3 px-4">All unsaved changes on the currently edited card will be lost.</span>
                <div class="dialog-footer flex flex-row w-full justify-center px-4">
                    <el-button class="bg-sky-600 text-white" @click="cancelQuestionDialog = false">
                        Cancel
                    </el-button>
                    <el-button @click="clearChanges" class="bg-red-700 text-white" type="danger">
                        Clear
                    </el-button>
                </div>
            </div>
        </el-dialog>

        <!--Add Incentive dialog-->
        <el-dialog
            v-model="incentivesDialogVisible"
            title="Add / Remove Incentives"
            :width="windowWidth > 768 ? '30%' : '60%'"
            center>
            <div class="flex flex-col gap-y-2">
                <form>
                    <div class="flex flex-col items-start justify-start mb-3 w-full">
                        <p class="text-md font-bold text-sm lg:text-md mb-3">Incentive
                            <span
                                class="text-red-500 ml-2">*</span>
                        </p>
                        <div class="flex flex-row items-start justify-start w-full mb-1">
                            <div class="flex flex-row rounded-md w-full">
                                <el-select
                                    @change="populateIncentive"
                                    style="width: 100%" v-model="incentive.incentive_id"
                                    placeholder="Select incentive" size="default">
                                    <el-option
                                        v-for="item in incentives"
                                        :key="item.id"
                                        :label="item.details"
                                        :value="item.id"
                                    />
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div v-show="one_incentive.data ? one_incentive.data.needs_authorization : false" class="flex flex-col items-start justify-start mb-3 w-full">
                        <p class="text-md font-bold text-sm lg:text-md mb-3">Select incentive approver</p>
                        <div class="flex flex-row items-start justify-start w-full mb-1">
                            <div class="flex flex-row rounded-md w-full">
                                <el-select
                                    style="width: 100%" v-model="incentive.approver_id" placeholder="Select user"
                                    size="default">
                                    <el-option
                                        v-for="item in sms_users"
                                        :key="item.id"
                                        :label="item.first_name + ' ' + item.last_name"
                                        :value="item.id"
                                    />
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col items-start justify-start mb-3 w-full">
                        <p class="text-md font-bold text-sm lg:text-md mb-3">Start Date</p>
                        <div class="flex flex-row items-start justify-start w-full mb-1">
                            <div class="flex flex-row rounded-md w-full">
                                <el-date-picker
                                    size="large"
                                    style="width: 100%"
                                    v-model="form.start_date"
                                    type="date"
                                    placeholder="Pick the date">
                                </el-date-picker>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col items-start justify-start mb-3 w-full">
                        <p class="text-md font-bold text-sm lg:text-md mb-3">End Date</p>
                        <div class="flex flex-row items-start justify-start w-full mb-1">
                            <div class="flex flex-row rounded-md w-full">
                                <el-date-picker
                                    size="large"
                                    style="width: 100%"
                                    v-model="form.end_date"
                                    type="date"
                                    placeholder="Pick the date">
                                </el-date-picker>
                            </div>
                        </div>
                    </div>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="addInspectionIncentive">
                            Save
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="incentivesDialogVisible = false">
                            Cancel
                        </el-button>
                    </div>
                </form>
                <div>
                    <div class="py-8 px-4">
                        <el-table class="" :data="inspection_incentives" stripe size="large" style="width: 100%">
                            <el-table-column prop="incentive.type" label="Type"/>
                            <el-table-column prop="incentive.amount" label="Amount"/>
                            <el-table-column prop="approver.full_name" label="Approver"/>
                            <el-table-column prop="incentive.start_date" label="Start"/>
                            <el-table-column prop="incentive.end_date" label="End"/>
                            <el-table-column label="Action">
                                <template #default="scope">
                                    <el-dropdown size="large">
                                        <el-button>
                                            Actions
                                            <el-icon class="el-icon--right">
                                                <arrow-down/>
                                            </el-icon>
                                        </el-button>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item @click.native="removeIncentive(scope.row)">Remove Incentive
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    Listbox,
    // ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    Switch
} from '@headlessui/vue'
import draggable from 'vuedraggable'
import {mapActions, mapGetters} from "vuex";
import {ElMessage} from "element-plus";
import {ArrowDown} from "@element-plus/icons-vue";

export default {
    components: {
        ArrowDown,
        Listbox,
        // ListboxLabel,
        ListboxButton,
        ListboxOptions,
        ListboxOption,
        Switch,
        draggable
    },
    data() {
        return {
            search: '',
            form: {
                question_type: {},
                options: [],
                sms_user_id:[]
            },
            filters: {},
            user: {},
            cancelQuestionDialog: false,
            windowWidth: window.innerWidth,
            dragging: false,
            enabled: true,
            order_form: {},
            incentivesDialogVisible: false,
            incentive:{}
        }
    },
    watch: {
        'form.question_type': function (val) {
            if (val.name === 'Multi Check') {
                if (!this.form.options.length) {
                    this.form.options = []
                    this.form.options.push({option: null, sms_user_id: []})
                }
            } else if (val.name === 'Multiple Choice') {
                if (!this.form.options.length) {
                    this.form.options = []
                    this.form.options.push(
                        {option: null, sms_user_id: []},
                        {option: null, sms_user_id: []}
                    )
                }
            } else if (val.name === 'Checkboxes') {
                if (!this.form.options.length) {
                    this.form.options = []
                    this.form.options.push(
                        {option: null, sms_user_id: []},
                        {option: null, sms_user_id: []}
                    )
                }
            } else if (val.name === 'Single Check') {
                this.form.options = []
            }
        },
        search: function (val) {
            this.getInspectionQuestions({
                id: this.$route.params.id,
                ...this.filters,
                search: val
            });
        }
    },
    mounted() {
        this.$store.commit('inspections/setInspection', {})
        this.user = JSON.parse(localStorage.getItem('user'));
        this.getInspectionQuestionTypes(this.filters).then(() => {
            this.form.question_type = this.inspection_question_types[0]
        })
        this.getInspectionQuestionCategories({});
        if (this.$route.params.id) {
            this.getInspection(this.$route.params.id)
            this.getInspectionQuestions({
                id: this.$route.params.id,
                ...this.filters
            })
        } else {
            this.$store.commit('inspections/setInspectionQuestions', [])
        }
        this.getSmsUsers(this.filters)
        this.getInspectionDropdowns({})
        this.getIncentives({
            id : this.user.user_company.company_id
        })
        this.getInspectionIncentives({
            ...this.filters,
            inspection_id:this.$route.params.id
        })
    },
    computed: {
        ...mapGetters({
            inspection_question_types: 'inspection_question_types/inspection_question_types',
            inspection: 'inspections/inspection',
            inspection_questions: 'inspections/inspection_questions',
            sms_users: 'sms_users/sms_users',
            inspection_dropdowns:'inspection_questions/inspection_dropdowns',
            inspection_question_categories:'inspection_question_types/inspection_question_categories',
            incentives:'incentives/incentives',
            inspection_incentives:'incentives/inspection_incentives',
            one_incentive:'incentives/incentive'
        }),
    },
    methods: {
        ...mapActions({
            getInspectionQuestionTypes: 'inspection_question_types/getInspectionQuestionTypes',
            createInspections: 'inspections/createInspections',
            editInspection: 'inspections/editInspection',
            getInspection: 'inspections/getInspection',
            createInspectionQuestions: 'inspection_questions/createInspectionQuestions',
            getInspectionQuestions: 'inspections/getInspectionQuestions',
            deleteInspectionQuestion: 'inspection_questions/deleteInspectionQuestion',
            editInspectionQuestion: 'inspection_questions/editInspectionQuestion',
            editInspectionQuestions: 'inspection_questions/editInspectionQuestions',
            getSmsUsers: 'sms_users/getSmsUsers',
            getInspectionDropdowns:'inspection_questions/getInspectionDropdowns',
            getInspectionQuestionCategories:'inspection_question_types/getInspectionQuestionCategories',
            getIncentives:'incentives/getIncentives',
            getInspectionIncentives:'incentives/getInspectionIncentives',
            createInspectionIncentives:'incentives/createInspectionIncentives',
            deleteInspectionIncentive:'incentives/deleteInspectionIncentive',
            getIncentive:'incentives/getIncentive'
        }),
        populateIncentive(value){
            this.getIncentive(value)
        },
        removeIncentive(item){
            this.deleteInspectionIncentive(item).then(()=>{
                this.getInspectionIncentives({
                    ...this.filters,
                    inspection_id:this.$route.params.id
                })
            })
        },
        addInspectionIncentive(){
            this.incentive.inspection_id =  this.$route.params.id
            this.createInspectionIncentives(this.incentive).then(()=>{
                this.getInspectionIncentives({
                    ...this.filters,
                    inspection_id:this.$route.params.id
                })
                // this.incentivesDialogVisible = false
            })
        },
        deleteCard(question) {
            let conf = confirm(`Are you sure you want to remove this item?`)
            if (conf) {
                this.deleteInspectionQuestion({id: question.id}).then(() => {
                    this.getInspectionQuestions({
                        id: this.$route.params.id,
                        ...this.filters
                    })
                })
            }
        },
        updateCard(question) {
            let form = {
                ...question,
                options: question.inspection_question_options.map((k)=> {
                    return {
                        ...k,
                        sms_user_id : k.sms_users.map(k => k.id)
                    }
                }),
                question_type: question.inspection_question_type,
                sms_user_id: question.sms_users.map(k => k.id)
            }
            this.form = Object.assign({}, this.form, form);
        },
        clearChanges() {
            this.form = {
                question_type: {},
                options: []
            }
            this.getInspectionQuestionTypes(this.filters).then(() => {
                this.form.question_type = this.inspection_question_types[0]
            })
            this.cancelQuestionDialog = false
        },
        cancelInspection() {
            this.form = {
                question_type: {},
                options: []
            }
            this.$router.push('/inspections')
        },
        assignInspection() {
            this.$router.push('/vehicles')
        },
        optionStatus(action, index) {
            if (action === 'remove') {
                this.form.options.splice(index, 1)
            } else {
                this.form.options.push({option: null, sms_user_id: []})
            }
        },
        setOptionInput(value, index) {
            this.form.options[index].option = value;
            this.form = {
                ...this.form
            }
        },
        photoReference(e) {
            this.file = e.target.files[0]
        },
        saveInspection() {
            this.inspection.company_id = this.user.user_company.company_id
            if (this.inspection.id) {
                this.editInspection(this.inspection).then(() => {
                    this.inspection = {}
                })
            } else {
                this.createInspections(this.inspection).then(() => {
                    this.inspection = {}
                });
            }
        },
        saveInspectionQuestion() {
            this.form.inspection_id = this.$route.params.id
            if (this.form.id) {
                this.editInspectionQuestion(this.form).then(() => {
                    this.form = {
                        question_type: {},
                        options: []
                    }
                    this.form.question_type = this.inspection_question_types[0]
                })
            } else {
                this.createInspectionQuestions(this.form).then(() => {
                    this.form = {
                        question_type: {},
                        options: []
                    }
                    this.form.question_type = this.inspection_question_types[0]

                })
            }
        },
        updateCardOrder() {
            if (this.dragging) {
                // If a card was dragged
                this.inspection_questions.data.forEach((question, index) => {
                    // Update the order based on the new index
                    question.order = index + 1;

                    this.order_form = Object.assign({}, this.order_form, question);

                    this.editInspectionQuestions(this.order_form)

                });
                this.getInspectionQuestions({
                    id: this.$route.params.id,
                    ...this.filters
                })
                ElMessage({
                    message: 'InspectionQuestion updated successfully!',
                    type: 'success',
                })
                this.dragging = false;
            }
        },
    }
}

</script>
