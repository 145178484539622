import api from "../api";
import {ElMessage} from "element-plus";

export default {
    namespaced: true,
    state: {
        modules: {data: [], current_page: 1, total: 0, per_page: 10},
        errors: {
            data: {
                errors: {
                    name: [],
                }
            }
        },
        has_errors: false,
    },
    getters: {
        modules: state => state.modules,
        errors: state => state.errors,
        has_errors: state => state.has_errors,
    },
    mutations: {
        setModules(state, data) {
            state.modules = data;
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        errors: {
                            name: [],
                        }
                    }
                };
            }
        }
    },
    actions: {
        async getModules({commit}, filters) {
            try {
                const response = await api.getModules(filters);
                commit('setModules', response);
            } catch (e) {
                console.log(e)
            }
        },
        async saveCompanyModules({dispatch}, data) {
            try {
                await api.saveCompanyModules(data);
                dispatch('getModules', {
                    paginate: true,
                    ...data
                });
                ElMessage({
                    message: 'company modules added successfully!',
                    type: "success"
                });
            } catch (e) {
                ElMessage({
                    message: e.response.data.message,
                    type: "error"
                });
            }
        },
    }
}
