<template>
    <div>
        <main>
            <section class="flex flex-row w-full h-full min-h-screen justify-center items-center">
                <div class="hidden md:flex flex-row w-1/2 px-6">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1004.72188" height="420.71758"
                         viewBox="0 0 1004.72188 420.71758" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g>
                            <path
                                d="M757.09802,180.94371V102.59987c0-7.81156,6.35475-14.16632,14.16632-14.16632h51.2514c7.81156,0,14.16632,6.35475,14.16632,14.16632v78.34384c0,7.81156-6.35475,14.16632-14.16632,14.16632h-51.2514c-7.81156,0-14.16632-6.35475-14.16632-14.16632Z"
                                fill="#56ab2f"/>
                            <rect x="783.34382" y="115.52599" width="27.09244" height="6.77311"
                                  transform="translate(1593.78007 237.82509) rotate(-180)" fill="#3f3d56"/>
                            <rect x="790.11693" y="88.43355" width="13.54622" height="22.01261"
                                  transform="translate(1593.78007 198.87971) rotate(-180)" fill="#3f3d56"/>
                            <rect x="790.11693" y="173.09742" width="13.54622" height="22.01261"
                                  transform="translate(1593.78007 368.20744) rotate(-180)" fill="#3f3d56"/>
                        </g>
                        <g>
                            <path
                                d="M720.69221,184.11002h-78.34384c-7.81156,0-14.16632-6.35475-14.16632-14.16632v-51.2514c0-7.81156,6.35475-14.16632,14.16632-14.16632h78.34384c7.81156,0,14.16632,6.35475,14.16632,14.16632v51.2514c0,7.81156-6.35475,14.16632-14.16632,14.16632Z"
                                fill="#56ab2f"/>
                            <rect x="669.66735" y="115.53229" width="27.09244" height="6.77311" fill="#3f3d56"/>
                            <rect x="632.41525" y="133.3117" width="13.54622" height="22.01261"
                                  transform="translate(783.50636 -494.87035) rotate(90)" fill="#3f3d56"/>
                            <rect x="717.07911" y="133.3117" width="13.54622" height="22.01261"
                                  transform="translate(868.17023 -579.53422) rotate(90)" fill="#3f3d56"/>
                        </g>
                        <path
                            d="M85.18205,330.61002l-13.07872-76.29253c-5.81157-33.90084,19.27967-65.27167,53.629-67.05071l186.44972-9.65676,44.49829-73.33978c5.89923-9.7228,16.44655-15.66022,27.81905-15.66022h133.96917c10.53065,0,19.42841,7.80916,20.79493,18.25077l3.4022,58.68312,334,1,42.01636,138.46769-39-1.90157,2,42-51,2-17.00105-48.07193c-3.20131-9.05198-17.22912-19.25848-31.80993-27.79778-32.68737-19.14345-74.68198-12.99928-99.41858,15.68933-5.83545,6.76774-9.91505,13.81911-11.05505,20.43115l-7.71538,44.74923-374-6-49.01636-68.56612c-14.48147-15.75924-82.84346-21.54789-89,0l-36.98364,54.56612-29.5-1.5Z"
                            fill="#e6e6e6"/>
                        <path
                            d="M491.67852,133.23624l11.00353,51.87378-140,3,29.56846-63.48522c2.68237-5.7592,8.57057-9.33482,14.9178-9.05885l67.08684,2.91682c8.49261,.36924,15.65945,6.43787,17.42337,14.75348Z"
                            fill="#fff"/>
                        <polygon
                            points="343.68205 184.11002 371.68205 112.11002 364.35134 104.77931 318.68205 174.11002 327.96158 188.11002 343.68205 184.11002"
                            fill="#fff"/>
                        <circle cx="744.68205" cy="352.11002" r="66" fill="#3f3d56"/>
                        <path
                            d="M699.18205,352.11002c0-25.08887,20.41113-45.5,45.5-45.5,25.08887,0,45.5,20.41113,45.5,45.5,0,25.08887-20.41113,45.5-45.5,45.5-25.08887,0-45.5-20.41113-45.5-45.5Zm1,0c0,24.53711,19.9624,44.5,44.5,44.5s44.5-19.96289,44.5-44.5c0-24.53711-19.9624-44.5-44.5-44.5s-44.5,19.96289-44.5,44.5Z"
                            fill="#fff"/>
                        <path
                            d="M705.68205,352.11002c0,21.53906,17.46088,39,39,39,21.53912,0,39-17.46094,39-39,0-21.53906-17.46088-39-39-39-21.53912,0-39,17.46094-39,39Zm64.35132-9.33911l-6.91003,4.50354-2.65479-4.07336,9.56482-.43018Zm-10.51978,22.90454l1.84021-4.50049,5.79425,7.62207-7.63446-3.12158Zm-17.69373,15.43457l4.86218-8.24805v8.24805h-4.86218Zm0-47.75195l4.86218-8.24805v8.24805h-4.86218Zm-21.60382,35.43896l5.79425-7.62207,1.84021,4.50049-7.63446,3.12158Zm-2.88531-26.02612l9.56482,.43018-2.65479,4.07336-6.91003-4.50354Z"
                            fill="#e6e6e6"/>
                        <circle cx="190.68205" cy="352.11002" r="66" fill="#3f3d56"/>
                        <path
                            d="M145.18205,352.11002c0-25.08887,20.41113-45.5,45.5-45.5,25.08887,0,45.5,20.41113,45.5,45.5,0,25.08887-20.41113,45.5-45.5,45.5-25.08887,0-45.5-20.41113-45.5-45.5Zm1,0c0,24.53711,19.9624,44.5,44.5,44.5,24.5376,0,44.5-19.96289,44.5-44.5,0-24.53711-19.9624-44.5-44.5-44.5-24.5376,0-44.5,19.96289-44.5,44.5Z"
                            fill="#fff"/>
                        <path
                            d="M151.68205,352.11002c0,21.53906,17.46088,39,39,39,21.53912,0,39-17.46094,39-39,0-21.53906-17.46088-39-39-39-21.53912,0-39,17.46094-39,39Zm64.35132-9.33911l-6.91003,4.50354-2.65479-4.07336,9.56482-.43018Zm-10.51978,22.90454l1.84021-4.50049,5.79425,7.62207-7.63446-3.12158Zm-17.69373,15.43457l4.86218-8.24805v8.24805h-4.86218Zm0-47.75195l4.86218-8.24805v8.24805h-4.86218Zm-21.60382,35.43896l5.79425-7.62207,1.84021,4.50049-7.63446,3.12158Zm-2.88531-26.02612l9.56482,.43018-2.65479,4.07336-6.91003-4.50354Z"
                            fill="#e6e6e6"/>
                        <rect x="466.18205" y="203.61002" width="38" height="8"
                              transform="translate(970.36411 415.22004) rotate(-180)" fill="#3f3d56"/>
                        <g>
                            <path
                                d="M1001.78797,368.65437c-.35419-1.08385-3.03868-.60669-3.29642-1.57612-.25685-.96594,2.31912-1.77589,4.29475-4.49802,.3563-.49089,2.60143-3.5844,1.74035-4.88552-1.63216-2.46625-12.98817,3.67622-15.71401,.22927-.59824-.75646-.84437-2.05513-2.62649-4.47199-.70898-.96147-1.16382-1.42564-1.70728-1.4119-.77113,.01948-1.04704,.98418-2.25903,2.73166-1.81761,2.6207-2.47353,2.29167-3.67091,4.43894-.88887,1.59402-.9942,2.61253-1.63519,2.67256-.90705,.08497-1.28185-1.89965-2.3902-1.97665-1.13101-.07854-2.26276,1.88246-2.8238,3.41457-1.05285,2.8751-.40089,5.07265-.25634,7.26272,.15706,2.38005-.21174,5.86405-2.79775,10.33581l-24.39164,38.86187c5.23835-7.92231,20.10799-29.52575,26.09893-37.44997,1.72948-2.28755,3.58802-4.58456,6.44861-5.0167,2.75409-.41608,6.10513,.9465,10.78244,.86718,.54625-.00924,2.06647-.0544,2.43625-.86016,.30573-.66614-.45336-1.24533-.17722-1.8715,.37038-.83991,2.03679-.47957,4.30777-1.03737,1.60136-.39336,2.6893-1.04324,3.62486-1.60214,.28206-.1685,4.48389-2.71375,4.01237-4.15656h-.00004Z"
                                fill="#f2f2f2"/>
                            <path
                                d="M937.90177,377.72771c-.63416-.1389-1.17451,1.31635-1.72468,1.16314-.54821-.15264-.20248-1.65064-.96847-3.40572-.13813-.3165-1.00865-2.31103-1.89572-2.2638-1.68137,.08948-1.94319,7.43538-4.42147,7.77869-.54387,.07536-1.25231-.17855-2.9527-.00121-.67646,.07056-1.03551,.15987-1.18558,.43056-.21293,.38409,.18092,.79788,.68891,1.8969,.76182,1.64824,.41106,1.87525,1.11947,3.08248,.52589,.89619,.99539,1.24177,.8399,1.57368-.22,.4697-1.30221,.08104-1.65979,.60279-.36488,.53245,.27105,1.65372,.86113,2.37114,1.10735,1.34631,2.37402,1.66038,3.49063,2.22135,1.21346,.60963,2.81703,1.7959,4.26564,4.35538l12.03589,23.18446c-2.3769-4.85686-8.68964-18.38826-10.85031-23.61505-.62375-1.50886-1.21488-3.08381-.60158-4.61251,.59043-1.47178,2.22607-2.72333,3.5367-5.04187,.15308-.27078,.56954-1.02994,.28078-1.44392-.23873-.34225-.74203-.13679-.96968-.453-.30536-.42414,.35231-1.13805,.73376-2.4136,.26898-.89945,.26391-1.62093,.25955-2.24138-.00132-.18705-.03819-2.98371-.88236-3.1686v.00006Z"
                                fill="#f2f2f2"/>
                            <path
                                d="M949.90939,383.38456l.37832-3.49289,.23204-.21944c1.07353-1.01459,1.74511-2.08947,1.99703-3.19576,.04009-.17587,.07113-.35379,.1029-.53496,.12563-.71817,.28159-1.61095,.97206-2.53825,.38642-.51664,1.40861-1.68432,2.46285-1.27646,.28454,.10665,.47659,.29111,.61001,.48669,.03349-.03397,.06781-.06778,.1045-.10451,.45539-.44254,.77396-.60465,1.08083-.76077,.2358-.12014,.47929-.24373,.89134-.5733,.18077-.1445,.32552-.27458,.44749-.38383,.36992-.33031,.84308-.71708,1.4912-.56388,.68704,.18779,.9226,.84315,1.07888,1.27702,.27909,.77389,.36506,1.30609,.42193,1.65905,.02048,.12894,.04423,.27414,.0622,.32134,.15038,.379,2.03808,.47702,2.74835,.51798,1.59658,.08663,2.97812,.1616,3.31036,1.25481,.2384,.78209-.25846,1.63511-1.51932,2.60275-.39275,.30105-.78541,.50883-1.12749,.664,.21041,.19894,.37804,.4695,.3883,.83799h0c.0231,.87225-.87152,1.54156-2.65888,1.99276-.44416,.11339-1.04159,.26343-1.82338,.22552-.36818-.01821-.6907-.07459-.96567-.12728-.041,.15667-.11618,.31955-.25171,.47414-.39781,.45538-1.04027,.57117-1.91232,.31448-.96274-.26828-1.72342-.63312-2.39463-.95383-.5863-.27931-1.09182-.51816-1.516-.58341-.78894-.11033-1.52462,.37678-2.40503,1.02507l-2.20612,1.65499h-.00002Z"
                                fill="#f2f2f2"/>
                            <path
                                d="M956.20565,363.48982l-3.47462,.52002-.27111-.16879c-1.25371-.78108-2.46376-1.15805-3.59778-1.12111-.1803,.00584-.36025,.02093-.54357,.03616-.72655,.06063-1.6297,.13624-2.7018-.29644-.59778-.24273-1.98653-.93531-1.85941-2.05852,.03099-.30227,.16071-.53484,.31606-.7135-.04138-.02377-.08278-.04838-.1276-.07456-.54357-.32826-.7812-.59529-1.01005-.85253-.17602-.19762-.35734-.40179-.78064-.71678-.18563-.13821-.34817-.24522-.48479-.33548-.41335-.27401-.90747-.63364-.92367-1.29942,.00738-.7122,.58157-1.10629,.9616-1.36752,.6778-.46625,1.17077-.68442,1.49777-.82896,.11952-.0525,.25396-.11232,.29506-.14167,.32846-.24159-.05553-2.09241-.19606-2.78985-.32117-1.56633-.5991-2.9217,.37411-3.52036,.69605-.42897,1.6472-.16474,2.903,.80945,.39082,.30354,.69141,.63066,.92828,.9222,.13906-.25398,.35825-.48479,.7121-.58814h0c.83787-.24358,1.7122,.452,2.60201,2.06645,.22234,.40087,.51902,.94071,.68064,1.70654,.07577,.36075,.10303,.68702,.12182,.96639,.16196-.00009,.33856,.03133,.52248,.12321,.54138,.26929,.81637,.86137,.78926,1.77001-.0153,.9993-.17526,1.82765-.31524,2.55826-.12147,.63798-.22429,1.18755-.17979,1.6144,.0934,.79113,.75118,1.37918,1.60159,2.06637l2.16045,1.71418h-.00009Z"
                                fill="#f2f2f2"/>
                            <path
                                d="M956.67397,353.05554l.37832-3.49289,.23204-.21944c1.07353-1.01459,1.74511-2.08947,1.99703-3.19576,.04009-.17587,.07113-.35379,.1029-.53496,.12563-.71817,.28159-1.61095,.97206-2.53825,.38642-.51664,1.40861-1.68432,2.46285-1.27646,.28454,.10665,.47659,.29111,.61001,.48669,.03349-.03397,.06781-.06778,.1045-.10451,.45539-.44254,.77396-.60465,1.08083-.76077,.2358-.12014,.47929-.24373,.89134-.5733,.18077-.1445,.32552-.27458,.44749-.38383,.36992-.33031,.84308-.71708,1.4912-.56388,.68704,.18779,.9226,.84315,1.07888,1.27702,.27909,.77389,.36506,1.30609,.42193,1.65905,.02048,.12894,.04423,.27414,.0622,.32134,.15038,.379,2.03808,.47702,2.74835,.51798,1.59658,.08663,2.97812,.1616,3.31036,1.25481,.2384,.78209-.25846,1.63511-1.51932,2.60275-.39275,.30105-.78541,.50883-1.12749,.664,.21041,.19894,.37804,.4695,.3883,.83799h0c.0231,.87225-.87152,1.54156-2.65888,1.99276-.44416,.11339-1.04159,.26343-1.82338,.22552-.36818-.01821-.6907-.07459-.96567-.12728-.041,.15667-.11618,.31955-.25171,.47414-.39781,.45538-1.04027,.57117-1.91232,.31448-.96274-.26828-1.72342-.63312-2.39463-.95383-.5863-.27931-1.09182-.51816-1.516-.58341-.78894-.11033-1.52462,.37678-2.40503,1.02507l-2.20612,1.65499h-.00002Z"
                                fill="#f2f2f2"/>
                            <path
                                d="M945.40721,419.98049l-.61431-.60685,.01109-.8629-.01109,.8629-.85388-.09027c.00408-.08028-.00153-.26809-.00778-.56139-.03285-1.60682-.13482-6.49713,.54614-14.01104,.47549-5.24516,1.27005-10.56941,2.36162-15.82744,1.0934-5.26624,2.22818-9.18461,3.13963-12.33401,.68784-2.37536,1.38211-4.62838,2.05984-6.82293,1.80866-5.86329,3.51641-11.40187,4.57396-17.71941,.23682-1.41021,.72832-4.34583-.27798-7.81072-.58387-2.00954-1.58355-3.90108-2.97231-5.62031l1.34413-1.0855c1.53244,1.89982,2.63793,3.99462,3.28636,6.22585,1.11574,3.84165,.58022,7.04039,.32292,8.5773-1.07639,6.42907-2.80118,12.02087-4.62763,17.94063-.67427,2.18666-1.36631,4.42981-2.05113,6.79549-.90321,3.12018-2.02779,7.00451-3.10696,12.20312-1.07926,5.19512-1.86364,10.45429-2.33386,15.63327-.67194,7.4192-.571,12.23674-.53795,13.81941,.01708,.84502,.02077,1.02202-.2508,1.29483v-.00002Z"
                                fill="#f2f2f2"/>
                            <path
                                d="M949.67486,340.56405c-.06911-.01541-.13867-.03265-.20832-.05338-1.41759-.39736-2.55471-1.55264-3.38069-3.43309-.3866-.88425-.47786-1.81489-.65983-3.67089-.02857-.2862-.15473-1.73418,.00037-3.65318,.10091-1.25399,.23571-1.75764,.57696-2.15309,.37896-.44058,.88952-.69289,1.42905-.81956,.01459-.16603,.06891-.32837,.17666-.48226,.44384-.63856,1.18677-.37612,1.58988-.23958,.2031,.07294,.45619,.16566,.74088,.2136,.44739,.07734,.71454-.00128,1.11892-.11836,.38693-.11234,.86804-.25204,1.53301-.24018,1.31219,.01803,2.28353,.60778,2.60385,.80185,1.68691,1.01362,2.26934,2.63075,2.94336,4.5032,.13393,.37533,.57841,1.73194,.68191,3.47889,.07472,1.26033-.09043,1.77624-.2798,2.1451-.38679,.75839-.97906,1.10993-2.44902,1.84956-1.53571,.77504-2.30622,1.16285-2.93798,1.36221-1.47009,.4615-2.39322,.75133-3.47921,.50911v.00004Z"
                                fill="#f2f2f2"/>
                        </g>
                        <path
                            d="M0,418.48037c0,.66003,.92528,1.19,2.07751,1.19H993.84817c1.15213,0,2.07741-.52997,2.07741-1.19,0-.65997-.92528-1.19-2.07741-1.19H2.07751c-1.15223,0-2.07751,.53003-2.07751,1.19Z"
                            fill="#ccc"/>
                        <g>
                            <g>
                                <polygon
                                    points="503.94039 410.96659 517.82504 410.96525 524.43008 357.40955 503.93763 357.41097 503.94039 410.96659"
                                    fill="#ffb6b6"/>
                                <path
                                    d="M551.51096,410.65165h0c.43232,.72811,.66043,3.07811,.66043,3.92484h0c0,2.60262-2.10981,4.71251-4.71251,4.7125l-42.99916-.00002c-1.77549,0-3.21489-1.43932-3.21489-3.21489v-1.79019s-2.12712-5.38046,2.25226-12.01217c0,0,5.44298,5.19273,13.57619-2.94047l2.39841-4.3449,17.36119,12.69711,9.62305,1.18451c2.10533,.25912,3.9719-.04021,5.05485,1.78369h.00017Z"
                                    fill="#2f2e41"/>
                            </g>
                            <g>
                                <polygon
                                    points="441.55781 410.96659 455.44246 410.96525 462.0475 357.40955 441.55505 357.41097 441.55781 410.96659"
                                    fill="#ffb6b6"/>
                                <path
                                    d="M489.12838,410.65165h0c.43232,.72811,.66043,3.07811,.66043,3.92484h0c0,2.60262-2.10981,4.71251-4.71251,4.7125l-42.99916-.00002c-1.77549,0-3.21489-1.43932-3.21489-3.21489v-1.79019s-2.12712-5.38046,2.25226-12.01217c0,0,5.44298,5.19273,13.57619-2.94047l2.39841-4.3449,17.36119,12.69711,9.62305,1.18451c2.10533,.25912,3.9719-.04021,5.05485,1.78369h.00017Z"
                                    fill="#2f2e41"/>
                            </g>
                            <polygon
                                points="501.74142 45.27554 504.3773 69.87713 473.62533 67.24124 478.89709 44.39691 501.74142 45.27554"
                                fill="#ffb6b6"/>
                            <polygon
                                points="501.74142 45.27554 504.3773 69.87713 473.62533 67.24124 478.89709 44.39691 501.74142 45.27554"
                                isolation="isolate" opacity=".1"/>
                            <circle cx="490.75857" cy="28.1423" r="20.20844" fill="#ffb6b6"/>
                            <polygon
                                points="503.93799 59.7729 537.76517 79.54203 526.682 133.13834 534.25066 205.18583 528.97889 381.79005 496.46965 378.27554 492.07651 228.03016 466.5963 383.54731 435.02796 383.54731 440.23746 215.72937 456.05277 144.5605 446.38786 67.24124 475.8219 58.01565 503.93799 59.7729"
                                fill="#2f2e41"/>
                            <path
                                d="M505.25593,33.85338s1.75726-6.1504,5.71108-5.71108,4.83245-3.0752,3.95383-4.83245-2.63588-10.54354-2.63588-10.54354c0,0,.87863-6.1504-4.39314-7.02902-5.27177-.87863-7.02902-1.75726-7.90765-3.51451-.87863-1.75726-14.05805-3.51451-18.45119-.87863-4.39314,2.63588-7.90765,7.69318-10.98285,9.11836-3.0752,1.42518-5.71108,4.06106-3.95383,8.4542,1.75726,4.39314,4.30578,12.98518,4.30578,12.98518,0,0,4.4805-1.56302,5.35913,1.07287s-2.63588-.87863,1.75726-7.90765c4.39314-7.02902,4.39314-14.05805,11.42216-11.42216,7.02902,2.63588,14.93668,1.75726,14.05805,7.02902-.87863,5.27177,1.75726,13.17942,1.75726,13.17942Z"
                                fill="#2f2e41"/>
                            <path id="uuid-2f48dadd-bd50-474e-b6bc-efb589732da4-18"
                                  d="M520.32128,189.42435c-4.22916,4.47794-5.34674,10.28982-2.4965,12.98081,2.85023,2.69099,8.5881,1.24194,12.81816-3.23816,1.71428-1.76644,2.98062-3.91773,3.69303-6.2739l17.6795-19.19952-9.18661-8.10443-16.45353,19.78776c-2.31231,.84629-4.38826,2.23416-6.05406,4.04744Z"
                                  fill="#ffb6b6"/>
                            <polygon
                                points="537.40932 79.10272 567.18163 129.82591 571.59234 152.98215 542.92271 189.37053 530.79325 173.93304 545.12807 152.98215 521.97183 112.18306 537.40932 79.10272"
                                fill="#2f2e41"/>
                            <g>
                                <path
                                    d="M453.70114,184.70132v-59.80533c0-9.11532,7.41537-16.53069,16.53069-16.53069h91.41953c9.11532,0,16.53069,7.41537,16.53069,16.53069v59.80533c0,9.11532-7.41537,16.53069-16.53069,16.53069h-91.41953c-9.11532,0-16.53069-7.41537-16.53069-16.53069Z"
                                    fill="#56ab2f"/>
                                <rect x="498.15861" y="138.20857" width="31.6142" height="7.90355"
                                      transform="translate(1027.93142 284.32068) rotate(180)" fill="#3f3d56"/>
                                <rect x="506.43524" y="179.95538" width="15.8071" height="25.68654"
                                      transform="translate(707.13744 -321.54013) rotate(90)" fill="#3f3d56"/>
                                <rect x="507.64086" y="103.95538" width="15.8071" height="25.68654"
                                      transform="translate(632.34306 -398.74576) rotate(90)" fill="#3f3d56"/>
                            </g>
                            <g>
                                <path id="uuid-f6ce86b9-37cf-4878-a476-c93c5a2c7b27-19"
                                      d="M462.95311,178.00219c4.22916,4.47794,5.34674,10.28982,2.4965,12.98081-2.85023,2.69099-8.5881,1.24194-12.81816-3.23816-1.71428-1.76644-2.98062-3.91773-3.69303-6.2739l-17.6795-19.19952,9.18661-8.10443,16.45353,19.78776c2.31231,.84629,4.38826,2.23416,6.05406,4.04744Z"
                                      fill="#ffb6b6"/>
                                <polygon
                                    points="445.86507 67.68056 416.09277 118.40375 411.68205 141.55999 440.35168 177.94837 452.48114 162.51087 438.14633 141.55999 461.30257 100.7609 445.86507 67.68056"
                                    fill="#2f2e41"/>
                            </g>
                        </g>
                        <ellipse cx="75.6657" cy="286.04391" rx="27" ry="23.5" fill="#e6e6e6"/>
                    </svg>
                </div>
                <div class="flex flex-row md:w-1/2 w-full">
                    <router-view/>
                </div>
            </section>
        </main>
    </div>
</template>
<script>

import registerBg2 from "@/assets/img/register_bg_2.png";

export default {
    data() {
        return {
            registerBg2,
        };
    },
};
</script>
