import client from "./client";

export default {
    async getInspectionQuestionTypes(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/inspection-question-types', filters)));
    },

    async createInspectionQuestionTypes(data) {
        return client.parseResponse(await client.post('/inspection-question-types', data))
    },

    async getInspectionQuestionType(id) {
        return client.parseResponse(await client.get('/inspection-question-types/' + id));
    },

    async editInspectionQuestionType(data) {
        return client.parseResponse(await client.put('/inspection-question-types/' + data.id, data))
    },

    async deleteInspectionQuestionType(payload) {
        return client.parseResponse(await client.delete('/inspection-question-types/' + payload.id));
    },

    async getInspectionQuestionCategories(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/inspection-question-categories', filters)));
    },

}
