import api from "../api";
import {ElMessage} from 'element-plus'

export default {
    namespaced: true,
    state: {
        tyres: {data: [], current_page: 1, total: 0, per_page: 10},
        errors: {
            data: {
                web_errors: {
                    tyre_serial_number: [],
                    tyre_size_id: [],
                    tyre_model_id: [],
                    tyre_weight: [],
                    tyre_width: [],
                    tyre_brand_id: [],
                    tyre_tread_pattern_id: [],
                    tread_depth: [],
                    tyre_specification_id: [],
                    tyre_vendor_id: [],
                    tyre_purchase_date: [],
                    tyre_warranty_period: [],
                    tyre_warranty_expire_date: [],
                    tyre_warranty_kms: [],
                    max_psi: [],
                    recom_psi: [],
                    company_id: [],
                    bel_initial:[],
                    manufacture_date:[]
                }
            }
        },
        has_errors: false,
        addTyreDialogVisible: false
    },
    getters: {
        addTyreDialogVisible: state => state.addTyreDialogVisible,
        tyres: state => state.tyres,
        errors: state => state.errors,
        has_errors: state => state.has_errors,
    },
    mutations: {
        setAddTyreDialogVisible(state, data) {
            state.addTyreDialogVisible = data;
        },
        setTyres(state, data) {
            state.tyres = data;
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        web_errors: {
                            tyre_serial_number: [],
                            tyre_size_id: [],
                            tyre_model_id: [],
                            tyre_weight: [],
                            tyre_width: [],
                            tyre_brand_id: [],
                            tyre_tread_pattern_id: [],
                            tread_depth: [],
                            tyre_specification_id: [],
                            tyre_vendor_id: [],
                            tyre_purchase_date: [],
                            tyre_warranty_period: [],
                            tyre_warranty_expire_date: [],
                            tyre_warranty_kms: [],
                            max_psi: [],
                            recom_psi: [],
                            company_id: [],
                            manufacture_date:[]
                        }
                    }
                };
            }
        }
    },
    actions: {
        openAddTyreDialogVisible({commit}, status) {
            commit('setAddTyreDialogVisible', status);
        },
        async getTyres({commit}, filters) {
            try {
                const response = await api.getTyres(filters);
                commit('setTyres', response);
            } catch (e) {
                console.log(e)
            }
        },

        async createTyres({commit, dispatch}, data) {
            try {
                commit('setLoading', true)
                commit('hasErrors', false)
                await api.createTyres(data);
                dispatch('getTyres', {
                    paginate: true,
                    id: data.company_id
                })
                commit('setLoading', false)
                commit('setAddUserDialogVisible', false)
                ElMessage({
                    message: 'Tyre created successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error creating Tyre!',
                    type: 'error',
                })
            }
        },
        async editTyre({dispatch,commit}, data) {
            try {
                await api.editTyre(data);
                dispatch('getTyres', {
                    paginate: true,
                    id: data.company_id
                });
                commit('setAddUserDialogVisible', false)
                ElMessage({
                    message: 'Tyre updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: 'Error updating Tyre',
                    type: 'error',
                })
            }
        },
        async deleteTyre({dispatch}, payload) {
            try {
                const {message} = await api.deleteTyre(payload)
                dispatch('getTyres', {
                    paginate: true,
                    id: payload.company_id
                })
                ElMessage({
                    message: message,
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            }
        },
        async uploadTyres(context, payload) {
            try {
                await api.uploadTyres(payload)
                ElMessage({
                    message: 'uploaded tyres successfully',
                    type: 'success',
                })
            } catch (e) {
                console.log(e.response.data)
                ElMessage({
                    message: e.response.data.errors,
                    type: 'error',
                    duration:100000
                })
            }
        },
        async exportTyres(context, filters) {
            try {
                const response = await api.exportTyres(filters);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data]));
                link.setAttribute('download', 'tyres ' + Date.now() + '.xlsx');
                document.body.appendChild(link);
                link.click();
            } catch (e) {
                ElMessage({
                    message: 'Error exporting!',
                    type: 'error',
                })
            }
        },

    }
}
