import client from "./client";

export default {
    async getInspectionQuestions(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/inspection-questions', filters)));
    },

    async createInspectionQuestions(data) {
        return client.parseResponse(await client.post('/inspection-questions', data))
    },

    async getInspectionQuestion(id) {
        return client.parseResponse(await client.get('/inspection-questions/' + id));
    },

    async editInspectionQuestion(data) {
        return client.parseResponse(await client.put('/inspection-questions/' + data.id, data))
    },

    async deleteInspectionQuestion(payload) {
        return client.parseResponse(await client.delete('/inspection-questions/' + payload.id));
    },

    async getInspectionDropdowns(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/inspection-dropdowns', filters)));
    },

}
