import client from "./client";

export default {
    async getCompanyRoutes(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/company-routes', filters)));
    },

    async createCompanyRoutes(data) {
        return client.parseResponse(await client.post('/company-routes', data))
    },

    async getCompanyRoute(id) {
        return client.parseResponse(await client.get('/company-routes/' + id));
    },

    async editCompanyRoute(data) {
        return client.parseResponse(await client.put('/company-routes/' + data.id, data))
    },

    async deleteCompanyRoute(payload) {
        return client.parseResponse(await client.delete('/company-routes/' + payload.id));
    },

    async uploadCompanyRoutes(payload) {
        return client.parseResponse(await client.upload('/import-company-routes', payload, payload.file))
    },

    async exportCompanyRoutes(filters) {
        return await client.get('/export-company-routes', filters, {responseType: 'blob'});
    },
}
