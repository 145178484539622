import api from "../api";
import {ElMessage} from 'element-plus'

export default {
    namespaced: true,
    state: {
        incentives: {data: [], current_page: 1, total: 0, per_page: 10},
        inspection_incentives: {data: [], current_page: 1, total: 0, per_page: 10},
        user_incentives: {data: [], current_page: 1, total: 0, per_page: 10},
        incentive:{},
        errors: {
            data: {
                errors: {
                    name: [],
                }
            }
        },
        has_errors: false,
    },
    getters: {
        incentives: state => state.incentives,
        incentive: state => state.incentive,
        inspection_incentives: state => state.inspection_incentives,
        user_incentives: state => state.user_incentives,
        errors: state => state.errors,
        has_errors: state => state.has_errors,
    },
    mutations: {
        setIncentives(state, data) {
            state.incentives = data;
        },
        setIncentive(state, data) {
            state.incentive = data;
        },
        setInspectionIncentives(state, data) {
            state.inspection_incentives = data;
        },
        setUserIncentives(state, data) {
            state.user_incentives = data;
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        errors: {
                            name: [],
                        }
                    }
                };
            }
        }
    },
    actions: {
        async getIncentives({commit}, filters) {
            try {
                const response = await api.getIncentives(filters);
                commit('setIncentives', response);
            } catch (e) {
                console.log(e)
            }
        },

        async createIncentives({commit, dispatch}, data) {
            try {
                commit('hasErrors', false)
                await api.createIncentives(data);
                dispatch('getIncentives', {
                    paginate: true
                })

                ElMessage({
                    message: 'Incentive created successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error creating Incentive!',
                    type: 'error',
                })
            }
        },
        async getIncentive({commit}, id) {
            try {
                const response = await api.getIncentive(id);
                commit('setIncentive', response);
            } catch (e) {
                console.log(e)
            }
        },
        async editIncentive({dispatch}, data) {
            try {
                await api.editIncentive(data);
                dispatch('getIncentives', {
                    paginate: true
                });
                ElMessage({
                    message: 'Incentive updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: 'Error updating Incentive',
                    type: 'error',
                })
            }
        },
        async deleteIncentive({dispatch}, payload) {
            try {
                const {message} = await api.deleteIncentive(payload)
                dispatch('getIncentives', {})
                ElMessage({
                    message: message,
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            }
        },




        async getInspectionIncentives({commit}, filters) {
            try {
                const response = await api.getInspectionIncentives(filters);
                commit('setInspectionIncentives', response);
            } catch (e) {
                console.log(e)
            }
        },

        async createInspectionIncentives({commit, dispatch}, data) {
            try {
                commit('hasErrors', false)
                await api.createInspectionIncentives(data);
                dispatch('getInspectionIncentives', {
                    paginate: true
                })

                ElMessage({
                    message: 'Incentive created successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error creating Incentive!',
                    type: 'error',
                })
            }
        },
        async editInspectionIncentive({dispatch}, data) {
            try {
                await api.editInspectionIncentive(data);
                dispatch('getInspectionIncentives', {
                    paginate: true
                });
                ElMessage({
                    message: 'Incentive updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: 'Error updating Incentive',
                    type: 'error',
                })
            }
        },
        async deleteInspectionIncentive({dispatch}, payload) {
            try {
                const {message} = await api.deleteInspectionIncentive(payload)
                dispatch('getInspectionIncentives', {})
                ElMessage({
                    message: message,
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            }
        },



        async getUserIncentives({commit}, filters) {
            try {
                const response = await api.getUserIncentives(filters);
                commit('setUserIncentives', response);
            } catch (e) {
                console.log(e)
            }
        },
        async authorizeIncentive({dispatch}, data) {
            try {
                await api.authorizeIncentive(data);
                dispatch('getUserIncentives', {
                    paginate: true
                });
                ElMessage({
                    message: 'Incentive status updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: 'Error updating Incentive',
                    type: 'error',
                })
            }
        },
    }
}
