<template>
    <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded border bg-white">

        <div class="rounded-t mb-0 px-4 py-5 border-b">
            <div class="flex flex-wrap items-center">
                <div class="md:flex-row flex-col relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-lg">
                        CompanyTowns
                    </h3>
                    <div class="flex flex-row justify-end">
                        <button class="bg-secondary text-white px-10 py-2 font-semibold rounded-md"
                                @click="addCompanyTownDialogVisible = true">Add CompanyTown
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!--    Filters and Search-->
        <div
            class="flex lg:flex-row flex-col justify-between md:items-start md:space-y-0 space-y-2 md:space-x-4 space-x-0 py-8 px-6 border-b">
            <div class="flex flex-row justify-start items-end mb-3 space-x-4">
                <!--        Export and Import-->
                <div class="flex flex-row">
                    <el-button-group>
                        <el-button @click="exportCompanyTowns" type="success" plain>
                            <span class="mr-3">Export</span>
                            <el-icon>
                                <Download/>
                            </el-icon>
                        </el-button>
                        <el-button @click="uploadCompanyTownDialogVisible=!uploadCompanyTownDialogVisible"
                                   type="warning" size="default"
                                   plain>
                            <span class="mr-3">Import</span>
                            <el-icon>
                                <Upload/>
                            </el-icon>
                        </el-button>
                    </el-button-group>
                </div>
            </div>
            <form>
                <div class="relative">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" class="w-5 h-5 mr-3">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                        </svg>
                    </div>
                    <input v-model="search"
                           class="block outline-none w-full px-12 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-secondary focus:border-secondary"
                           placeholder="Search Towns ...">
                </div>
            </form>
        </div>

        <div class="block w-full overflow-x-auto">

            <!--Import dialog-->
            <el-dialog
                v-model="uploadCompanyTownDialogVisible"
                title="Upload CompanyTowns"
                :width="windowWidth > 768 ? '40%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <div class="flex flex-row items-center w-full rounded mb-4">
                        <a class="py-2 px-6 text-sky-600"
                           href="/templates/towns_template.csv" download="">
                            <font-awesome-icon class="mr-2 text-sm"
                                               :icon="['fas', 'download']"/>
                            Please click here to download the towns template.
                        </a>
                    </div>
                    <form class="px-4">
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">File (.xls or .xlsx or .csv)<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row border p-2 rounded-md w-full">
                                    <input
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        class="outline-none w-full" @change="companyFile" type="file" required/>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="upload">
                            Upload
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="uploadCompanyTownDialogVisible = false">Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!-- Add/Edit dialog-->
            <el-dialog
                v-model="addCompanyTownDialogVisible"
                title="Add/Edit CompanyTowns"
                :width="windowWidth > 768 ? '30%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <form class="px-4">
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Towns</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="town.town_id" placeholder="Select town"
                                        size="default">
                                        <el-option
                                            v-for="item in towns"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="addCompanyTown">
                            Save
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="addCompanyTownDialogVisible = false">Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!--Table-->
            <div class="py-8 px-4">
                <el-table class="" :data="company_towns.data" stripe size="large" style="width: 100%">
                    <el-table-column prop="town.name" label="NAME"/>
                    <el-table-column label="ACTIONS">
                        <template #default="scope">
                            <el-dropdown size="large">
                                <el-button>
                                    Actions
                                    <el-icon class="el-icon--right">
                                        <arrow-down/>
                                    </el-icon>
                                </el-button>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item @click.native="updateCompanyTown(scope.row)">EDIT
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="deleteCompanyTown(scope.row)">DELETE
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="flex flex-row p-5 w-full justify-end">
                <el-pagination
                    :current-page="towns.current_page"
                    :page-size="towns.per_page"
                    :page-sizes="[5,10,20,50]"
                    :small=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="towns.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </div>

    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {ArrowDown, Upload, Download} from '@element-plus/icons-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
    data() {
        return {
            search: '',
            uploadCompanyTownDialogVisible: false,
            addCompanyTownDialogVisible: false,
            windowWidth: window.innerWidth,
            form: {},
            town: {},
            user: {},
            filters: {
                paginate: true,
                per_page: 10
            },
        };
    },
    components: {
        ArrowDown, Upload, Download
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.getCompanyTownsList({
            ...this.filters,
            id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
        })
        this.getCompanies(this.filters)
        this.getTowns({})
    },
    computed: {
        models() {
            var model_data = this.towns.data
            return model_data?.map(function (item) {
                return item.model;
            }).filter(
                (item, index, arr) => {
                    return arr.indexOf(item) === index
                }
            );
        },
        ...mapGetters({
            towns: 'towns/towns',
            companies: 'companies/companies',
            company_towns: 'towns/company_towns'
        })
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.getCompanyTownsList(this.filters)
            }
        },
        search: function (val) {
            this.getCompanyTownsList({
                ...this.filters,
                search: val
            });
        }
    },
    methods: {
        ...mapActions({
            getCompanyTownsList: 'towns/getCompanyTowns',
            createCompanyTowns: 'towns/createCompanyTowns',
            editCompanyTown: 'towns/editCompanyTown',
            getCompanies: 'companies/getCompanies',
            getTowns: 'towns/getTowns',
            downloadCompanyTowns: 'towns/exportCompanyTowns',
            uploadCompanyTowns: 'towns/uploadCompanyTowns'
        }),
        handleSizeChange(size) {
            this.filters.per_page = size;
            this.getCompanyTownsList({paginate: true, ...this.filters});
        },
        handleCurrentChange(page) {
            this.filters.page = page;
            this.getCompanyTownsList({paginate: true, ...this.filters});
        },
        addCompanyTown() {
            if (this.town.id) {
                this.town.company_id = this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
                this.editCompanyTown(this.town).then(() => {
                    if (!this.has_errors) {
                        this.town = {}
                        this.addCompanyTownDialogVisible = false
                    }
                })
            } else {
                this.town.company_id = this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
                this.createCompanyTowns(this.town).then(() => {
                    if (!this.has_errors) {
                        this.town = {}
                        this.addCompanyTownDialogVisible = false
                    }
                });
            }
        },
        updateCompanyTown(item) {
            this.addCompanyTownDialogVisible = true
            this.form = Object.assign({}, this.form, item.town);
        },
        deleteCompanyTown() {

        },
        upload() {
            this.town.file = this.file;
            this.town.company_id = this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id;
            this.uploadCompanyTowns(this.town)
                .then(() => {
                    this.getCompanyTownsList({
                        ...this.filters,
                        id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
                    })
                    this.uploadCompanyTownDialogVisible = false
                    this.$message.success('CompanyTowns uploaded successfully!')
                }).catch((e) => {
                this.$message.error(e.response.data.message)
            })
        },
        companyFile(e) {
            this.file = e.target.files[0]
        },
        exportCompanyTowns() {
            this.downloadCompanyTowns({...this.filters})
        }

    }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: transparent;
    display: flex;
    align-items: center;
}
</style>
