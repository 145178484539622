<template>
    <div class="container mx-auto px-4 pt-2 h-full">
        <div class="flex content-center items-center justify-center h-full">
            <div class="w-full md:px-16 px-4">
                <div
                    class="relative flex flex-col min-w-0 break-words w-full mb-6 border rounded-lg bg-white">
                    <div class="rounded-t mb-0 px-6 py-6">
                        <div class="text-center mb-3">
                            <h6 class="text-slate-500 text-sm font-bold">
                                Sign up with
                            </h6>
                        </div>
                        <div class="btn-wrapper text-center">
                            <button
                                class="bg-white active:bg-slate-50 text-slate-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center text-xs ease-linear transition-all duration-150"
                                type="button"
                                @click="socialLogin('Facebook')"
                            >
                                <img alt="..." class="w-5 mr-1" :src="facebook"/>
                                Facebook
                            </button>
                            <button
                                class="bg-white active:bg-slate-50 text-slate-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center text-xs ease-linear transition-all duration-150"
                                type="button"
                                @click="socialLogin('Facebook')"
                            >
                                <img alt="..." class="w-5 mr-1" :src="google"/>
                                Google
                            </button>
                        </div>
                        <hr class="mt-6 border-b-1 border-slate-300"/>
                    </div>
                    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                        <div class="text-slate-400 text-center mb-3 font-bold">
                            <small>Or sign up with credentials</small>
                        </div>
                        <form @submit.prevent="registerUser">
                            <div class="relative w-full mb-3">
                                <label
                                    class="block uppercase text-slate-600 text-xs font-bold mb-2"
                                >
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-secondary focus:border-secondary block w-full px-3 py-2 outline-none"
                                    placeholder="First Name"
                                    v-model="form.first_name"
                                />
                                <span class="text-red-500" v-if="errors.data.web_errors.first_name  && this.has_errors">
                                    {{ errors.data.web_errors.first_name[0] }}
                                </span>
                            </div>

                            <div class="relative w-full mb-3">
                                <label
                                    class="block uppercase text-slate-600 text-xs font-bold mb-2"
                                >
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-secondary focus:border-secondary block w-full px-3 py-2 outline-none"
                                    placeholder="Last Name"
                                    v-model="form.last_name"
                                />
                                <span class="text-red-500" v-if="errors.data.web_errors.last_name  && this.has_errors">
                                    {{ errors.data.web_errors.last_name[0] }}
                                </span>
                            </div>

                            <div class="relative w-full mb-3">
                                <label
                                    class="block uppercase text-slate-600 text-xs font-bold mb-2"
                                >
                                    Email
                                </label>
                                <input
                                    type="email"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-secondary focus:border-secondary block w-full px-3 py-2 outline-none"
                                    placeholder="Email"
                                    v-model="form.email"
                                />
                                <span class="text-red-500" v-if="errors.data.web_errors.email  && this.has_errors">
                                    {{ errors.data.web_errors.email[0] }}
                                </span>
                            </div>

                            <div class="relative w-full mb-3">
                                <label
                                    class="block uppercase text-slate-600 text-xs font-bold mb-2"
                                >
                                    Company Code
                                </label>
                                <input
                                    type="text"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-secondary focus:border-secondary block w-full px-3 py-2 outline-none"
                                    placeholder="Company Code"
                                    v-model="form.company_code"
                                />
                                <span class="text-red-500"
                                      v-if="errors.data.web_errors.company_code  && this.has_errors">
                                    {{ errors.data.web_errors.company_code[0] }}
                                </span>
                            </div>

                            <div class="relative w-full mb-3">
                                <label
                                    class="block uppercase text-slate-600 text-xs font-bold mb-2"
                                >
                                    Phone Number
                                </label>
                                <MazPhoneNumberInput
                                    v-model="phone"
                                    default-country-code="KE"
                                    :only-countries="['KE','TZ','UG','RW','BW','ZA','NA','ZW']"
                                    @update="emitInput"
                                />
                                <span class="text-red-500"
                                      v-if="errors.data.web_errors.phone_number  && this.has_errors">
                                    {{ errors.data.web_errors.phone_number[0] }}
                                </span>
                            </div>

                            <div class="relative w-full mb-3">
                                <label
                                    class="block uppercase text-slate-600 text-xs font-bold mb-2"
                                >
                                    Address One
                                </label>
                                <input
                                    type="text"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-secondary focus:border-secondary block w-full px-3 py-2 outline-none"
                                    placeholder="Address One"
                                    v-model="form.address_one"
                                />
                                <span class="text-red-500"
                                      v-if="errors.data.web_errors.address_one  && this.has_errors">
                                    {{ errors.data.web_errors.address_one[0] }}
                                </span>
                            </div>

                            <div class="relative w-full mb-3">
                                <label
                                    class="block uppercase text-slate-600 text-xs font-bold mb-2"
                                >
                                    Address Two
                                </label>
                                <input
                                    type="text"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-secondary focus:border-secondary block w-full px-3 py-2 outline-none"
                                    placeholder="Address Two"
                                    v-model="form.address_two"
                                />
                                <span class="text-red-500"
                                      v-if="errors.data.web_errors.address_two  && this.has_errors">
                                    {{ errors.data.web_errors.address_two[0] }}
                                </span>
                            </div>

                            <div class="relative w-full mb-3">
                                <label
                                    class="block uppercase text-slate-600 text-xs font-bold mb-2"
                                >
                                    Password
                                </label>
                                <input
                                    type="password"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-secondary focus:border-secondary block w-full px-3 py-2 outline-none"
                                    placeholder="Password"
                                    v-model="form.password"
                                />
                                <span class="text-red-500" v-if="errors.data.web_errors.password  && this.has_errors">
                                    {{ errors.data.web_errors.password[0] }}
                                </span>
                            </div>
                            <div class="relative w-full mb-3">
                                <label
                                    class="block uppercase text-slate-600 text-xs font-bold mb-2"
                                >
                                    Password Confirmation
                                </label>
                                <input
                                    type="password"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-secondary focus:border-secondary block w-full px-3 py-2 outline-none"
                                    placeholder="Password"
                                    v-model="form.password_confirmation"
                                />
                                <span class="text-red-500"
                                      v-if="errors.data.web_errors.password_confirmation  && this.has_errors">
                                    {{ errors.data.web_errors.password_confirmation[0] }}
                                </span>
                            </div>

                            <div class="text-center mt-6">
                                <button
                                    class="text-white bg-secondary hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm sm:w-auto px-6 py-3 text-center" type="submit">
                                    Create Account
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="flex flex-wrap mt-6 relative justify-end">

                    <div class="md:w-1/2 w-full text-right">
                        <router-link to="/login" class="text-slate-500">
                            <small>Already have an account? <span class="text-red-500">Login</span></small>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import facebook from "@/assets/img/facebook.svg";
import google from "@/assets/img/google.svg";
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import {mapActions, mapGetters} from 'vuex'

export default {
    data() {
        return {
            facebook,
            google,
            form: {}
        }
    },
    components: {
        MazPhoneNumberInput
    },
    computed: {
        ...mapGetters({
            errors: 'auth/errors',
            has_errors: 'auth/has_errors',
        })
    },
    methods: {
        ...mapActions({
            'register': 'auth/registerUser',
            'socialLogins': 'auth/socialLogin',
        }),

        emitInput(input) {
            if (input.isValid) {
                this.form.phone_number = input.nationalNumber
                this.form.country_code = input.countryCallingCode
            } else {
                this.form.phone_number = null;
                this.form.country_code = null;

            }
        },

        async registerUser() {
            await this.register(this.form)
        },
    },
};
</script>
