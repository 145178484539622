import client from "./client";

export default {
    async getRoutes(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/routes', filters)));
    },

    async createRoutes(data) {
        return client.parseResponse(await client.post('/routes', data))
    },

    async getRoute(id) {
        return client.parseResponse(await client.get('/routes/' + id));
    },

    async editRoute(data) {
        return client.parseResponse(await client.put('/routes/' + data.id, data))
    },

    async deleteRoute(payload) {
        return client.parseResponse(await client.delete('/manufactures/' + payload.id));
    },

    async uploadRoutes(payload) {
        return client.parseResponse(await client.upload('/import-routes', payload, payload.file))
    },

    async exportRoutes(filters) {
        return await client.get('/export-routes', filters, {responseType: 'blob'});
    },
}
