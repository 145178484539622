import client from "./client";

export default {
    async getTyreBrands(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/tyre-brands', filters)));
    },

    async createTyreBrands(data) {
        return client.parseResponse(await client.post('/tyre-brands', data))
    },

    async getTyreBrand(id) {
        return client.parseResponse(await client.get('/tyre-brands/' + id));
    },

    async editTyreBrand(data) {
        return client.parseResponse(await client.put('/tyre-brands/' + data.id, data))
    },

    async deleteTyreBrand(payload) {
        return client.parseResponse(await client.delete('/tyre-brands/' + payload.id));
    },

    async uploadTyreBrands(payload) {
        return client.parseResponse(await client.upload('/import-tyre-brands', payload, payload.file))
    },

    async exportTyreBrands(filters) {
        return await client.get('/export-tyre-brands', filters, {responseType: 'blob'});
    },
}
