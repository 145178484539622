import client from "./client";

export default {
    async getTyreTreadPatterns(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/tyre-tread-patterns', filters)));
    },

    async createTyreTreadPatterns(data) {
        return client.parseResponse(await client.post('/tyre-tread-patterns', data))
    },

    async getTyreTreadPattern(id) {
        return client.parseResponse(await client.get('/tyre-tread-patterns/' + id));
    },

    async editTyreTreadPattern(data) {
        return client.parseResponse(await client.put('/tyre-tread-patterns/' + data.id, data))
    },

    async deleteTyreTreadPattern(payload) {
        return client.parseResponse(await client.delete('/tyre-tread-patterns/' + payload.id));
    },

    async uploadTyreTreadPatterns(payload) {
        return client.parseResponse(await client.upload('/import-tyre-tread-patterns', payload, payload.file))
    },

    async exportTyreTreadPatterns(filters) {
        return await client.get('/export-tyre-tread-patterns', filters, {responseType: 'blob'});
    },
}
