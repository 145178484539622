<template>
  <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded border bg-white">

    <div class="rounded-t mb-0 px-4 py-5 border-b">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-lg">
            Fleets
          </h3>
          <button class="bg-red-700 text-white px-4 py-2 font-semibold rounded-md"
                  @click="addFleetDialogVisible = true">Add Fleet
          </button>
        </div>
      </div>
    </div>

    <!--    Filters and Search-->
    <div class="flex flex-col py-5 text-gray-100 border-b px-4">
      <div class="flex flex-row justify-start items-end mb-3 space-x-4">
        <!--        Export and Import-->
        <div class="flex flex-row">
          <el-button-group>
            <el-button @click="exportFleets" type="success" plain>
              <span class="mr-3">Export</span>
              <el-icon>
                <Download/>
              </el-icon>
            </el-button>
            <el-button @click="uploadFleetDialogVisible=!uploadFleetDialogVisible" type="warning"
                       size="default"
                       plain>
              <span class="mr-3">Import</span>
              <el-icon>
                <Upload/>
              </el-icon>
            </el-button>
          </el-button-group>
        </div>
      </div>

      <div class="flex flex-row justify-end">
        <!--      search-->
        <div class="flex flex-row justify-center items-center rounded-md border border-[#e5e7eb] p-1 mr-2">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-5 h-5 mr-3">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
            </svg>
          </div>
          <input v-model="search" placeholder="Search here ..."
                 class="w-full outline-none px-5"/>
        </div>
      </div>
    </div>

    <div class="block w-full overflow-x-auto">

      <!--Import dialog-->
      <el-dialog
          v-model="uploadFleetDialogVisible"
          title="Upload Fleets"
          :width="windowWidth > 768 ? '40%' : '90%'">
        <div class="flex flex-col gap-y-2">
          <div class="flex flex-row items-center w-full rounded mb-4">
            <a class="py-2 px-6 text-sky-600"
               href="/templates/fleets_template.csv" download="">
                <font-awesome-icon class="mr-2 text-sm"
                                   :icon="['fas', 'download']" />
              Please click here to download the fleets template.
            </a>
          </div>
          <form class="px-4">
            <div class="flex flex-col items-start justify-start mb-3 w-full">
              <p class="text-md font-bold text-sm lg:text-md mb-3">File (.xls or .xlsx or .csv)<span
                  class="text-red-500 ml-2">*</span></p>
              <div class="flex flex-row items-start justify-start w-full mb-1">
                <div class="flex flex-row border p-2 rounded-md w-full">
                  <input
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      class="outline-none w-full" @change="companyFile" type="file" required/>
                </div>
              </div>
            </div>
          </form>
          <div class="dialog-footer flex flex-row w-full justify-end px-4">
            <el-button class="bg-sky-600 text-white" @click="upload">
              Upload
            </el-button>
            <el-button class="bg-red-700 text-white" type="danger" @click="uploadFleetDialogVisible = false">
              Cancel
            </el-button>
          </div>
        </div>
      </el-dialog>

      <!-- Add/Edit dialog-->
      <el-dialog
          v-model="addFleetDialogVisible"
          title="Add/Edit Fleets"
          :width="windowWidth > 768 ? '30%' : '90%'">
        <div class="flex flex-col gap-y-2">
          <form class="px-4">

            <!--            'user_id', 'rigid', 'arctic', 'drawbar', 'interlink'-->
            <div class="flex flex-col items-start justify-start mb-3 w-full">
              <p class="text-md font-bold text-sm lg:text-md mb-3">Rigid<span
                  class="text-red-500 ml-2">*</span></p>
              <div class="flex flex-row items-start justify-start w-full mb-1">
                <div class="flex flex-row rounded-md w-full">
                  <input
                      class="py-2 text-slate-600 relative bg-white bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                      v-model="form.rigid" type="number"
                      name="form.rigid" required/>
                </div>
              </div>
            </div>

            <div class="flex flex-col items-start justify-start mb-3 w-full">
              <p class="text-md font-bold text-sm lg:text-md mb-3">Artic<span
                  class="text-red-500 ml-2">*</span></p>
              <div class="flex flex-row items-start justify-start w-full mb-1">
                <div class="flex flex-row rounded-md w-full">
                  <input
                      class="py-2 text-slate-600 relative bg-white bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                      v-model="form.artic" type="number"
                      name="form.arctic" required/>
                </div>
              </div>
            </div>

            <div class="flex flex-col items-start justify-start mb-3 w-full">
              <p class="text-md font-bold text-sm lg:text-md mb-3">DrawBar<span
                  class="text-red-500 ml-2">*</span></p>
              <div class="flex flex-row items-start justify-start w-full mb-1">
                <div class="flex flex-row rounded-md w-full">
                  <input
                      class="py-2 text-slate-600 relative bg-white bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                      v-model="form.drawbar" type="number"
                      name="form.drawbar" required/>
                </div>
              </div>
            </div>

            <div class="flex flex-col items-start justify-start mb-3 w-full">
              <p class="text-md font-bold text-sm lg:text-md mb-3">Interlink<span
                  class="text-red-500 ml-2">*</span></p>
              <div class="flex flex-row items-start justify-start w-full mb-1">
                <div class="flex flex-row rounded-md w-full">
                  <input
                      class="py-2 text-slate-600 relative bg-white bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                      v-model="form.interlink" type="number"
                      name="form.interlink" required/>
                </div>
              </div>
            </div>

          </form>
          <div class="dialog-footer flex flex-row w-full justify-end px-4">
            <el-button class="bg-sky-600 text-white" @click="addFleet">
              Save
            </el-button>
            <el-button class="bg-red-700 text-white" type="danger" @click="addFleetDialogVisible = false">Cancel
            </el-button>
          </div>
        </div>
      </el-dialog>

      <!--Table-->
      <div class="py-8 px-4">
        <el-table class="" :data="fleets.data" stripe size="large" style="width: 100%">
          <el-table-column prop="rigid" label="RIGID"/>
          <el-table-column prop="artic" label="ARTIC"/>
          <el-table-column prop="drawbar" label="DRAWBAR"/>
          <el-table-column prop="interlink" label="INTERLINK"/>
          <el-table-column prop="user.first_name" label="USER"/>
          <el-table-column prop="created_at" label="CREATED AT"/>
          <el-table-column label="ACTIONS">
            <template #default="scope">
              <el-dropdown size="large">
                <el-button>
                  Actions
                  <el-icon class="el-icon--right">
                    <arrow-down/>
                  </el-icon>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click.native="updateFleet(scope.row)">EDIT</el-dropdown-item>
                    <el-dropdown-item @click.native="deleteFleet(scope.row)">DELETE</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex flex-row p-5 w-full justify-end">
        <el-pagination
            :current-page="fleets.current_page"
            :page-size="fleets.per_page"
            :page-sizes="[5,10,20,50]"
            :small=false
            layout="total, sizes, prev, pager, next, jumper"
            :total="fleets.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
      </div>
    </div>

  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {ArrowDown, Upload, Download} from '@element-plus/icons-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  data() {
    return {
      search: '',
      uploadFleetDialogVisible: false,
      addFleetDialogVisible: false,
      windowWidth: window.innerWidth,
      form: {},
      fleet: {},
      filters: {
        paginate: true,
        per_page: 10
      },
    };
  },
  components: {
    ArrowDown, Upload, Download

  },
  mounted() {
    this.getFleetsList(this.filters)
  },
  computed: {
    ...mapGetters({
      fleets: 'fleets/fleets',
    })
  },
  watch: {
    filters: {
      deep: true,
      handler: function () {
        this.getFleetsList(this.filters)
      }
    },
    search: function (val) {
      this.getFleetsList({
        ...this.filters,
        search: val
      });
    }
  },
  methods: {
    ...mapActions({
      getFleetsList: 'fleets/getFleets',
      uploadFleets: 'fleets/uploadFleets',
      downloadFleets: 'fleets/exportFleets',
      createFleets: 'fleets/createFleets',
      editFleet: 'fleets/editFleet',
      removeFleet: 'fleets/deleteFleet'
    }),
    handleSizeChange(size) {
      this.filters.per_page = size;
      this.getFleetsList({paginate: true, ...this.filters});
    },
    handleCurrentChange(page) {
      this.filters.page = page;
      this.getFleetsList({paginate: true, ...this.filters});
    },
    addFleet() {
      if (this.form.id) {
        this.editFleet(this.form).then(() => {
          if (!this.has_errors) {
            this.form = {}
            this.addFleetDialogVisible = false
          }
        })
      } else {
        this.createFleets(this.form).then(() => {
          if (!this.has_errors) {
            this.form = {}
            this.addFleetDialogVisible = false
          }
        });
      }
    },
    updateFleet(item) {
      this.addFleetDialogVisible = true
      this.form = Object.assign({}, this.form, item);
    },
    deleteFleet(item) {
      let conf = confirm(`Are you sure you want to remove this item?`)
      if (conf) {
        this.removeFleet({
          id: item.id
        })
      }
    },
    upload() {
      this.fleet.file = this.file;
      this.uploadFleets(this.fleet)
          .then(() => {
            this.getFleetsList(this.filters)
            this.uploadFleetDialogVisible = false
            this.$message.success('Fleets uploaded successfully!')
          }).catch((e) => {
        this.$message.error(e.response.data.message)
      })
    },
    companyFile(e) {
      this.file = e.target.files[0]
    },
    exportFleets() {
      this.downloadFleets({...this.filters})
    }

  }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
  cursor: pointer;
  color: transparent;
  display: flex;
  align-items: center;
}
</style>
