<template>
    <div class="container mx-auto h-full">
        <div class="flex items-center justify-center h-full">
            <div class="">
                <div
                    class="w-full md:px-12 relative flex flex-col break-words mb-6 border rounded-md bg-white">
                    <div class="flex flex-col rounded-t mb-0 py-10 mx-4 px-4">
                        <div class="font-bold text-black text-3xl pb-4">
                            Forgot your password?
                        </div>
                        <div class="font-normal text-base text-gray-500 tracking-normal pb-6">
                            Don't fret!
                            Just type in your email and we will send you a code to reset your password!
                        </div>
                        <div>
                            <div class="mb-6">
                                <label for="email" class="block mb-2 text-base font-medium text-gray-900">Your
                                    email or phone number</label>
                                <input v-model="form.input" type="email"
                                       class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-secondary focus:border-secondary block w-full p-3 outline-none"
                                       placeholder="example@gmail.com  or  0712345678" required>
                                <span class="text-red-500" v-if="errors.data.web_errors.input  && this.has_errors">
                                    {{ errors.data.web_errors.input[0] }}
                                </span>
                            </div>
                            <button v-loading="loading" @click="submitEmail()"
                                    class="text-white bg-secondary hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm sm:w-auto px-6 py-3 text-center">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
    data() {
        return {
            form: {}
        };
    },
    computed: {
        ...mapGetters({
            errors: 'auth/errors',
            has_errors: 'auth/has_errors',
            loading:'auth/loading'
        })
    },
    methods: {
        ...mapActions({
            verifyEmail: 'auth/verifyEmail'
        }),
        submitEmail() {
            this.verifyEmail(this.form)
        }

    }
};
</script>
