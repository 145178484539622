<template>
    <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded border bg-white">

        <div class="rounded-t mb-0 px-4 py-5 border-b">
            <div class="flex flex-wrap items-center">
                <div
                    class="md:flex-row flex-col relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-lg">
                        SmsUsers
                    </h3>
                    <div class="flex flex-row justify-end">
                        <button class="bg-secondary text-white px-10 py-2 font-semibold rounded-md"
                                @click="addSmsUserDialogVisible = true">Add Sms User
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!--    Filters and Search-->
        <div
            class="flex lg:flex-row flex-col justify-between md:items-start md:space-y-0 space-y-2 md:space-x-4 space-x-0 py-8 px-6 border-b">
            <!--            <div class="flex flex-row justify-start items-end mb-3 space-x-4">-->
            <!--                        Export and Import-->
            <!--                <div class="flex flex-row">-->
            <!--                    <el-button-group>-->
            <!--                        <el-button @click="exportSmsUsers" type="success" plain>-->
            <!--                            <span class="mr-3">Export</span>-->
            <!--                            <el-icon>-->
            <!--                                <Download/>-->
            <!--                            </el-icon>-->
            <!--                        </el-button>-->
            <!--                        <el-button @click="uploadSmsUserDialogVisible=!uploadSmsUserDialogVisible"-->
            <!--                                   type="warning"-->
            <!--                                   size="default"-->
            <!--                                   plain>-->
            <!--                            <span class="mr-3">Import</span>-->
            <!--                            <el-icon>-->
            <!--                                <Upload/>-->
            <!--                            </el-icon>-->
            <!--                        </el-button>-->
            <!--                    </el-button-group>-->
            <!--                </div>-->
            <!--            </div>-->

            <form>
                <div class="relative">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" class="w-5 h-5 mr-3">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                        </svg>
                    </div>
                    <input v-model="search"
                           class="block outline-none w-full px-12 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-secondary focus:border-secondary"
                           placeholder="Search Sms Users ...">
                </div>
            </form>
        </div>

        <div class="block w-full overflow-x-auto">

            <!--Import dialog-->
            <el-dialog
                v-model="uploadSmsUserDialogVisible"
                title="Upload SmsUsers"
                :width="windowWidth > 768 ? '40%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <div class="flex flex-row items-center w-full rounded mb-4">
                        <a class="py-2 px-6 text-sky-600"
                           href="/templates/sms_users_template.csv" download="">
                            <font-awesome-icon class="mr-2 text-sm"
                                               :icon="['fas', 'download']"/>
                            Please click here to download the sms_users template.
                        </a>
                    </div>
                    <form class="px-4">
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">File (.xls or .xlsx or .csv)<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row border p-2 rounded-md w-full">
                                    <input
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        class="outline-none w-full" @change="companyFile" type="file" required/>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="upload">
                            Upload
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="uploadSmsUserDialogVisible = false">
                            Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!-- Add/Edit dialog-->
            <el-dialog
                v-model="addSmsUserDialogVisible"
                title="Add/Edit SmsUsers"
                :width="windowWidth > 768 ? '30%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <form class="px-4">

                        <div v-show="!new_user" class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Select and existing User</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.user_id" placeholder="Select user"
                                        size="default">
                                        <el-option
                                            v-for="item in users.data"
                                            :key="item.id"
                                            :label="item.first_name + ' ' + item.last_name"
                                            :value="item.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                        </div>

                        <div v-show="!new_user">
                            <span class="mr-5">or</span>
                            <el-button class="bg-red-700 text-white" @click="new_user = true">
                                Add New User
                            </el-button>
                        </div>

                        <div v-show="new_user && !form.id" class="flex flex-row mb-5">
                            <button
                                class="flex flex-row space-x-2 max-w-fit items-center text-blue-500 border border-blue-500 rounded px-2 py-1 hover:text-white hover:bg-blue-500"
                                @click="new_user = false">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"/>
                                </svg>
                                <span>back</span>
                            </button>
                        </div>

                        <div :class="{'hidden' : !new_user, 'flex flex-col' : new_user}">
                            <div class="flex flex-col items-start justify-start mb-3 w-full">
                                <p class="text-md font-bold text-sm lg:text-md mb-3">First Name<span
                                    class="text-red-500 ml-2">*</span></p>
                                <div class="flex flex-row items-start justify-start w-full mb-1">
                                    <div class="flex flex-row rounded-md w-full">
                                        <input
                                            class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                            v-model="form.first_name" type="text"
                                            name="form.first_name" required/>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col items-start justify-start mb-3 w-full">
                                <p class="text-md font-bold text-sm lg:text-md mb-3">Last Name<span
                                    class="text-red-500 ml-2">*</span></p>
                                <div class="flex flex-row items-start justify-start w-full mb-1">
                                    <div class="flex flex-row rounded-md w-full">
                                        <input
                                            class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                            v-model="form.last_name" type="text"
                                            name="form.last_name" required/>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col items-start justify-start mb-3 w-full">
                                <p class="text-md font-bold text-sm lg:text-md mb-3">Phone Number<span
                                    class="text-red-500 ml-2">*</span></p>
                                <div class="flex flex-row items-start justify-start w-full mb-1">
                                    <div class="flex flex-row rounded-md w-full">
                                        <MazPhoneNumberInput
                                            v-model="phone"
                                            default-country-code="KE"
                                            :only-countries="['KE','TZ','UG','RW','BW','ZA','NA','ZW']"
                                            @update="emitInput"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="addSmsUser">
                            Save
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="addSmsUserDialogVisible = false">Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!--Table-->
            <div class="py-8 px-4">
                <el-table :data="sms_users.data" stripe size="large" style="width: 100%">
                    <el-table-column prop="first_name" label="FIRST NAME"/>
                    <el-table-column prop="last_name" label="LAST NAME"/>
                    <el-table-column prop="phone_number" label="PHONE NUMBER"/>
                    <el-table-column label="ACTIONS">
                        <template #default="scope">
                            <el-dropdown size="large">
                                <el-button>
                                    Actions
                                    <el-icon class="el-icon--right">
                                        <arrow-down/>
                                    </el-icon>
                                </el-button>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item @click.native="updateSmsUser(scope.row)">EDIT
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="deleteSmsUser(scope.row)">DELETE
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="flex flex-row p-5 w-full justify-end">
                <el-pagination
                    :current-page="sms_users.current_page"
                    :page-size="sms_users.per_page"
                    :page-sizes="[5,10,20,50]"
                    :small=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="sms_users.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </div>

    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
// import {ArrowDown} from '@element-plus/icons-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
    data() {
        return {
            search: '',
            uploadSmsUserDialogVisible: false,
            addSmsUserDialogVisible: false,
            windowWidth: window.innerWidth,
            form: {},
            filters: {
                paginate: true,
                per_page: 10
            },
            new_user: false,
            phone: null,
        };
    },
    components: {
        // ArrowDown,
        // Upload, Download

    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.getSmsUsersList({
            ...this.filters,
            id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
        })
        this.getUsers({...this.filters})
    },
    computed: {
        ...mapGetters({
            sms_users: 'sms_users/sms_users',
            users: 'users/users'
        })
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.getSmsUsersList(this.filters)
            }
        },
        search: function (val) {
            this.getSmsUsersList({
                ...this.filters,
                search: val
            });
        }
    },
    methods: {
        ...mapActions({
            getSmsUsersList: 'sms_users/getSmsUsers',
            createSmsUsers: 'sms_users/createSmsUsers',
            editSmsUser: 'sms_users/editSmsUser',
            removeSmsUser: 'sms_users/deleteSmsUser',
            getUsers: 'users/getUsers'
        }),
        handleSizeChange(size) {
            this.filters.per_page = size;
            this.getSmsUsersList({paginate: true, ...this.filters});
        },
        handleCurrentChange(page) {
            this.filters.page = page;
            this.getSmsUsersList({paginate: true, ...this.filters});
        },
        emitInput(input) {
            if (input.isValid) {
                this.form.phone_number = input.countryCallingCode + input.nationalNumber
            } else {
                this.form.phone_number = null;
            }
        },
        addSmsUser() {
            this.form.company_id = this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id;
            if (this.form.id) {
                this.editSmsUser(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addSmsUserDialogVisible = false
                    }
                })
            } else {
                this.createSmsUsers(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addSmsUserDialogVisible = false
                    }
                });
            }
        },
        updateSmsUser(item) {
            this.new_user = true
            this.addSmsUserDialogVisible = true
            this.form = Object.assign({}, this.form, item);
        },
        deleteSmsUser(item) {
            let conf = confirm(`Are you sure you want to remove this item?`)
            if (conf) {
                this.removeSmsUser({
                    id: item.id
                }).then(() => {
                    this.getSmsUsersList({
                        ...this.filters,
                        id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
                    })
                })
            }
        },
    }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: transparent;
    display: flex;
    align-items: center;
}
</style>
