import client from "./client";

export default {
    async login(credentials) {
        return client.parseResponse(await client.post('/login', credentials))
    },

    async logout(filters) {
        return client.parseResponse(await client.get('/logout', filters))
    },

    async registerUser(data) {
        return client.parseResponse(await client.post('/register', data))
    },

    async socialLogin(credentials) {
        return client.parseResponse(await client.get('/auth/google', credentials))
    },

    async verifyEmail(data) {
        return client.parseResponse(await client.post('/verify-email', data))
    },

    async verifyOtp(data) {
        return client.parseResponse(await client.post('/verify-otp', data))
    },

    async verifyAccount(data) {
        return client.parseResponse(await client.post('/verify-account', data))
    },

    async resetPassword(data) {
        return client.parseResponse(await client.post('/reset-password/' + data.user_id, data))
    },

    async resendOtp(id) {
        return client.parseResponse(await client.post('/resend-otp/' + id))
    },


}
