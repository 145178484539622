<template>
    <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded border bg-white">

        <div class="rounded-t mb-0 px-4 py-5 border-b">
            <div class="flex flex-wrap items-center">
                <div
                    class="md:flex-row flex-col relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-lg">
                        User Incentives
                    </h3>
                </div>
            </div>
        </div>

        <!--    Filters and Search-->
        <div class="flex flex-col">
            <div class="flex lg:flex-row flex-col justify-start md:items-end lg:space-x-4 md:space-y-0 space-y-2 py-8 px-6">
                <div class="flex flex-col">
                    <p class="text-md font-bold text-sm lg:text-md mb-3">Inspection</p>
                    <div class="flex flex-row items-start justify-start w-full">
                        <div class="flex flex-row rounded-md w-full">
                            <el-select
                                style="width: 100%" v-model="filters.inspection_id"
                                placeholder="Select inspection"
                                size="default" filterable>
                                <el-option
                                    v-for="item in inspections"
                                    :key="item.id"
                                    :label="item.title"
                                    :value="item.id"
                                />
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col">
                    <p class="text-md font-bold text-sm lg:text-md mb-3">Status</p>
                    <div class="flex flex-row items-start justify-start w-full">
                        <div class="flex flex-row rounded-md w-full">
                            <el-select
                                style="width: 100%" v-model="filters.status"
                                placeholder="Select status"
                                size="default">
                                <el-option value="dispatched">Dispatched</el-option>
                                <el-option value="cancelled">Cancelled</el-option>
                                <el-option value="pending_dispatch">Pending Dispatch</el-option>
                                <el-option value="waiting_for_auth">Waiting for auth</el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="flex lg:flex-row flex-col justify-between md:items-start md:space-y-0 space-y-2 md:space-x-4 space-x-0 py-8 px-6 border-b">
                <div class="block">
                    <span class="text-black mr-2">Date Range</span>
                    <el-date-picker
                        v-model="date_range"
                        type="datetimerange"
                        :shortcuts="shortcuts"
                        range-separator="To"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                    />
                </div>
                <form>
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                 stroke="currentColor" class="w-5 h-5 mr-3">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                            </svg>
                        </div>
                        <input v-model="search"
                               class="block outline-none w-full px-12 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-secondary focus:border-secondary"
                               placeholder="Search user incentives ...">
                    </div>
                </form>
            </div>
        </div>

        <!--authorization dialog-->
        <el-dialog
            v-model="authorizeIncentiveDialog"
            title="Update Incentive Status"
            :width="windowWidth > 768 ? '40%' : '90%'">
            <div class="flex flex-col gap-y-2">
                <form class="px-4">
                    <div class="flex flex-col items-start justify-start mb-3 w-full">
                        <p class="text-md font-bold text-sm lg:text-md mb-3">Please select the incentive status</p>
                        <div class="flex flex-row items-start justify-start w-full mb-1">
                            <div class="flex flex-row rounded-md w-full">
                                <el-select
                                    style="width: 100%" v-model="form.status" placeholder="Select status"
                                    size="default">
                                    <el-option value="canceled">Cancel Incentive</el-option>
                                    <el-option value="pending_dispatch">Approve Incentive Dispatch</el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="dialog-footer flex flex-row w-full justify-end px-4">
                    <el-button class="bg-sky-600 text-white" @click="authorizeIncentive">
                        Save
                    </el-button>
                    <el-button class="bg-red-700 text-white" type="danger" @click="authorizeIncentive = false">
                        Cancel
                    </el-button>
                </div>
            </div>
        </el-dialog>


        <div class="block w-full overflow-x-auto">

            <!--Table-->
            <div class="py-8 px-4">
                <el-table class="" :data="user_incentives.data" stripe size="large" style="width: 100%">
                    <el-table-column prop="user.full_name" label="USER"/>
                    <el-table-column prop="incentive.type" label="INCENTIVE TYPE"/>
                    <el-table-column prop="incentive.amount" label="AMOUNT"/>
                    <el-table-column prop="incentive.details" label="DETAILS"/>
                    <el-table-column prop="inspection.title" label="INSPECTION"/>
                    <el-table-column prop="status" label="STATUS"/>
                    <el-table-column prop="created_at" label="CREATED AT"/>
                    <el-table-column label="ACTIONS">
                        <template #default="scope">
                            <el-dropdown size="large">
                                <el-button>
                                    Actions
                                    <el-icon class="el-icon--right">
                                        <arrow-down/>
                                    </el-icon>
                                </el-button>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item @click.native="updateIncentiveStatus(scope.row)">Authorize
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="flex flex-row p-5 w-full justify-end">
                <el-pagination
                    :current-page="user_incentives.current_page"
                    :page-size="user_incentives.per_page"
                    :page-sizes="[5,10,20,50]"
                    :small=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="user_incentives.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </div>

    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {ArrowDown} from '@element-plus/icons-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import moment from "moment/moment";

export default {
    data() {
        return {
            search: '',
            windowWidth: window.innerWidth,
            form: {},
            user: {},
            user_incentive: {},
            authorizeIncentiveDialog: false,
            filters: {
                paginate: true,
                per_page: 10
            },
            date_range:[],
            shortcuts:[
                {
                    text: 'Today',
                    value: new Date(),
                },
                {
                    text: 'Yesterday',
                    value: () => {
                        const date = new Date()
                        date.setTime(date.getTime() - 3600 * 1000 * 24)
                        return date
                    },
                },
                {
                    text: 'This week',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setHours(0, 0, 0, 0); // Start of the current day
                        const dayOfWeek = start.getDay(); // 0 (Sunday) to 6 (Saturday)
                        const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
                        start.setDate(start.getDate() - daysToSubtract);
                        return [start, end];
                    },
                },
                {
                    text: 'Last week',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                        return [start, end]
                    },
                },
                {
                    text: 'This month',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setDate(1); // Start of the current month
                        return [start, end];
                    },
                },
                {
                    text: 'Last month',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                        return [start, end]
                    },
                },
                {
                    text: 'Last 3 months',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                        return [start, end]
                    },
                },
                {
                    text: 'This year',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setMonth(0, 1); // Start of the current year
                        return [start, end];
                    },
                },
            ]
        };
    },
    components: {
        ArrowDown

    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.getUserIncentivesList({
            ...this.filters,
            id: this.$route.params.id ? this.$route.params.id : this.user.user_company?.company_id
        })
        this.getInspections({})
    },
    computed: {
        ...mapGetters({
            user_incentives: 'incentives/user_incentives',
            inspections:'inspections/inspections',
        })
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.getUserIncentivesList({
                    ...this.filters,
                    id: this.$route.params.id ? this.$route.params.id : this.user.user_company?.company_id
                })
            }
        },
        search: function (val) {
            this.getUserIncentivesList({
                ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company?.company_id,
                search: val
            });
        },
        'date_range': function (val){
            this.filters.start = moment(val[0]).format('DD-MM-YYYY HH:MM')
            this.filters.end = moment(val[1]).format('DD-MM-YYYY HH:MM')
            this.getUserIncentivesList({
                ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company?.company_id
            })
        },
    },
    methods: {
        ...mapActions({
            getUserIncentivesList: 'incentives/getUserIncentives',
            approveIncentive: 'incentives/authorizeIncentive',
            getInspections:'inspections/getInspections'
        }),
        handleSizeChange(size) {
            this.filters.per_page = size;
            this.getUserIncentivesList({paginate: true, ...this.filters});
        },
        handleCurrentChange(page) {
            this.filters.page = page;
            this.getUserIncentivesList({paginate: true, ...this.filters});
        },
        updateIncentiveStatus(item) {
            this.authorizeIncentiveDialog = true
            this.form = Object.assign({}, this.form, item);
            this.form.status = ''
        },
        authorizeIncentive() {
            this.approveIncentive(this.form).then(()=>{
                this.authorizeIncentiveDialog = false
                this.getUserIncentivesList({
                    ...this.filters,
                    id: this.$route.params.id ? this.$route.params.id : this.user.user_company?.company_id
                })
            })
        }
    }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: transparent;
    display: flex;
    align-items: center;
}
</style>
