import client from "./client";

export default {
    async getPermissions(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/permissions', filters)));
    },

    async createPermissions(data) {
        return client.parseResponse(await client.post('/permissions', data))
    },

    async getPermission(id) {
        return client.parseResponse(await client.get('/permissions/' + id));
    },

    async editPermission(data) {
        return client.parseResponse(await client.put('/permissions/' + data.id, data))
    },

    async deletePermission(payload) {
        return client.parseResponse(await client.delete('/permissions/' + payload.id));
    },
}
