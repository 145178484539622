<template>
    <div class="flex flex-col m-4">
        <div class="flex flex-col p-5 bg-white border mb-8">
            <span class="text-2xl mb-4 font-semibold">
                {{ inspection_log.inspection.title }}
            </span>
            <div class="flex md:flex-row flex-col-reverse justify-between">
                <div class="flex flex-row">
                    <div class="flex flex-col pr-10 gap-y-2">
                        <span>Date Created:</span>
                        <span>Date Inspected:</span>
                        <span class="pt-12">
                        <el-tag effect="dark" :type="inspection_log.status === 1 ? 'success' : 'danger'" size="large">
                                {{ inspection_log.status === 1 ? 'Passed' : 'Failed' }}
                        </el-tag>
                    </span>
                    </div>
                    <div class="flex flex-col font-semibold gap-y-2">
                        <span>{{ inspection_log.inspection.created_at }}</span>
                        <span>{{ inspection_log.created_at }}</span>
                    </div>
                </div>
                <div class="flex flex-col pr-12 mr-4 md:mb-0 mb-5">
                    <div class="flex flex-row mb-8 md:justify-end justify-start">
                        <div class="text-slate-500 block pr-12">
                            <div class="items-center flex flex-col gap-y-2">
                            <span
                                class="w-12 h-12 text-sm text-white bg-slate-200 inline-flex items-center justify-center rounded-full">
                                <img alt="..." class="w-full rounded-full align-middle border-none shadow-lg"
                                     src="/spa/assets/img/profile.8c421f27.png">
                            </span>
                                <span>
                                {{ inspection_log.user.first_name }}
                            </span>
                            </div>
                        </div>
                        <div class="text-slate-500 block">
                            <div class="items-center flex flex-col gap-y-2">
                            <span
                                class="w-12 h-12 text-sm text-white bg-slate-200 inline-flex items-center justify-center rounded-full">
                                <img alt="..." class="w-full rounded-full align-middle border-none shadow-lg"
                                     src="https://cdn-s3-assets.whip-around.com/vehicles/profilepics/no_image_vehicle.png">
                            </span>
                                <span>
                                {{ inspection_log.vehicle.reg_number }}
                            </span>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row">
                        <el-button v-loading="loading" @click="exportPdf" type="primary" plain>
                            <span class="mr-3">Download Pdf</span>
                            <el-icon>
                                <Download/>
                            </el-icon>
                        </el-button>
                        <el-button disabled @click="editInspectionLog" type="primary" plain>
                            <span class="mr-3">Edit</span>
                            <el-icon>
                                <Edit/>
                            </el-icon>
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-wrap gap-6">
            <div v-for="question in inspection_log.inspection.inspection_questions" :key="question.id"
                 class="p-5 border flex flex-col flex-grow bg-white h-full">
                <div class="flex flex-row justify-between pb-6">
                    <span class="text-lg font-medium pr-12">
                        {{ question.title }}
                    </span>
                    <span class="flex items-center" v-html="question.inspection_question_type.avatar">
                    </span>
                </div>
                <div
                    v-if="question.inspection_question_type.name === 'Multi Check'
                           || question.inspection_question_type.name === 'Single Check'">
                    <div class="flex flex-col justify-end h-full gap-y-2"
                         v-for="response in question.inspection_responses"
                         :key="response.id">
                        <div v-if="response.fail_images.length">
                            <div v-for="image in response.fail_images" :key="image.id">
                                <el-image style="width: 100px; height: 100px" :src="image.image" fit="fill"/>
                            </div>
                        </div>
                        <ul v-for="option in response.inspection_response_options"
                            :key="option.id">
                            <li class="flex flex-col" :class="{'text-red-500' : !option.status }">
                                <div class="flex flex-row">
                                    <div class="mr-3 flex justify-center items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5"/>
                                        </svg>
                                    </div>
                                    <span>
                                {{ option.inspection_question_option.option }}
                                </span>
                                </div>
                                <div class="text-xs text-black pl-3" v-if="option.option_fail_comments.length">
                                    <span class="font-bold">
                                        Comments
                                    </span>
                                    <div v-for="comment in option.option_fail_comments" :key="comment.id">
                                        {{ comment.comment }}
                                    </div>
                                </div>
                                <div v-if="option.option_fail_images.length">
                                    <div v-for="image in option.option_fail_images" :key="image.id">
                                        <el-image style="width: 100px; height: 100px" :src="image.image" fit="fill"/>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <span class="justify-end w-full flex">
                            <el-tag effect="dark" :type="response.status === 1 ? 'success' : 'danger'" size="default">
                                {{ response.status === 1 ? 'Pass' : 'Fail' }}
                            </el-tag>
                        </span>
                    </div>
                </div>
                <div
                    v-if="question.inspection_question_type.name === 'Multiple Choice'">
                    <div class="flex flex-col justify-end h-full gap-y-2"
                         v-for="response in question.inspection_responses"
                         :key="response.id">
                        <ul v-for="option in response.inspection_response_options"
                            :key="option.id">
                            <li v-if="option.status" class="flex flex-row">
                                <div class="mr-3 flex justify-center items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ option.inspection_question_option.option }}
                                </span>
                            </li>
                        </ul>
                        <span class="justify-end w-full flex">
                            <el-tag effect="dark" size="default">{{ question.inspection_question_type.name }}</el-tag>
                        </span>
                    </div>
                </div>

                <div
                    v-if="question.inspection_question_type.name === 'Checkboxes'">
                    <div class="flex flex-col justify-end h-full gap-y-2"
                         v-for="response in question.inspection_responses"
                         :key="response.id">
                        <ul v-for="option in response.inspection_response_options"
                            :key="option.id">
                            <li v-if="option.status" class="flex flex-row">
                                <div class="mr-3 flex justify-center items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ option.inspection_question_option.option }}
                                </span>
                            </li>
                        </ul>
                        <span class="justify-end w-full flex">
                            <el-tag effect="dark" size="default">{{ question.inspection_question_type.name }}</el-tag>
                        </span>
                    </div>
                </div>

                <div
                    v-if="question.inspection_question_type.name === 'Photo' || question.inspection_question_type.name === 'Signature' ">
                    <div class="flex flex-col justify-end h-full gap-y-2"
                         v-for="response in question.inspection_responses"
                         :key="response.id">
                        <div class="flex flex-wrap" v-if="response.inspection_response_images.length">
                            <div v-for="image in response.inspection_response_images" :key="image.id">
                                <el-image style="width: 100px; height: 100px" :src="image.image" fit="fill"/>
                            </div>

                        </div>
                        <span class="justify-end w-full flex">
                            <el-tag effect="dark" size="default">{{ question.inspection_question_type.name }}</el-tag>
                        </span>
                    </div>
                </div>

                <div
                    v-if="question.inspection_question_type.name === 'Mileage' || question.inspection_question_type.name === 'Engine Hours'
                          || question.inspection_question_type.name === 'Data Entry Alphanumeric' || question.inspection_question_type.name === 'Data Entry Numeric'
                           || question.inspection_question_type.name === 'Dropdown'">
                    <div class="flex flex-col justify-end h-full gap-y-2"
                         v-for="response in question.inspection_responses"
                         :key="response.id">
                        {{ response.response }}
                        <span class="justify-end w-full flex">
                            <el-tag effect="dark" size="default">{{ question.inspection_question_type.name }}</el-tag>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {Download, Edit} from '@element-plus/icons-vue'

export default {
    data() {
        return {}
    },
    mounted() {
        this.getInspectionLog(this.$route.params.id)
    },
    components: {
        Download, Edit
    },
    computed: {
        ...mapGetters({
            inspection_log: 'inspection_logs/inspection_log',
            loading:'inspection_logs/loading'
        })
    },
    methods: {
        ...mapActions({
            getInspectionLog: 'inspection_logs/getInspectionLog',
            downloadInspectionReport:'inspection_logs/downloadInspectionReport',
        }),
        getImages(images) {
            return images.map(image => image.image)
        },
        exportPdf(){
            this.downloadInspectionReport({id:this.inspection_log.id})
        }
    }
}
</script>
