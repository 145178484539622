<template>
    <div class="container mx-auto h-full">
        <div class="flex items-center justify-center h-full w-full">
            <div class="flex flex-col justify-center items-center w-full">
                <div class="flex flex-col justify-center w-full md:px-12 px-4">
                    <div
                        class="relative flex flex-col break-words mb-6 shadow rounded-md bg-white">
                        <div class="flex flex-col rounded-t mb-0 py-10 mx-4 px-4">
                            <div class="font-bold text-black text-3xl pb-4">
                                Verify Your Email
                            </div>
                            <div class="font-normal text-base text-gray-500 tracking-normal pb-6">
                                Please enter the otp code sent to your email
                            </div>
                            <div class="mr-4">
                                <div class="mb-6 mr-4">
                                    <label for="email" class="block mb-2 text-base font-medium text-gray-900">
                                        Otp Code
                                    </label>
                                    <input v-model="form.code" type="email"
                                           class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-secondary focus:border-secondary block w-full p-3 outline-none"
                                           placeholder="****" required>
                                        <span class="text-red-500"
                                              v-if="errors.data.web_errors.code  && this.has_errors">
                                            {{ errors.data.web_errors.code[0] }}
                                        </span>
                                </div>
                                <button @click="sendOtp()"
                                        class="text-white bg-secondary hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm sm:w-auto px-6 py-3 text-center">
                                    Verify & Proceed
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-wrap relative">
                        <div class="w-1/2">
                            <div @click="resendOtp" class="text-slate-500">
                                <span>Didn't receive code? <span class="text-red-500">Click here to resend</span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
    data() {
        return {
            form: {}
        };
    },
    computed: {
        ...mapGetters({
            errors: 'auth/errors',
            has_errors: 'auth/has_errors',
            loading: 'auth/loading'
        })
    },
    methods: {
        ...mapActions({
            verifyAccount: 'auth/verifyAccount',
            resendOTP: 'auth/resendOTP'
        }),
        sendOtp() {
            this.form.user_id = localStorage.getItem('id')
            this.verifyAccount(this.form)
        },
        resendOtp() {
            this.resendOTP(localStorage.getItem('id'))
        }
    }
};
</script>
