import api from "../api";
import {ElMessage} from 'element-plus'

export default {
    namespaced: true,
    state: {
        vehicle_inspections: {data: [], current_page: 1, total: 0, per_page: 10},
        vehicle_inspection: {},
        errors: {
            data: {
                errors: {
                    name: [],
                }
            }
        },
        has_errors: false,
    },
    getters: {
        vehicle_inspection: state => state.vehicle_inspection,
        vehicle_inspections: state => state.vehicle_inspections,
        errors: state => state.errors,
        has_errors: state => state.has_errors,
    },
    mutations: {
        setVehicleInspection(state, data) {
            state.vehicle_inspection = data;
        },
        setVehicleInspections(state, data) {
            state.vehicle_inspections = data;
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        errors: {
                            name: [],
                        }
                    }
                };
            }
        }
    },
    actions: {
        async getVehicleInspections({commit}, filters) {
            try {
                const response = await api.getVehicleInspections(filters);
                commit('setVehicleInspections', response);
            } catch (e) {
                console.log(e)
            }
        },
        async getVehicleInspection({commit}, id) {
            try {
                const response = await api.getVehicleInspection(id);
                commit('setVehicleInspection', response);
            } catch (e) {
                console.log(e)
            }
        },

        async createVehicleInspections({commit}, data) {
            try {
                commit('hasErrors', false)
                await api.createVehicleInspections(data);
                // dispatch('getVehicleInspections', {paginate: true})
                ElMessage({
                    message: 'VehicleInspection created successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error creating VehicleInspection!',
                    type: 'error',
                })
            }
        },
        async editVehicleInspection({dispatch}, data) {
            try {
                await api.editVehicleInspection(data);
                dispatch('getVehicleInspections', {
                    paginate: true
                });
                ElMessage({
                    message: 'VehicleInspection updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: 'Error updating VehicleInspection',
                    type: 'error',
                })
            }
        },
        async deleteVehicleInspection(context, payload) {
            try {
                const {message} = await api.deleteVehicleInspection(payload)
                // dispatch('getVehicleInspections', {paginate: true})
                ElMessage({
                    message: message,
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            }
        },
    }
}
