import client from "./client";

export default {
    async getCompanyVehicles(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/vehicle', filters)));
    },

    async createCompanyVehicles(data) {
        return client.parseResponse(await client.post('/company-vehicles', data))
    },

    async getCompanyVehicle(id) {
        return client.parseResponse(await client.get('/company-vehicles/' + id));
    },

    async editCompanyVehicle(data) {
        return client.parseResponse(await client.put('/company-vehicles/' + data.id, data))
    },

    async deleteCompanyVehicle(payload) {
        return client.parseResponse(await client.delete('/company-vehicles/' + payload.id));
    },

    async uploadCompanyVehicles(payload) {
        return client.parseResponse(await client.upload('/import-vehicles', payload, payload.file))
    },

    async exportCompanyVehicles(filters) {
        return await client.get('/export-vehicles', filters, {responseType: 'blob'});
    },
}
