import client from "./client";

export default {
    async getTyreDefects(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/tyre-defects', filters)));
    },

    async createTyreDefects(data) {
        return client.parseResponse(await client.post('/tyre-defects', data))
    },

    async getTyreDefect(id) {
        return client.parseResponse(await client.get('/tyre-defects/' + id));
    },

    async editTyreDefect(data) {
        return client.parseResponse(await client.put('/tyre-defects/' + data.id, data))
    },

    async deleteTyreDefect(payload) {
        return client.parseResponse(await client.delete('/tyre-defects/' + payload.id));
    },

    async uploadTyreDefects(payload) {
        return client.parseResponse(await client.upload('/import-tyre-defects', payload, payload.file))
    },

    async exportTyreDefects(filters) {
        return await client.get('/export-tyre-defects', filters, {responseType: 'blob'});
    },
}
