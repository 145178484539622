import client from "./client";

export default {
    async getTyreStores(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/tyre-stores', filters)));
    },

    async createTyreStores(data) {
        return client.parseResponse(await client.post('/tyre-stores', data))
    },

    async getTyreStore(id) {
        return client.parseResponse(await client.get('/tyre-stores/' + id));
    },

    async editTyreStore(data) {
        return client.parseResponse(await client.put('/tyre-stores/' + data.id, data))
    },

    async deleteTyreStore(payload) {
        return client.parseResponse(await client.delete('/tyre-stores/' + payload.id));
    },

    async uploadTyreStores(payload) {
        return client.parseResponse(await client.upload('/import-tyre-stores', payload, payload.file))
    },

    async exportTyreStores(filters) {
        return await client.get('/export-tyre-stores', filters, {responseType: 'blob'});
    },
}
