<template>
  <div>
    <div class="flex flex-row w-full h-full overflow-hidden">
        <iframe title="Reporting Ver1"  height="800" class="w-full" src="https://app.powerbi.com/reportEmbed?reportId=965561ce-374a-4d89-919a-83820562b301&autoAuth=true&ctid=9d64e322-f0ad-4e3b-ab5d-e33e63d23797" frameborder="0" allowFullScreen="true"></iframe>
    </div>
  </div>
</template>
<script>
export default {
  name: "dashboard-page",
  components: {

  },
};
</script>
