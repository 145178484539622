import api from "../api";
import {ElMessage} from 'element-plus'

export default {
    namespaced: true,
    state: {
        permissions: {data: [], current_page: 1, total: 0, per_page: 10},
        errors: {
            data: {
                errors: {
                    name: [],
                    description: []
                }
            }
        },
        has_errors: false,
        dialogVisible: false,
        loading: false
    },
    getters: {
        dialogVisible: state => state.dialogVisible,
        permissions: state => state.permissions,
        errors: state => state.errors,
        has_errors: state => state.has_errors,
        loading: state => state.loading,
    },
    mutations: {
        setPermissions(state, data) {
            state.permissions = data;
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        setDialogVisible(state, status) {
            state.dialogVisible = status;
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        errors: {
                            name: [],
                            description: []
                        }
                    }
                };
            }
        }
    },
    actions: {
        async getPermissions({commit}, filters) {
            try {
                const response = await api.getPermissions(filters);
                commit('setPermissions', response);
            } catch (e) {
                ElMessage({
                    type: 'error',
                    message: e.response.data.message,
                    duration: 10000
                });
            }
        },
        async createPermission({dispatch, commit}, data) {
            try {
                commit('setLoading', true)
                commit('hasErrors', false)
                await api.createPermissions(data);
                dispatch('getPermissions', {
                    paginate: true
                })
                ElMessage({
                    type: 'success',
                    message: 'Permission created successfully!',
                    duration: 10000
                });
                commit('setLoading', false)
                commit('setDialogVisible', false)
            } catch (e) {
                commit('setLoading', false)
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    type: 'error',
                    message: e.response.data.message,
                    duration: 10000
                });
            }
        },

        async editPermission({dispatch, commit}, data) {
            try {
                commit('setLoading', true)
                commit('hasErrors', false)
                await api.editPermission(data)
                ElMessage({
                    type: 'success',
                    message: 'Permission updated successfully!',
                    duration: 10000
                });
                commit('setLoading', false)
                commit('setDialogVisible', false)
                dispatch('getPermissions', {
                    paginate: true
                })
            } catch (e) {
                commit('setLoading', false)
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    type: 'error',
                    message: e.response.data.message,
                    duration: 10000
                });
            }
        },
        async deletePermission({dispatch, commit}, data) {
            try {
                commit('hasErrors', false);
                const response = await api.deletePermission(data);
                dispatch('getPermissions', {
                    paginate: true
                });
                if (response.status === 401) {
                    ElMessage({
                        type: 'warning',
                        message: response.message,
                        duration: 10000
                    });
                } else {
                    ElMessage({
                        type: 'success',
                        message: response.message,
                        duration: 10000
                    });
                }
            } catch (e) {
                commit('hasErrors', true);
                commit('setErrors', e.response)
                ElMessage({
                    type: 'error',
                    message: e.response.data.message,
                    duration: 10000
                });
            }
        },


    }
}
