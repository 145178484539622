import client from "./client";

export default {
    async getDefects(filters){
        return client.parseResponse(client.parseResponse(await client.get('/defects', filters)));
    },

    async getDefect(id) {
        return client.parseResponse(await client.get('/defects/' + id));
    },

    async editDefect(data) {
        return client.parseResponse(await client.put('/defects/' + data.id, data))
    },

    async exportDefects(filters) {
        return await client.get('/export-defects', filters, {responseType: 'blob'});
    },
}
