import api from "../api";
import {ElMessage} from 'element-plus'

export default {
    namespaced: true,
    state: {
        tyre_brands: {data: [], current_page: 1, total: 0, per_page: 10},
        errors: {
            data: {
                errors: {
                    name: [],
                }
            }
        },
        has_errors: false,
    },
    getters: {
        tyre_brands: state => state.tyre_brands,
        errors: state => state.errors,
        has_errors: state => state.has_errors,
    },
    mutations: {
        setTyreBrands(state, data) {
            state.tyre_brands = data;
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        errors: {
                            name: [],
                        }
                    }
                };
            }
        }
    },
    actions: {
        async getTyreBrands({commit}, filters) {
            try {
                const response = await api.getTyreBrands(filters);
                commit('setTyreBrands', response);
            } catch (e) {
                console.log(e)
            }
        },

        async createTyreBrands({commit, dispatch}, data) {
            try {
                commit('hasErrors', false)
                await api.createTyreBrands(data);
                dispatch('getTyreBrands', {
                    paginate: true
                })
                ElMessage({
                    message: 'TyreBrand created successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error creating TyreBrand!',
                    type: 'error',
                })
            }
        },
        async editTyreBrand({dispatch}, data) {
            try {
                await api.editTyreBrand(data);
                dispatch('getTyreBrands', {
                    paginate: true
                });
                ElMessage({
                    message: 'TyreBrand updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: 'Error updating TyreBrand',
                    type: 'error',
                })
            }
        },
        async deleteTyreBrand({dispatch}, payload) {
            try {
                const {message} = await api.deleteTyreBrand(payload)
                dispatch('getTyreBrands', {})
                ElMessage({
                    message: message,
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            }
        },
        async uploadTyreBrands(context, payload) {
            return await api.uploadTyreBrands(payload)
        },
        async exportTyreBrands(context, filters) {
            try {
                const response = await api.exportTyreBrands(filters);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data]));
                link.setAttribute('download', 'tyre_brands ' + Date.now() + '.xlsx');
                document.body.appendChild(link);
                link.click();
            } catch (e) {
                ElMessage({
                    message: 'Error exporting!',
                    type: 'error',
                })
            }
        },

    }
}
